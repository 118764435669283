import React, { FC, useEffect } from 'react'
import { useUsecases } from '../../hooks'
import { Metrics } from './Metrics'
import { UploadModal } from './Upload'

export const Wrapper: FC = () => {
    const { metricsUsecase } = useUsecases()
    useEffect(() => {
        // load metrics everytime the component mounts
        // this is not the best, but enables us to fetch the data without having to fully reload the page
        void metricsUsecase.loadAllMetrics()
    }, [])
    return (
        <>
            <Metrics />
            <UploadModal />
        </>
    )
}
