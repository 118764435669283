import { NavigationService } from '../../application/services'

export class LocationNavigationService implements NavigationService {
    redirect(url: URL): void {
        window.location.replace(url.toString())
    }

    getUrlHash(): string {
        /**
         * Get hash without #
         */
        return window.location.hash.substring(1)
    }
}
