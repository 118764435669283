import { useLayoutEffect, useState } from 'react'

type WindowSize = {
    readonly width: Window['innerWidth']
    readonly height: Window['innerHeight']
}

/**
 * Use the window's inner size, updates automatically
 */
export const useWindowSize = (): WindowSize => {
    const getWindowSize = (): WindowSize => ({ width: window.innerWidth, height: window.innerHeight })

    const [size, setSize] = useState<WindowSize>(getWindowSize())

    useLayoutEffect(() => {
        const listener = () => setSize(getWindowSize())

        /**
         * Add listener
         */
        window.addEventListener('resize', listener)

        /**
         * Remove when hook is dropped
         */
        return () => window.removeEventListener('resize', listener)
    }, [])

    return size
}
