import { AppState } from '../domain'

/**
 * This is initial state of the application
 */
export const initialState: AppState = {
    /**
     * No licenses or controls to licenses are loaded initially
     */
    manageLicenses: {
        licenses: null,
        toBeCreated: null,
        isBulkRemoving: false,
        isBulkEditing: false,
        toBeEdited: null,
        toBeDuplicated: null,
        downloadLicenseFile: false,
        downloadLicenseKey: false,
        editLicenseAsUser: false,
        editLicense: false
    },

    appliances: {
        appliances: null,
        toBeCreated: null,
        isDeleting: false
    },

    manageServices: {
        toBeCreated: null,
        categories: null,
        toBeEdited: null,
        toBeDeleted: null,
        services: null,
        expanded: {},
        details: null,
        allowedDirectories: null
    },

    token: null,
    account: null,

    manageMetrics: {
        metrics: null,
        toBeUploaded: null,
        isUploading: false
    }
}
