import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { withStyles } from '@material-ui/core/styles'
import Edit from './Edit'
import Delete from './Delete'

const styles = {}

class Toolbar extends Component {
  state = {
    edit: false,
    remove: false
  }

  toggleOpen = (key) => {
    return () => {
      this.setState((prevState) => ({
        [key]: !prevState[key]
      }))
    }
  }

  render() {
    const { classes, id, permissionGroup } = this.props
    const { edit, remove } = this.state
    return (
      <div>
        <Tooltip title={'Edit'}>
          <IconButton className={classes.iconButton} onClick={this.toggleOpen('edit')}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Delete'}>
          <IconButton className={classes.iconButton} onClick={this.toggleOpen('remove')}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Delete id={id} open={remove} toggleOpen={this.toggleOpen('remove')} />
        <Edit id={id} open={edit} toggleOpen={this.toggleOpen('edit')} {...permissionGroup} />
      </div>
    )
  }
}

export default withStyles(styles)(Toolbar)
