import { Card, CardActionArea, CardMedia, CardContent, Typography, makeStyles, Badge } from '@material-ui/core'
import { AddShoppingCart as BadgeIcon } from '@material-ui/icons'
import React, { FC } from 'react'
import { ServiceMetaData } from '../../../../domain'
import { useIsSuperAdmin, useUsecases } from '../../hooks'

const useStyles = makeStyles((theme) => ({
    root: { padding: `${theme.spacing(2)}px` },
    header: { display: 'flex', marginBottom: `${theme.spacing(2)}px` },
    headerTitle: { flexGrow: 1 },
    headerActions: { margin: `0 ${theme.spacing(2)}px`, display: 'flex', justifyContent: 'space-between', height: '40px' },
    bodyContent: { margin: `${theme.spacing(2)}px` },
    container: { width: '100%', display: 'flex', flexWrap: 'wrap' },
    item: { width: '200px', height: '200px', margin: `${theme.spacing(3)}px` },
    content: { height: '100%' },
    icon: { height: '80px', objectFit: 'scale-down', padding: '10px' },
    delete: { backgroundColor: 'rgba(255, 0, 0, 0.8)' },
    danger: {
        backgroundColor: 'rgba(255, 0, 0, 0.8)',
        color: 'white',
        '&:hover': {
            backgroundColor: 'rgba(255, 0, 0, 1)'
        }
    },
    cardContent: { alignSelf: 'baseline', display: 'flex', flexDirection: 'column', flexGrow: 1 },
    cardTitle: { fontSize: '1.2rem', flexGrow: 1 },
    categoryCollapsable: { display: 'flex' },
    marginBottom: { marginBottom: `${theme.spacing(4)}px` }
}))

const ServiceCard: FC<{ app: ServiceMetaData }> = ({ app, app: { icon, name, provider, availableInLicense } }) => {
    const classes = useStyles()
    const { manageServicesCatalogUsecase } = useUsecases()
    const isSuperAdmin = useIsSuperAdmin() || false
    return (
        <Badge
            badgeContent={<BadgeIcon />}
            overlap="circle"
            color="secondary"
            invisible={availableInLicense || isSuperAdmin}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Card className={classes.item} raised>
                <CardActionArea
                    className={classes.content}
                    onClick={() => manageServicesCatalogUsecase.toggleDetails(app)} //handleOnClick(app)}
                >
                    <CardMedia
                        className={classes.icon}
                        component="img"
                        src={icon ? icon : 'https://www.cybus.io/wp-content/uploads/2017/10/blocks.svg'}
                        title={name}
                    />
                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h5" component="h2" className={classes.cardTitle}>
                            {name.length > 26 ? `${name.substring(0, 26)}...` : name}
                        </Typography>
                        <Typography variant="body1" color="textSecondary" component="p">
                            {provider}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Badge>
    )
}

export default ServiceCard
