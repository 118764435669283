import gql from 'graphql-tag'

import { PortalError, PortalErrorType } from '../../../../domain'

import { TypedResponse } from './Response'
import { buildMutationHandler, MutationHandler } from './Builders'

const GENERATE_LICENSE_FILE = gql`
    mutation ConnectwareLicenseFile($connectwareLicenseKey: String!) {
        connectwareLicenseFile(connectwareLicenseKey: $connectwareLicenseKey) {
            connectwareLicenseFile
        }
    }
`
type GenerateLicenseFileInput = {
    connectwareLicenseKey: string
}

interface GenerateLicenseFileResponse extends TypedResponse<'GenerateLicenseKey'> {
    readonly connectwareLicenseFile: string
}

export default (id: string): MutationHandler<GenerateLicenseFileInput, { readonly connectwareLicenseFile: GenerateLicenseFileResponse }, string> =>
    buildMutationHandler(
        {
            document: GENERATE_LICENSE_FILE,
            mapper: ({ data }) => {
                if (!data?.connectwareLicenseFile) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, 'Unexpected deletion response', { ...data?.connectwareLicenseFile })
                }
                return data.connectwareLicenseFile.connectwareLicenseFile
            }
        },
        {
            connectwareLicenseKey: id
        }
    )
