import React, { FC, useMemo } from 'react'
import { isResolved, usePromise } from 'react-sync-promise'
import { makeStyles, Tooltip } from '@material-ui/core'
import { Error } from '@material-ui/icons'
import moment, { Moment } from 'moment'

import { ConnectwareLicenseExpirationConfiguration, Translation } from '../../../../../domain'

import { FormattedTranslation, useUsecases } from '../../../hooks'
import { formatDate } from '../../utils'

const useStyles = makeStyles({ error: { color: 'red' }, warning: { color: 'orange' } })

/**
 * @todo consider moving to utils class
 * @deprecated
 */
export const formatReadableDate = (date: moment.MomentInput): string => formatDate(moment(date).toDate())

/**
 * @todo consider using `Pick<ConnectwareLicense, ...>` in the future
 */
interface Props {
    /**
     * @todo consider using `Date` in the future
     */
    readonly date: Date | Moment
    readonly autoRenewal?: boolean
}

const useExpiration = (daysToGo: number): Record<keyof ConnectwareLicenseExpirationConfiguration, boolean> => {
    const { connectwareLicenseUsecase } = useUsecases()

    const promise = usePromise(useMemo(() => connectwareLicenseUsecase.fetchExpirationConfiguration(), [daysToGo]))
    if (!isResolved(promise)) {
        return { expired: false, toExpireSoon: false }
    }

    return { expired: daysToGo <= promise.value.expired, toExpireSoon: daysToGo <= promise.value.toExpireSoon }
}

export const ExpirationStatus: FC<Props> = ({ date }) => {
    const classes = useStyles()

    const readableDate = formatReadableDate(date)
    const expirationDayEnd = moment(date).endOf('day')
    const daysToGo = moment(expirationDayEnd).diff(moment().endOf('day'), 'day') + 1
    const { expired, toExpireSoon } = useExpiration(daysToGo)

    if (!expired && !toExpireSoon) {
        return <>{readableDate}</>
    }

    return (
        <div className={expired ? classes.error : classes.warning}>
            {readableDate}
            <Tooltip
                title={
                    <FormattedTranslation
                        id={Translation.LICENSE_EXPIRATION_LABEL}
                        values={{ expiration: expired ? 'expired' : 'toExpireSoon', autoRenewal: false }}
                    />
                }
            >
                <Error />
            </Tooltip>
        </div>
    )
}
