import React, { useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'

import { Router, Redirect, Route, RoutePath, useUsecases } from './infrastructure/react/hooks'

import Licenses from './infrastructure/react/components/Licenses'
import License from './infrastructure/react/components/License'
import ManageLicenses from './infrastructure/react/components/ManageLicenses'
import ConnectwareAppliances from './infrastructure/react/components/ConnectwareAppliances'

import { Navigation } from './infrastructure/react'
import ManageLicense from './components/ManageLicense'
import ManageRoles from './components/ManageRoles'
import ManageRole from './components/ManageRole'
import ServicesCatalog from './infrastructure/react/components/ServicesCatalog'
import ManageUserGroups from './infrastructure/react/components/ManageUserGroups'
import ManageUserGroup from './infrastructure/react/components/ManageUserGroup'
import ManageUsers from './infrastructure/react/components/ManageUsers'
import Home from './infrastructure/react/components/Home'
import Metrics from './infrastructure/react/components/Metrics'

function App() {
  const { manageConnectwareLicensesUsecase } = useUsecases()
  useEffect(() => {
    // this will load the licenses once no matter which route the user lands on
    // it is needed to fetch the data being used for the license or manage license details pages.
    // FIXME: should think about a better fetching system to minimize data loads.
    void manageConnectwareLicensesUsecase.loadLicenses()
  }, [])
  return (
    <Router onUnauthorized={RoutePath.HOME} onAuthorizing={<CircularProgress />}>
      <Route exact path={RoutePath.HOME}>
        <Navigation>
          <Home />
        </Navigation>
      </Route>
      <Route exact path={RoutePath.APPLIANCES}>
        <Navigation>
          <ConnectwareAppliances />
        </Navigation>
      </Route>
      <Route exact path={RoutePath.SERVICES_CATALOG}>
        <Navigation>
          <ServicesCatalog />
        </Navigation>
      </Route>
      <Route exact path={RoutePath.LICENSES}>
        <Navigation>
          <Licenses />
        </Navigation>
      </Route>
      <Route exact path={RoutePath.LICENSE}>
        <Navigation>
          <License />
        </Navigation>
      </Route>
      <Route exact superAdmin path={RoutePath.ADMIN_LICENSES}>
        <Navigation>
          <ManageLicenses />
        </Navigation>
      </Route>
      <Route exact superAdmin path={RoutePath.ADMIN_LICENSE}>
        <Navigation>
          <ManageLicense />
        </Navigation>
      </Route>
      <Route exact superAdmin path={RoutePath.ADMIN_ROLES}>
        <Navigation>
          <ManageRoles />
        </Navigation>
      </Route>
      <Route exact superAdmin path={RoutePath.ADMIN_ROLE}>
        <Navigation>
          <ManageRole />
        </Navigation>
      </Route>
      <Route exact path={RoutePath.ADMIN_USER_GROUPS}>
        <Navigation>
          <ManageUserGroups />
        </Navigation>
      </Route>
      <Route exact path={RoutePath.ADMIN_USER_GROUP}>
        <Navigation>
          <ManageUserGroup />
        </Navigation>
      </Route>
      <Route exact superAdmin path={RoutePath.ADMIN_USERS}>
        <Navigation>
          <ManageUsers />
        </Navigation>
      </Route>
      <Route exact path={RoutePath.METRICS}>
        <Navigation>
          <Metrics />
        </Navigation>
      </Route>
      <Redirect to={RoutePath.HOME} />
    </Router>
  )
}

export default App
