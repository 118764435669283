import React, { FC, useState as useReactState } from 'react'
import { Button, Dialog, DialogActions, DialogTitle, Grid, LinearProgress } from '@material-ui/core'
import { PortalError, Translation } from '../../../../../domain'
import { FormattedTranslation, useUsecases, useState } from '../../../hooks'
import { ErrorMessage } from '../../common'
import { FileUploadSection } from './FileUpload'

export const UploadModal: FC = () => {
    const { metricsUsecase } = useUsecases()
    const { isUploading: openUploadDialog, toBeUploaded } = useState((s) => s.manageMetrics)
    const [loading, setLoading] = useReactState<boolean>(false)
    const [done, setDone] = useReactState<boolean>(false)
    const [error, setError] = useReactState<PortalError | null>(null)
    const handleClose = () => metricsUsecase.toggleUpload()
    const handleUpload = async () => {
        setLoading(true)
        try {
            await metricsUsecase.upload()
            setError(null)
            setDone(true)
            handleClose()
        } catch (err) {
            setError(err as PortalError)
        } finally {
            setLoading(false)
        }
    }
    return (
        <Dialog open={openUploadDialog} onClose={handleClose} fullWidth maxWidth={'md'}>
            <Grid container>
                {/* Title */}
                <Grid item xs={12}>
                    <DialogTitle>
                        <FormattedTranslation id={Translation.METRICS_UPLOAD_TITLE} />
                    </DialogTitle>
                    <LinearProgress variant={loading === true ? 'indeterminate' : 'determinate'} value={done ? 100 : 0} />
                </Grid>
                {/* Main */}
                <Grid item xs={7}>
                    <FileUploadSection />
                </Grid>
                {/* Error / Actions */}
                <Grid id="error-column" item xs={9}>
                    {error && <ErrorMessage error={error} />}
                </Grid>
                <Grid item xs={3}>
                    <DialogActions>
                        <Button color="primary" onClick={handleClose}>
                            <FormattedTranslation id={Translation.CANCEL} />
                        </Button>
                        <Button
                            id="upload-metrics-submit"
                            color="primary"
                            type="submit"
                            disabled={toBeUploaded?.data.metrics.length === 0}
                            onClick={handleUpload}
                        >
                            <FormattedTranslation id={Translation.UPLOAD_METRICS} />
                        </Button>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    )
}
