import React, { FC } from 'react'
import { Typography, makeStyles, CircularProgress } from '@material-ui/core'

import { isPortalError, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../hooks'

import { ErrorMessage, ExpirationStatus } from '../common'
import IsInUse from '../IsInUse'
import { useLicense } from './hooks'

const useStatusStyles = makeStyles((theme) => ({
    info: {
        margin: '10px 0px',
        display: 'grid',
        gridTemplateColumns: 'repeat(5, auto)',
        gridGap: '10px',
        gridAutoRows: 'minmax(60px, auto)',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            padding: '10px'
        }
    }
}))

const useLineStyles = makeStyles((theme) => ({
    infoText: { paddingBottom: '5px', fontSize: theme.typography.body1.fontSize },
    cell: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: { margin: '10px 0px' }
    }
}))

const Line: FC<{ readonly translation: Translation }> = ({ translation, children }) => {
    const classes = useLineStyles()

    return (
        <div className={classes.cell}>
            <Typography variant="body2">
                <FormattedTranslation id={translation} />
            </Typography>
            <div className={classes.infoText}>{children}</div>
        </div>
    )
}

const Status: FC<{ id: string }> = ({ id }) => {
    const classes = useStatusStyles()
    const license = useLicense(id)

    if (!license) {
        return <CircularProgress />
    }

    if (isPortalError(license)) {
        return <ErrorMessage error={license} />
    }
    const { description, expiration, autoRenewal, associatedAccount, licenseClass } = license

    return (
        <div className={classes.info} data-testid="licenseStatus">
            <Line translation={Translation.LICENSE_CLASS}>{licenseClass}</Line>
            <Line translation={Translation.DESCRIPTION}>
                <IsInUse description={description || false} />
            </Line>
            <Line translation={Translation.ACCOUNT}>
                {associatedAccount || <FormattedTranslation id={Translation.NOT_AVAILABLE} values={{ type: 'short' }} />}
            </Line>
            {/* TODO: inherit permission groups from user group (associated_account) */}
            {/* <Line translation={Translation.PERMISSIONS}>{roles.map((g) => g.name).join(', ')}</Line> */}
            <Line translation={Translation.EXPIRATION_DATE}>
                <ExpirationStatus date={expiration} autoRenewal={autoRenewal} />
            </Line>
            {/* <Line translation={Translation.AUTOMATIC_LICENSE_RENEWAL}>
                <FormattedTranslation id={Translation.BOOLEAN} values={{ value: autoRenewal }} />
            </Line> */}
        </div>
    )
}

export default Status
