import React, { Component } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { ALL_PERMISSION_GROUPS, UPDATE_PERMISSION_GROUP } from '../../../graphql'
import { ErrorMessage } from '../../../infrastructure/react/components/common'
import Table from '../../common/Table'
import Create from './Create'

class RegistryPermissions extends Component {
  getColumns = () => {
    return [
      {
        name: 'url',
        label: 'Url',
        options: {
          filter: false,
          sort: true,
          sortDescFirst: false
        }
      },
      {
        name: 'operation',
        label: 'Operation',
        options: {
          filter: false,
          sort: true
        }
      }
    ]
  }

  getOptions = (data, deleteCallback) => {
    const { permissionGroup } = this.props
    return {
      filter: false,
      elevation: 0,
      textLabels: {
        body: {
          noMatch: ''
        }
      },
      customToolbar: () => <Create permissionGroup={permissionGroup} />,
      onRowsDelete: (options) => {
        deleteCallback({
          variables: {
            id: permissionGroup.id,
            name: permissionGroup.name,
            registryPermissions: permissionGroup.registryPermissions
              .filter((value, index) => !options.data.find(({ dataIndex }) => dataIndex === index))
              .map(({ url, operation }) => ({ url, operation }))
          }
        })
      }
    }
  }

  render() {
    const { permissionGroup } = this.props
    return (
      <Mutation mutation={UPDATE_PERMISSION_GROUP} refetchQueries={[{ query: ALL_PERMISSION_GROUPS }]}>
        {(updatePermissionGroup, { data = {}, error }) => {
          const columns = this.getColumns()
          const options = this.getOptions(permissionGroup.registryPermissions, updatePermissionGroup)
          const { updatePermissionGroup: { success, message } = {} } = data
          return (
            <div>
              <Table title='Registry Permissions' data={permissionGroup.registryPermissions} columns={columns} options={options} smallHeader />
              <ErrorMessage error={error} />
            </div>
          )
        }}
      </Mutation>
    )
  }
}

export default RegistryPermissions
