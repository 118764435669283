import React, { FC } from 'react'

import { Page } from '../common'
import Header from './Header'
import { Divider } from '@material-ui/core'
import Preferences from './Preferences'
import Summary from './Summary'

export const Wrapper: FC = ({ children }) => {
    return (
        <Page>
            <Header />
            <Divider />
            <Preferences />
            <Summary />
            {children}
        </Page>
    )
}
