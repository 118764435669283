import { ApplianceHealth, PortalError, PortalErrorType, Appliances, ConnectwareAppliance } from '../../../domain'

import { initialState } from '../../InitialState'
import { ConnectwareApplianceCreationRequest } from '../../services'

import { ConnectwareUsecase } from './Base'

export class ConnectwareApplianceUsecase extends ConnectwareUsecase {
    private getAppliances(): Appliances {
        const { appliances } = this.getState()
        return appliances
    }

    private setAppliances(state: Partial<Appliances>): void {
        this.setState({ appliances: { ...this.getAppliances(), ...state } })
    }

    // Load

    async loadAppliances(): Promise<void> {
        this.setAppliances(initialState.appliances)

        try {
            const appliances = await this.withAuthentication(() => this.connectwareApplianceService.fetchAppliances())

            this.setAppliances({ appliances })
        } catch (e) {
            this.setAppliances({ appliances: e as PortalError })
        }
    }

    // Togglers

    toggle(action: 'creation' | 'deletion'): void {
        const { toBeCreated, isDeleting } = this.getAppliances()

        switch (action) {
            case 'creation':
                this.setAppliances({ toBeCreated: toBeCreated ? null : { name: '', license: '' } })
                break
            case 'deletion':
                this.setAppliances({ isDeleting: !isDeleting })
                break
        }
    }

    // Create

    private createApplianceCreationRequest(): ConnectwareApplianceCreationRequest | null {
        const { toBeCreated } = this.getAppliances()

        if (!toBeCreated) {
            return null
        }

        const { license, name } = toBeCreated

        if (!license || !name) {
            return null
        }

        return { license, name }
    }

    async createAppliance(): Promise<void> {
        const request = this.createApplianceCreationRequest()

        if (!request) {
            throw new PortalError(PortalErrorType.STATE, 'Appliance could not be created')
        }

        await this.withAuthentication(() => this.connectwareApplianceService.createAppliance(request))
        await this.loadAppliances()
    }

    updateApplianceToBeCreated(appliance: Partial<Appliances['toBeCreated']>): boolean {
        const { toBeCreated } = this.getAppliances()

        if (!toBeCreated) {
            throw new PortalError(PortalErrorType.STATE, "Appliance to be created can't be updated")
        }

        this.setAppliances({ toBeCreated: { ...toBeCreated, ...appliance } })
        return Boolean(this.createApplianceCreationRequest())
    }

    // Delete

    async deleteAppliances(ids: ConnectwareAppliance['id'][]): Promise<void> {
        await this.withAuthentication(() => this.connectwareApplianceService.deleteAppliances(ids))
        await this.loadAppliances()
    }

    fetchHealth(id: string): Promise<ApplianceHealth> {
        return this.withAuthentication(() => this.connectwareApplianceService.fetchHealth(id))
    }

    fetchIsOnline(id: string): Promise<boolean> {
        return this.withAuthentication(() => this.connectwareApplianceService.fetchIsOnline(id))
    }
}
