import moment, { Moment } from 'moment'

export const MOMENT_DATE_FORMAT = 'DD.MM.YYYY'
const INVALID_DATE = '01.01.1970'

export const formatDate = (date: Date | Moment): string => moment(date).format(MOMENT_DATE_FORMAT)

export const isValidDate = (date: Date | Moment): boolean => !(formatDate(date) === INVALID_DATE || formatDate(date) === 'Invalid date')

export const getToday = (): Date => new Date(moment().format(moment.HTML5_FMT.DATE))

export const getDate = (date: Date | Moment): Date => new Date(moment(date).format(moment.HTML5_FMT.DATE))
