import gql from 'graphql-tag'
import moment from 'moment'

import { PortalError, PortalErrorType } from '../../../../domain'

import { buildMutationHandler, MutationHandler } from './Builders'
import { TypedResponse } from './Response'

const BULK_UPDATE_CONNECTWARE_LICENSE = gql`
    mutation BulkUpdate($ids: [ID]!, $expiration: Timestamp!) {
        bulkUpdateConnectwareLicense(ids: $ids, expiration: $expiration) {
            success
            message
        }
    }
`

interface BulkUpdateInput {
    readonly expiration: string
    readonly ids: string[]
}

interface UpdateConnectwareLicenseResponse extends TypedResponse<'UpdateConnectwareLicenseResponse'> {
    readonly success: boolean
    readonly message: string
}

export default (
    expiration: Date,
    ids: string[]
): MutationHandler<BulkUpdateInput, { readonly bulkUpdateConnectwareLicense: UpdateConnectwareLicenseResponse }, void> =>
    buildMutationHandler(
        {
            document: BULK_UPDATE_CONNECTWARE_LICENSE,
            mapper: ({ data }) => {
                if (!data?.bulkUpdateConnectwareLicense.success) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, 'Unexpected bulk edit response', { ...data?.bulkUpdateConnectwareLicense })
                }
            }
        },
        { expiration: moment(expiration, 'DD.MM.YYYY', true).format(), ids }
    )
