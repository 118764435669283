import React, { FC, useEffect, useState as useReactState } from 'react'

import { Typography, FormControl, Select, MenuItem, TextField, FormControlLabel, Checkbox } from '@material-ui/core'

import { Translation } from '../../../../../domain'

import { FormattedTranslation } from '../../../hooks'

import { useStyles } from './Styles'

type DirectoryFieldProps = {
    readonly defaultDirectories: string[]
    readonly directory: string
    readonly isValid: boolean
    readonly onChange: (directory: string) => void
    /**
     * Will be executed only once on the first render of the field
     */
    readonly onReady?: () => void
}

export const DirectoryField: FC<DirectoryFieldProps> = ({ directory: initialDirectory, defaultDirectories, isValid, onChange, onReady }) => {
    const classes = useStyles()

    const [isDefaultDirectory, setIsDefaultDirectory] = useReactState(true)
    const [directory, setDirectory] = useReactState(initialDirectory)

    /**
     * Whenever the directory parameter changes, we need to update our internal temporary control too
     */
    useEffect(() => {
        if (directory !== initialDirectory) {
            setDirectory(initialDirectory)
        }
    }, [initialDirectory])

    useEffect(() => {
        onReady?.()
    }, [])

    return (
        <div className={classes.dialogEntry}>
            <Typography gutterBottom variant="subtitle1">
                <FormattedTranslation id={Translation.UPLOAD_DIALOG_SELECT_DIRECTORY} />
            </Typography>

            <FormControlLabel
                control={
                    <Checkbox
                        name="is-custom-directory"
                        color="primary"
                        checked={isDefaultDirectory}
                        onChange={() => {
                            if (!isDefaultDirectory) {
                                // Select first
                                onChange(defaultDirectories[0])
                            } else {
                                onChange('')
                            }
                            setIsDefaultDirectory(!isDefaultDirectory)
                        }}
                    />
                }
                label={<FormattedTranslation id={Translation.DIRECTORY_FIELD_CONTROL_LABEL} />}
            />

            {isDefaultDirectory ? (
                <FormControl className={classes.formControl}>
                    <Select
                        // TODO Do we have to validate if someone has at least one directory?
                        value={defaultDirectories[0]}
                        onChange={({ target: { value } }) => onChange(String(value))}
                        inputProps={{ name: 'folder', id: 'folder' }}
                    >
                        {defaultDirectories.map((value) => (
                            <MenuItem value={value} key={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <TextField
                    name="customDirectory"
                    id="customDirectory"
                    label={<FormattedTranslation id={Translation.UPLOAD_DIALOG_CUSTOM_DIRECTORY_LABEL} />}
                    InputProps={{ placeholder: '' }}
                    value={directory}
                    autoComplete="off"
                    error={!isValid}
                    onChange={({ target: { value } }) => onChange(value)}
                />
            )}
        </div>
    )
}

type DirectoryLabelProps = {
    readonly directory?: string
    readonly noEntries?: boolean
}

export const DirectoryLabel: FC<DirectoryLabelProps> = ({ directory, noEntries }) => {
    const classes = useStyles()

    return (
        <div className={classes.dialogEntry}>
            <Typography gutterBottom variant="subtitle1">
                <FormattedTranslation id={Translation.DIRECTORY_LABEL} />
            </Typography>
            {noEntries ? <FormattedTranslation id={Translation.NO_ALLOWED_DIRECTORIES} /> : directory}
        </div>
    )
}
