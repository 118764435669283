import gql from 'graphql-tag'

import { DetailedConnectwareLicense } from '../../../../domain'

import { buildQueryHandler, QueryHandler } from './Builders'
import { LicenseClassResponse } from './LicenseClasses'
import { TypedResponse } from './Response'

interface PermissionGroupResponse extends TypedResponse<'PermissionGroup'> {
    readonly id: string
    readonly name: string
}

interface ConnectwareLicenseResponse extends TypedResponse<'ConnectwareLicense'> {
    readonly id: string
    readonly associatedAccount: string
    readonly connectwareLicenseClass: LicenseClassResponse['name']
    readonly name: string
    readonly description: string | null
    readonly expiration: string
    readonly autoRenewal: boolean
    readonly capabilities: { readonly features: Record<string, boolean>; readonly protocols: string[]; readonly resources: Record<string, number> }
    readonly permissionGroups: PermissionGroupResponse[]
}

type ConnectwareLicenseInput = { readonly id: string }

const CONNECTWARE_LICENSE = gql`
    query ConnectwareLicense($id: ID) {
        connectwareLicense(input: { id: $id }) {
            id
            associatedAccount
            connectwareLicenseClass
            name
            description
            expiration
            autoRenewal
            capabilities
            permissionGroups {
                id
                name
            }
        }
    }
`

export default (id: string): QueryHandler<ConnectwareLicenseInput, { readonly connectwareLicense: ConnectwareLicenseResponse }, DetailedConnectwareLicense> =>
    buildQueryHandler(
        {
            document: CONNECTWARE_LICENSE,
            mapper: ({
                data: {
                    connectwareLicense: {
                        name,
                        description,
                        id,
                        expiration,
                        autoRenewal,
                        permissionGroups,
                        associatedAccount,
                        connectwareLicenseClass,
                        capabilities
                    }
                }
            }) => ({
                name,
                description,
                id,
                expiration: new Date(expiration),
                autoRenewal,
                roles: permissionGroups.map(({ name, id }) => ({ id, name })),
                associatedAccount,
                licenseClass: connectwareLicenseClass,
                capabilities
            })
        },
        { id }
    )
