import React, { FC, useState as useReactState } from 'react'
import { PortalError, Translation } from '../../../../../domain'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, LinearProgress, TextField, makeStyles } from '@material-ui/core'
import { FormattedTranslation, useState, useUsecases } from '../../../hooks'
import { DateField, ErrorMessage } from '../../common'
import ConnectwareLicenseClass from '../../ConnectwareLicenseClass'
import { cybusAmber } from '../../../colors'
import { UserGroupsSelect } from '../../UserGroups'

const useStyles = makeStyles((theme) => ({
    dialogContent: { display: 'flex', flexDirection: 'column', width: '100%' },
    dialogEntry: { marginTop: `${theme.spacing(3)}px`, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', wordWrap: 'break-word' },
    dialogText: { width: '100%', textAlign: 'left' },
    errorText: { marginLeft: `${theme.spacing(3)}px` },
    icon: { marginRight: `${theme.spacing(2)}px`, color: ((theme.palette as unknown) as Partial<Record<string, typeof cybusAmber>>)?.accent?.[500] },
    form: { width: '100%', textAlign: 'center' },
    select: { alignSelf: 'left', width: '100%' }
}))

export const Edit: FC = () => {
    const classes = useStyles()
    const { manageConnectwareLicensesUsecase } = useUsecases()
    const license = useState((s) => s.manageLicenses.toBeEdited)
    const [error, setError] = useReactState<PortalError | null>(null)
    const [loading, setLoading] = useReactState(false)

    if (!license) return <ErrorMessage error={'Error loading license'} />

    const update = async (): Promise<void> => {
        if (!license.expiration || !license.associatedAccount || !license.name || !license.licenseClass) {
            return
        }
        try {
            setLoading(true)
            setError(null)
            await manageConnectwareLicensesUsecase.updateLicense()
            handleClose()
        } catch (e) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        manageConnectwareLicensesUsecase.toggleEdit()
    }

    return (
        <Dialog fullWidth disableEscapeKeyDown open onClose={handleClose} maxWidth="xs">
            <form
                className={classes.form}
                onSubmit={(e) => {
                    e.preventDefault()
                    void update()
                }}
            >
                <DialogTitle>Edit License</DialogTitle>
                <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.dialogEntry}>
                        <TextField
                            name="name"
                            id="name"
                            label="Name"
                            className={classes.dialogText}
                            InputProps={{ placeholder: '' }}
                            value={license.name}
                            autoComplete="off"
                            onChange={({ target: { value: name } }) => manageConnectwareLicensesUsecase.updateLicenseRequest({ name })}
                        />
                    </div>
                    <div className={classes.dialogEntry}>
                        <TextField
                            name="description"
                            id="description"
                            label="Description"
                            className={classes.dialogText}
                            InputProps={{ placeholder: '' }}
                            value={license.description}
                            autoComplete="off"
                            onChange={({ target: { value: description } }) => manageConnectwareLicensesUsecase.updateLicenseRequest({ description })}
                        />
                    </div>
                    <div className={classes.dialogEntry}>
                        <UserGroupsSelect
                            account={license.associatedAccount}
                            onChange={(associatedAccount) => manageConnectwareLicensesUsecase.updateLicenseRequest({ associatedAccount })}
                        />
                    </div>
                    <div className={classes.dialogEntry}>
                        <ConnectwareLicenseClass
                            selected={license.licenseClass || ''}
                            valueKey="name"
                            onChange={({ name: licenseClass }) => manageConnectwareLicensesUsecase.updateLicenseRequest({ licenseClass: licenseClass || '' })}
                        />
                    </div>
                    <div className={classes.dialogEntry}>
                        <DateField
                            name="expiration"
                            id="expiration"
                            initialValue={license.expiration}
                            label={<FormattedTranslation id={Translation.EXPIRATION} />}
                            className={classes.dialogText}
                            onChange={(expiration) => manageConnectwareLicensesUsecase.updateLicenseRequest({ expiration })}
                        />
                    </div>
                    {/* <div className={classes.dialogEntry}>
                  <FormControlLabel
                    control={<Switch name='autoRenewal' checked={Boolean(autoRenewal)} onChange={this.handleSwitchChange} color='primary' />}
                    label='Automatic license renewal'
                  />
                </div> */}
                    <div className={classes.dialogEntry}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="archived"
                                    checked={Boolean(license.archived)}
                                    onChange={({ target: { checked: archived } }) => manageConnectwareLicensesUsecase.updateLicenseRequest({ archived })}
                                    color="primary"
                                />
                            }
                            label="Archived"
                        />
                    </div>
                    {error && <ErrorMessage error={error} />}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        <FormattedTranslation id={Translation.CANCEL} />
                    </Button>
                    <Button color="primary" type="submit" disabled={Boolean(error) || loading}>
                        <FormattedTranslation id={Translation.SAVE} />
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
