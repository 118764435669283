import gql from 'graphql-tag'

import { ApplianceHealth } from '../../../../domain'

import { buildSubscriptionHandler, SubcriptionHandler } from './Builders'

type ApplienceInput = { readonly id: string }

const APPLIANCE_HEALTH = gql`
    subscription ApplianceHealth($id: ID!) {
        applianceHealth(id: $id)
    }
`

type ApplienceHealthResponse = { readonly applianceHealth: ApplianceHealth }

export default (id: string): SubcriptionHandler<ApplienceInput, ApplienceHealthResponse, ApplianceHealth> =>
    buildSubscriptionHandler({ document: APPLIANCE_HEALTH, mapper: (d) => d.applianceHealth }, { id })
