import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink, NormalizedCacheObject, split, Operation } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { Middleware, SubscriptionClient } from 'subscriptions-transport-ws'

import { PortalError, PortalErrorType } from '../../../domain'

import { LoggerService } from '../../../application/services'

export type GraphQLArgs = {
    readonly httpURL: string
    readonly webSocketURL: string
    /**
     * @deprecated revise this once everything is in typescript
     */
    readonly getToken: () => string | null
}

/**
 * @description on the long term, the high dependency of the project on graphql/apollo client should be revised
 * For now, this builder is here to minimize this dependency
 */
export class WrappedApolloClient {
    private readonly args: GraphQLArgs
    private readonly logger: LoggerService
    private readonly onNotAuthorizedListeners: Set<() => unknown>

    readonly client: ApolloClient<NormalizedCacheObject>

    constructor(args: GraphQLArgs, logger: LoggerService) {
        this.logger = logger
        this.args = args
        this.onNotAuthorizedListeners = new Set()
        this.client = this.build()
    }

    /**
     * @deprecated only needed for the legacy GraphQL requests
     */
    onNotAuthorized(listener: () => unknown): this {
        this.onNotAuthorizedListeners.add(listener)
        return this
    }

    private buildRequestsSplitter(): (op: Operation) => boolean {
        return ({ query }) => {
            const definition = getMainDefinition(query)
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
        }
    }

    private buildWebsocketRequestsHandler(): ApolloLink {
        const authenticationMiddleware: Middleware = {
            applyMiddleware: (options, next) => {
                options.authorization = this.args.getToken()
                next()
            }
        }

        const subscriptionClient = new SubscriptionClient(this.args.webSocketURL, { reconnect: true, lazy: true })

        /** Apollo wrapper */
        return new WebSocketLink(subscriptionClient.use([authenticationMiddleware]))
    }

    private buildHttpRequestsHandler(): ApolloLink {
        /**
         * @todo Update to use GraphQLRequest['context'] when it becomes properly typed
         */
        const authenticationHandler = setContext((_, { headers }: { headers: Record<string, unknown> }) => {
            const token = this.args.getToken()
            return { headers: { ...headers, authorization: token ? `Bearer ${token}` : '' } }
        })

        const endpointConfigurationHandler = createHttpLink({ uri: this.args.httpURL, credentials: 'same-origin' })

        /**
         * Make both act together
         */
        return authenticationHandler.concat(endpointConfigurationHandler)
    }

    private buildErrorResponseHandler(): ApolloLink {
        return onError(({ graphQLErrors, networkError, operation: { operationName } }) => {
            if (graphQLErrors) {
                graphQLErrors.forEach(({ extensions: { code } = {}, message, locations, path }) => {
                    if (code === 'UNAUTHENTICATED' || code === 'FORBIDDEN') {
                        this.onNotAuthorizedListeners.forEach((l) => l())
                    }

                    this.logger.error(
                        new PortalError(PortalErrorType.SERVER_ERROR, `Error on GraphQLResponse: ${message}`, {
                            operationName,
                            code: code as string,
                            locations,
                            path
                        })
                    )
                })
            }

            if (networkError) {
                this.logger.error(
                    new PortalError(PortalErrorType.SERVER_ERROR, `Error on Network communication: ${networkError.message}`, {
                        ...networkError,
                        operationName
                    })
                )
            }
        })
    }

    private build(): ApolloClient<NormalizedCacheObject> {
        const handlers =
            /**
             * In case there are errors, this will handle them
             */
            this.buildErrorResponseHandler()

                /** In case there are no errors (on request or response) */
                .concat(
                    split(
                        /**
                         * First the request will be split between websocket and http requests
                         */
                        this.buildRequestsSplitter(),

                        /**
                         * This will make sure websocket requests are properly handled
                         */
                        this.buildWebsocketRequestsHandler(),

                        /**
                         * This will make sure http requests are properly handled
                         */
                        this.buildHttpRequestsHandler()
                    )
                )

        return new ApolloClient({
            link: handlers,
            cache: new InMemoryCache(),
            defaultOptions: { watchQuery: { fetchPolicy: 'network-only', errorPolicy: 'all' }, query: { fetchPolicy: 'network-only', errorPolicy: 'all' } }
        })
    }
}
