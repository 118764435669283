import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { DELETE_CONNECTWARE_LICENSE } from '../../../graphql'
import { ErrorMessage } from '../../../infrastructure/react/components/common'
import { RoutePath, useRedirect, useUsecases } from '../../../infrastructure/react/hooks'

const styles = (theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  dialogEntry: {
    marginTop: `${theme.spacing(3)}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    wordWrap: 'break-word'
  },
  dialogText: {
    width: '100%',
    textAlign: 'left'
  },
  errorText: {
    marginLeft: `${theme.spacing(3)}px`
  },
  icon: {
    marginRight: `${theme.spacing(2)}px`,
    color: theme.palette.accent[500]
  },
  form: {
    width: '100%',
    textAlign: 'center'
  }
})

function Delete({ classes, id, toggleOpen }) {
  const r = useRedirect()
  const { manageConnectwareLicensesUsecase } = useUsecases()
  const [disabled, setDisabled] = useState(false)
  const [deleteConnectwareLicense, { loading, data }] = useMutation(DELETE_CONNECTWARE_LICENSE, {
    onCompleted: ({ deleteConnectwareLicense: { success } }) => {
      void manageConnectwareLicensesUsecase.loadLicenses()
      setDisabled(!success)
      handleDeleteComplete(success)
    }
  })

  const handleDeleteComplete = (success) => {
    if (success) {
      r.redirect(RoutePath.ADMIN_LICENSES)
    }
  }

  return (
    <Dialog open fullWidth disableEscapeKeyDown onClose={toggleOpen} maxWidth='xs'>
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault()
          deleteConnectwareLicense({ variables: { id } })
        }}
      >
        <DialogTitle>Delete License</DialogTitle>
        <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
        <DialogContent className={classes.dialogContent}>
          <div className={classes.dialogEntry}>
            <Typography className={classes.dialogText} variant='subtitle1' gutterBottom>
              Are you sure you want to delete this License?
            </Typography>
          </div>
          {data && <ErrorMessage error={data.deleteConnectwareLicense.message} />}
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={toggleOpen}>
            Cancel
          </Button>
          <Button color='primary' type='submit' disabled={loading || disabled}>
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default withRouter(withStyles(styles)(Delete))
