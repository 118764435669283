import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { ApplianceConnectwareLicense, SimpleConnectwareLicense, LicenseClass, DetailedConnectwareLicense, ManageableConnectwareLicense } from '../../../domain'

import {
    ConnectwareLicenseCreationRequest,
    ConnectwareLicenseDuplicationRequest,
    ConnectwareLicenseExpirationConfiguration,
    ConnectwareLicenseService,
    UpdateLicenseRequest,
    UpdateUserLicenseRequest
} from '../../../application/services'

import { ApolloConnectwareService } from './Connectware'

import buildLicenseClassQuery from './handlers/LicenseClasses'
import buildIsConnectwareLicenseUsed from './handlers/IsConnectwareLicenseUsed'
import buildAvailableConnectwareLicenses from './handlers/AvailableConnectwareLicenses'
import buildConnectwareLicense from './handlers/ConnectwareLicense'
import buildAllLicensesInAccount from './handlers/AllLicensesInAccount'
import buildConnectwareLicenses from './handlers/ConnectwareLicenses'
import buildBulkUpdate from './handlers/BulkUpdate'
import buildCreateConnectwareLicense from './handlers/CreateConnectwareLicense'
import buildDuplicateConnectwareLicense from './handlers/DuplicateConnectwareLicense'
import buildBulkDeleteConnectwareLicenses from './handlers/BulkDeleteConnectwareLicenses'
import buildGenerateLicenseKey from './handlers/GenerateLicenseKey'
import buildGenerateLicenseFile from './handlers/GenerateLicenseFile'
import { updateUserLicense as buildUpdateUserLicense, updateLicense as buildUpdateLicense } from './handlers/UpdateConnectwareLicense'

export class ApolloConnectwareLicenseService extends ApolloConnectwareService implements ConnectwareLicenseService {
    private expirationConfiguration: ConnectwareLicenseExpirationConfiguration

    constructor(apolloClient: ApolloClient<NormalizedCacheObject>, expirationConfiguration: ConnectwareLicenseExpirationConfiguration) {
        super(apolloClient)
        this.expirationConfiguration = expirationConfiguration
    }

    generateLicenseKey(id: string): Promise<string> {
        return this.mutate(buildGenerateLicenseKey(id))
    }
    generateLicenseFile(id: string): Promise<string> {
        return this.mutate(buildGenerateLicenseFile(id))
    }

    fetchUsage(id: string): Promise<boolean> {
        return this.query(buildIsConnectwareLicenseUsed(id))
    }

    fetchLicenseClasses(): Promise<LicenseClass[]> {
        return this.query(buildLicenseClassQuery())
    }

    fetchAvailableLicenses(associatedAccount: string | null): Promise<ApplianceConnectwareLicense[]> {
        return this.query(buildAvailableConnectwareLicenses(associatedAccount))
    }

    fetchManageableConnectwareLicense(id: string): Promise<DetailedConnectwareLicense> {
        return this.query(buildConnectwareLicense(id))
    }

    findAllLicensesInAccount(): Promise<SimpleConnectwareLicense[]> {
        return this.query(buildAllLicensesInAccount())
    }

    fetchExpirationConfiguration(): Promise<ConnectwareLicenseExpirationConfiguration> {
        return Promise.resolve(this.expirationConfiguration)
    }

    fetchAllLicenses(): Promise<ManageableConnectwareLicense[]> {
        return this.query(buildConnectwareLicenses())
    }

    createLicense(request: ConnectwareLicenseCreationRequest): Promise<void> {
        return this.mutate(buildCreateConnectwareLicense(request))
    }

    duplicateLicense(request: ConnectwareLicenseDuplicationRequest): Promise<void> {
        return this.mutate(buildDuplicateConnectwareLicense(request))
    }

    updateLicenseExpiration(expiration: Date, licenseIds: string[]): Promise<void> {
        return this.mutate(buildBulkUpdate(expiration, licenseIds))
    }

    deleteLicenses(ids: string[]): Promise<void> {
        return this.mutate(buildBulkDeleteConnectwareLicenses(ids))
    }

    updateUserLicense(request: UpdateUserLicenseRequest): Promise<void> {
        return this.mutate(buildUpdateUserLicense(request))
    }

    updateLicense(request: UpdateLicenseRequest): Promise<void> {
        return this.mutate(buildUpdateLicense(request))
    }
}
