import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Typography } from '@material-ui/core'
import React, { FC, useState } from 'react'
import { Translation } from '../../../../../../domain'
import { FormattedTranslation, useUsecases } from '../../../../hooks'
import { isDeleteModalOpen, useToggler } from '../../ManageRole.context'

interface Props {
    id: string
    selected: string[]
    onUpdate: (userGroupNames: string[]) => void
    setSelected: (rows: number[]) => void
}

const DeleteUserGroupModal: FC<Props> = ({ id, selected, onUpdate, setSelected }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const toggle = useToggler('deleteRoleModalOpen')
    const open = isDeleteModalOpen()
    const { connectwareUsersUsecase } = useUsecases()

    const deleteUserGroups = async (): Promise<void> => {
        // TODO: maybe introduce own usecase for connectwareRoles
        setLoading(true)
        const result = await connectwareUsersUsecase.deleteRole(id, selected)
        onUpdate(result.userGroups)
        setLoading(false)
        setSelected([])
        toggle()
    }

    return (
        <Dialog fullWidth disableEscapeKeyDown open={open} onClose={toggle} maxWidth={'xs'}>
            <DialogTitle>
                <FormattedTranslation id={Translation.DELETE_USER_GROUP_TITLE} />
            </DialogTitle>
            <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} />
            <DialogContent>
                <DialogContentText>Do you really want to revoke the access to the following User Groups?</DialogContentText>
                {/* {promise.value} */}
                {selected.map((name) => (
                    <Typography key={name}>{name}</Typography>
                ))}
            </DialogContent>
            <DialogActions>
                <Button color={'primary'} onClick={toggle}>
                    <FormattedTranslation id={Translation.CANCEL} />
                </Button>
                <Button color={'primary'} onClick={deleteUserGroups}>
                    <FormattedTranslation id={Translation.REVOKE} />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteUserGroupModal
