import gql from 'graphql-tag'

import { PortalError, PortalErrorType, ServiceMetaData } from '../../../../domain'

import { ServiceRequest } from '../../../../application/services'

import { TypedResponse } from './Response'
import { buildMutationHandler, MutationHandler } from './Builders'

const UPDATE_SERVICE = gql`
    mutation UpdateRepositoryFile(
        $filename: String!
        $directory: String!
        $description: String!
        $name: String!
        $category: String!
        $data: Base64Encoded
        $publiclyOffered: Boolean
        $isTemplate: Boolean
    ) {
        updateRepositoryFile(
            input: {
                filename: $filename
                directory: $directory
                description: $description
                name: $name
                category: $category
                data: $data
                publiclyOffered: $publiclyOffered
                isTemplate: $isTemplate
            }
        ) {
            success
            message
        }
    }
`
interface UpdateInput extends Pick<ServiceMetaData, 'filename' | 'directory' | 'name'> {
    data?: string | ArrayBuffer
    description?: string
    category?: string
    publiclyOffered?: boolean
    isTemplate?: boolean
}

interface UpdateServiceResponse extends TypedResponse<'UpdateServiceResponse'> {
    readonly success: boolean
    readonly message: string
}

export default ({
    filename,
    directory,
    data,
    name,
    description,
    publiclyOffered,
    isTemplate,
    category
}: ServiceRequest): MutationHandler<UpdateInput, { readonly updateRepositoryFile: UpdateServiceResponse }, void> =>
    buildMutationHandler(
        {
            document: UPDATE_SERVICE,
            mapper: ({ data }) => {
                if (!data?.updateRepositoryFile.success) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, data?.updateRepositoryFile.message)
                }
            }
        },
        {
            filename,
            directory,
            data: data || undefined,
            name,
            description,
            publiclyOffered,
            isTemplate,
            category
        }
    )
