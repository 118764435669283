import { ManageableConnectwareLicense, ManageableConnectwareLicenses, PortalError, PortalErrorType, Translation } from '../../../../domain'
import { getToday, isValidDate } from '../../../../infrastructure/react/components/utils'

import { initialState } from '../../../InitialState'

import { ConnectwareLicenseCreationRequest, ConnectwareLicenseDuplicationRequest, UpdateLicenseRequest } from '../../../services'

import { ConnectwareUsecase } from '../Base'

export class ManageConnectwareLicensesUsecase extends ConnectwareUsecase {
    // Utils

    private getManageableLicenses(): ManageableConnectwareLicenses {
        const { manageLicenses } = this.getState()
        return manageLicenses
    }

    private setManageableLicenses(newState: Partial<ManageableConnectwareLicenses>): void {
        const manageLicenses = this.getManageableLicenses()
        this.setState({ manageLicenses: { ...manageLicenses, ...newState } })
    }

    // Loading

    /**
     * Loads licenses to be managed
     */
    async loadLicenses(): Promise<void> {
        const { licenses } = this.getManageableLicenses()
        if (!licenses) this.setManageableLicenses(initialState.manageLicenses)
        try {
            this.setManageableLicenses({ licenses: await this.withAuthentication(() => this.connectwareLicenseService.fetchAllLicenses()) })
        } catch (e) {
            this.setManageableLicenses({ licenses: e as PortalError })
        }
    }

    // Create

    /**
     * Creates or drops license from creation state
     */
    toggleCreation(): void {
        const manageLicenses = this.getManageableLicenses()
        const toBeCreated = manageLicenses.toBeCreated
            ? null
            : { associatedAccount: '', name: '', expiration: getToday(), autoRenewal: false, licenseClass: '' }
        this.setManageableLicenses({ toBeCreated })
    }

    private createLicenseCreationRequest(): ConnectwareLicenseCreationRequest | null {
        const { toBeCreated } = this.getManageableLicenses()

        if (!toBeCreated) {
            return null
        }

        const { associatedAccount, licenseClass, expiration, autoRenewal, name } = toBeCreated

        if (!associatedAccount || !licenseClass || !expiration || !name) {
            return null
        }

        return { associatedAccount, name, expiration, autoRenewal, licenseClass }
    }

    /**
     * Updates the license in the state that will be dispatched to the backend
     * @returns if the license can be created
     */
    updateLicenseToBeCreated(license: Partial<ManageableConnectwareLicenses['toBeCreated']>): boolean {
        const manageLicenses = this.getManageableLicenses()

        if (!manageLicenses.toBeCreated) {
            throw new PortalError(PortalErrorType.STATE, "License to be created can't be updated")
        }

        this.setManageableLicenses({ toBeCreated: { ...manageLicenses.toBeCreated, ...license } })
        return Boolean(this.createLicenseCreationRequest())
    }

    async createLicense(): Promise<void> {
        const request = this.createLicenseCreationRequest()

        if (!request) {
            throw new PortalError(PortalErrorType.STATE, "License can't be created")
        }

        await this.withAuthentication(() => this.connectwareLicenseService.createLicense(request))
        await this.loadLicenses()
    }

    // Duplicate

    toggleDuplication(original?: ManageableConnectwareLicense): void {
        this.setManageableLicenses({
            toBeDuplicated: original
                ? {
                      duplicates: 1,
                      associatedAccount: original.associatedAccount,
                      name: this.translationService.translate(Translation.COPY_OF, { name: original.name }),
                      expiration: original.expiration,
                      autoRenewal: original.autoRenewal,
                      licenseClass: original.licenseClass,
                      id: original.id,
                      roles: original.roles
                  }
                : null
        })
    }

    private createLicenseDuplicationRequest(): ConnectwareLicenseDuplicationRequest | null {
        const { toBeDuplicated } = this.getManageableLicenses()

        if (!toBeDuplicated) {
            return null
        }

        const { associatedAccount, licenseClass, expiration, autoRenewal, name, duplicates, id, roles } = toBeDuplicated

        if (!associatedAccount || !licenseClass || !expiration || duplicates < 1 || roles.length === 0 || !name) {
            return null
        }

        return { duplicates, id, associatedAccount, name, expiration, autoRenewal, licenseClass, permissionGroups: roles.map(({ id }) => id) }
    }

    updateLicenseToBeDuplicated(license: Partial<ManageableConnectwareLicenses['toBeDuplicated']>): boolean {
        const { toBeDuplicated } = this.getManageableLicenses()

        if (!toBeDuplicated) {
            throw new PortalError(PortalErrorType.STATE, "License to be duplicated can't be updated")
        }

        this.setManageableLicenses({ toBeDuplicated: { ...toBeDuplicated, ...license } })

        return Boolean(this.createLicenseDuplicationRequest())
    }

    async duplicateLicense(): Promise<void> {
        const request = this.createLicenseDuplicationRequest()

        if (!request) {
            throw new PortalError(PortalErrorType.STATE, "License can't be duplicated")
        }

        await this.withAuthentication(() => this.connectwareLicenseService.duplicateLicense(request))
        await this.loadLicenses()
    }

    // Edit

    toggleBulkEdit(): void {
        const { isBulkEditing } = this.getManageableLicenses()
        this.setManageableLicenses({ isBulkEditing: !isBulkEditing })
    }

    async updateLicenseExpiration(expiration: Date, ...licenseIds: string[]): Promise<void> {
        await this.withAuthentication(() => this.connectwareLicenseService.updateLicenseExpiration(expiration, licenseIds))
        await this.loadLicenses()
    }

    toggleEdit(license?: ManageableConnectwareLicense): void {
        const { editLicense } = this.getManageableLicenses()
        if (license) {
            this.setManageableLicenses({ toBeEdited: license })
        } else {
            this.setManageableLicenses({ toBeEdited: null })
        }
        this.setManageableLicenses({ editLicense: !editLicense })
    }

    private createLicenseUpdatingRequest(): UpdateLicenseRequest | null {
        const { toBeEdited } = this.getManageableLicenses()

        if (!toBeEdited) {
            return null
        }

        const { associatedAccount, licenseClass, expiration, name, id, description, archived } = toBeEdited

        if (!associatedAccount || !licenseClass || !expiration || !isValidDate(expiration) || !name) {
            return null
        }

        return { id, associatedAccount, name, expiration, licenseClass, description, archived }
    }

    updateLicenseRequest(license: Partial<ManageableConnectwareLicenses['toBeEdited']>): boolean {
        const { toBeEdited } = this.getManageableLicenses()
        if (!toBeEdited) {
            throw new PortalError(PortalErrorType.STATE, 'Edited state null')
        }
        this.setManageableLicenses({ toBeEdited: { ...toBeEdited, ...license } })
        return Boolean(this.createLicenseUpdatingRequest())
    }

    async updateLicense(): Promise<void> {
        const { toBeEdited } = this.getManageableLicenses()
        if (!toBeEdited) {
            throw new PortalError(PortalErrorType.STATE, 'Edited state null')
        }
        await this.withAuthentication(() => this.connectwareLicenseService.updateLicense(toBeEdited))
        await this.loadLicenses()
    }

    // Remove

    toggleDeletion(): void {
        const { isBulkRemoving } = this.getManageableLicenses()
        this.setManageableLicenses({ isBulkRemoving: !isBulkRemoving })
    }

    async deleteLicenses(ids: string[]): Promise<void> {
        await this.withAuthentication(() => this.connectwareLicenseService.deleteLicenses(ids))
        await this.loadLicenses()
    }

    // Download license key
    toggleDownloadLicenseKey(): void {
        const { downloadLicenseKey } = this.getManageableLicenses()
        this.setManageableLicenses({ downloadLicenseKey: !downloadLicenseKey })
    }
    async generateLicenseKey(id: string): Promise<string> {
        return await this.withAuthentication(() => this.connectwareLicenseService.generateLicenseKey(id))
    }

    // Download license file
    toggleDownloadLicenseFile(): void {
        const { downloadLicenseFile } = this.getManageableLicenses()
        this.setManageableLicenses({ downloadLicenseFile: !downloadLicenseFile })
    }
    async generateLicenseFile(id: string): Promise<string> {
        return await this.withAuthentication(() => this.connectwareLicenseService.generateLicenseFile(id))
    }

    // Edit License assigned_to property
    toggleUserLicenseEdit(): void {
        const { editLicenseAsUser } = this.getManageableLicenses()
        this.setManageableLicenses({ editLicenseAsUser: !editLicenseAsUser })
    }

    async updateUserLicense(licenseName: string, id: string): Promise<void> {
        await this.withAuthentication(() => this.connectwareLicenseService.updateUserLicense({ name: licenseName, id }))
        await this.loadLicenses()
    }
}
