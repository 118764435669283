import React, { ChangeEvent, FC } from 'react'
import { Input, InputProps } from '@material-ui/core'

import { PortalError, PortalErrorType } from '../../../../../domain'

type UploadEvent = Pick<File, 'name'> & { result: string }

type Props = Omit<InputProps, 'ref' | 'type' | 'onChange'> & { readonly onChange: (errorOrFile: PortalError | UploadEvent) => unknown }

export const Upload: FC<Props> = ({ onChange, ...props }) => (
    <Input
        {...props}
        type="file"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0]

            if (!file) {
                onChange(new PortalError(PortalErrorType.UNEXPECTED, 'File not found on input'))
                return
            }

            const reader = new FileReader()

            reader.onloadend = () => {
                onChange({ result: reader.result as string, name: file.name })
            }

            reader.readAsText(file)
        }}
    />
)
