import React, { FC } from 'react'

import { IconButton, makeStyles, AppBar, Toolbar, Typography } from '@material-ui/core'
import { ExitToApp, Menu } from '@material-ui/icons'

import { Translation } from '../../../../domain'

import { Title, useTranslator, useUsecases } from '../../hooks'
import { useToggler } from './Navigation.context'

import { WIDTH } from './LeftSide'

const useStyles = makeStyles((theme) => ({
    hoverFocusHide: { '&:hover, &:focus': { backgroundColor: 'transparent' } },
    appBar: { marginLeft: WIDTH, [theme.breakpoints.up('lg')]: { width: `calc(100% - ${WIDTH}px)` } },
    menuButton: { marginRight: 20, [theme.breakpoints.up('lg')]: { display: 'none' } },
    toolbarRight: { marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }
}))

export const Header: FC = () => {
    const { authenticationUsecase } = useUsecases()
    const translator = useTranslator()
    const classes = useStyles()
    const toggle = useToggler()

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <IconButton color="inherit" aria-label={translator.formatTranslation(Translation.OPEN_DRAWER)} onClick={toggle} className={classes.menuButton}>
                    <Menu />
                </IconButton>
                <Typography variant="h6" color="inherit" noWrap>
                    <Title />
                </Typography>
                <div className={classes.toolbarRight}>
                    <IconButton
                        className={classes.hoverFocusHide}
                        size="small"
                        color="inherit"
                        aria-label={translator.formatTranslation(Translation.LOGOUT)}
                        title={translator.formatTranslation(Translation.LOGOUT)}
                        onClick={() => authenticationUsecase.logOut()}
                    >
                        <ExitToApp />
                    </IconButton>
                </div>
            </Toolbar>
        </AppBar>
    )
}
