import React, { FC } from 'react'
import { Translation } from '../../../../domain'
import { FormattedTranslation } from '../../hooks'

type Props = {
    readonly description: string | boolean
}

const IsInUse: FC<Props> = ({ description }) => {
    return <FormattedTranslation id={Translation.LICENSE_DESCRIPTION_VALUE} values={{ value: description }} />
}

export default IsInUse
