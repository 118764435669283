import gql from 'graphql-tag'
import { Metrics, PortalError, PortalErrorType } from '../../../../domain'
import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

const READ_ALL_METRICS = gql`
    query ReadAllMetrics {
        readAllMetrics {
            id
            connectwareLicenseId
            associatedAccount
            name
            received
            date
            numberOfDatapoints
            numberOfMessages
        }
    }
`

interface MetricsResponse extends TypedResponse<'Metric'>, Metrics {}

export default (): QueryHandler<void, { readonly readAllMetrics: MetricsResponse[] }, Metrics[]> =>
    buildQueryHandler(
        {
            document: READ_ALL_METRICS,
            mapper: ({ data: { readAllMetrics } }) => {
                if (!readAllMetrics) throw new PortalError(PortalErrorType.SERVER_ERROR, 'Not able to fetch all metrics')
                return readAllMetrics
            }
        },
        void 0
    )
