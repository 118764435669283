import { makeStyles } from '@material-ui/core'
import { MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables'
import React, { FC } from 'react'
import { Translation, UserGroup } from '../../../../domain'
import { RoutePath, useIsAdmin, useRedirect, useTranslator } from '../../hooks'
import { Table } from '../common'

const useStyles = (isAdmin: boolean) =>
    makeStyles({
        container: {
            display: 'grid',
            gridTemplateColumns: isAdmin ? 'repeat(2, 1fr)' : '1fr',
            gridColumnGap: '20px'
        }
    })

const options: MUIDataTableOptions = {
    selectableRows: 'none',
    filter: false,
    viewColumns: false,
    sortOrder: {
        name: 'name',
        direction: 'asc'
    },
    search: false,
    elevation: 0,
    textLabels: { body: { noMatch: '' } },
    pagination: false
}

const createTableColumns = (): MUIDataTableProps['columns'] => {
    const translator = useTranslator()
    return [
        {
            name: 'name',
            label: translator.formatTranslation(Translation.GROUP_LABEL),
            options: { filter: false, sort: true, sortDescFirst: false }
        }
    ]
}

const AdminTable: FC<{ data: UserGroup[] }> = ({ data }) => {
    const r = useRedirect()
    const translator = useTranslator()
    const tableOptions: Partial<MUIDataTableOptions> = {
        ...options,
        onCellClick: (_, { dataIndex }) => r.redirect(RoutePath.ADMIN_USER_GROUP, data[dataIndex].name)
    }

    return (
        <div data-testid="adminTable">
            <Table
                title={translator.formatTranslation(Translation.ADMIN_USER_GROUPS_HEADER_TITLE)}
                smallHeader
                data={data}
                columns={createTableColumns()}
                options={tableOptions}
            />
        </div>
    )
}
const UserTable: FC<{ data: UserGroup[] }> = ({ data }) => {
    const r = useRedirect()
    const translator = useTranslator()
    const tableOptions: Partial<MUIDataTableOptions> = {
        ...options,
        onCellClick: (_, { dataIndex }) => r.redirect(RoutePath.ADMIN_USER_GROUP, data[dataIndex].name)
    }
    return (
        <div data-testid="userTable">
            <Table
                title={translator.formatTranslation(Translation.USER_GROUPS_TITLE)}
                smallHeader
                data={data}
                columns={createTableColumns()}
                options={tableOptions}
            />
        </div>
    )
}

interface Props {
    readonly user: UserGroup[]
    readonly admin: UserGroup[]
}

export const Tables: FC<Props> = ({ user, admin }) => {
    const isAdmin = Boolean(useIsAdmin())
    const classes = useStyles(isAdmin)()
    return (
        <div className={classes.container}>
            <UserTable data={user} />
            {isAdmin && <AdminTable data={admin} />}
        </div>
    )
}
