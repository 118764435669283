import React, { Context, createContext, FC, useContext, useState } from 'react'

import { PortalError, PortalErrorType } from '../../../../domain'

type State = { readonly isDrawerOpen: boolean }

const NavigationStateContext = createContext<State | null>(null)
const NavigationDispatchContext = createContext<((state: State) => void) | null>(null)

export const NavigationProvider: FC<State> = ({ children, isDrawerOpen }) => {
    const [isOpen, setOpen] = useState<State>({ isDrawerOpen })

    return (
        <NavigationStateContext.Provider value={isOpen}>
            <NavigationDispatchContext.Provider value={setOpen}>{children}</NavigationDispatchContext.Provider>
        </NavigationStateContext.Provider>
    )
}

const getContext = <T extends unknown>(context: Context<T | null>): T => {
    const foundContext = useContext(context)

    if (foundContext === null) {
        throw new PortalError(PortalErrorType.STATE, 'NavigationContext must be used with-in a NavigationProvider')
    }

    return foundContext
}

export const isOpen = (): boolean => getContext(NavigationStateContext).isDrawerOpen

export const useToggler = (): VoidFunction => {
    const state = getContext(NavigationStateContext)
    const setter = getContext(NavigationDispatchContext)

    return () => setter({ ...state, isDrawerOpen: !state.isDrawerOpen })
}
