import React, { FC, useEffect } from 'react'
import { useUsecases } from '../../hooks'
import { ServicesCatalog } from './ServicesCatalog'
import ServicesDetails from './Details'
import Upload from './Upload'
import Delete from './Delete'

export const Wrapper: FC = ({ children }) => {
    const { manageServicesCatalogUsecase } = useUsecases()

    useEffect(() => void manageServicesCatalogUsecase.loadData(), [])

    return (
        <>
            <ServicesCatalog />
            <ServicesDetails />
            <Upload />
            <Delete />
            {children}
        </>
    )
}
