import { makeStyles, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { Translation } from '../../../../domain'
import { FormattedTranslation, Username } from '../../hooks'
import { AppVersion } from '../Navigation/Version'

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    title: {
        padding: '1rem 0px'
    }
}))

const Header: FC = () => {
    const classes = useStyles()
    return (
        <div className={classes.container}>
            <Typography className={classes.title} variant="h6">
                <FormattedTranslation id={Translation.WELCOME_TITLE} />
                <AppVersion />
            </Typography>
            <Typography variant="subtitle1">
                <Username />
            </Typography>
        </div>
    )
}
export default Header
