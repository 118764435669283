import React, { FC, useMemo } from 'react'
import { isPending, isRejected, usePromise } from 'react-sync-promise'
import { makeStyles, InputLabel, MenuItem, FormControl, Select as MuiSelect, CircularProgress } from '@material-ui/core'

import { PortalError, Translation } from '../../../../domain'

import { FormattedTranslation, useUsecases } from '../../hooks'
import { ErrorMessage } from '../common'

const useStyles = makeStyles((theme) => ({
    root: { display: 'flex', flexWrap: 'wrap', width: '100%' },
    formControl: { margin: `${theme.spacing(1)} 0px`, textAlign: 'left', width: '100%' },
    selectEmpty: { marginTop: theme.spacing(2) }
}))

type Props = { readonly account: string | null; readonly onChange: (account: string) => void }

const Select: FC<Props> = ({ account, onChange }) => {
    const { connectwareUsersUsecase } = useUsecases()

    const promise = usePromise<string[], PortalError>(useMemo(() => connectwareUsersUsecase.fetchUserGroups(), []))

    if (isPending(promise)) {
        return <CircularProgress size={30} />
    }

    if (isRejected(promise)) {
        return <ErrorMessage error={promise.value} />
    }

    return (
        <MuiSelect value={account} onChange={(e) => onChange(e.target.value as string)} inputProps={{ name: 'associatedAccount', id: 'associatedAccount' }}>
            {promise.value.map((userGroup) => (
                <MenuItem value={userGroup} key={userGroup}>
                    {userGroup}
                </MenuItem>
            ))}
        </MuiSelect>
    )
}

export const UserGroupsSelect: FC<Props> = (props) => {
    const classes = useStyles()

    return (
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor="associatedAccount">
                <FormattedTranslation id={Translation.ACCOUNT} />
            </InputLabel>
            <Select {...props} />
        </FormControl>
    )
}
