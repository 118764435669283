// import { Moment } from 'moment'

export type ConnectwareLicenseExpirationConfiguration = {
    /**
     * In days
     */
    readonly toExpireSoon: number
    readonly expired: number
}

export type ApplianceHealth = 'Healthy' | 'Updating' | 'Unhealthy' | 'Unavailable'

export interface Roles {
    readonly id: string
    readonly name: string
    readonly users: string[]
    readonly userGroups: string[]
    readonly connectwareLicenses: FullConnectwareLicense[]
    readonly registryPermissions: RegistryPermissions[]
}
export type Role = Pick<Roles, 'id' | 'name'>

enum Operation {
    'Push',
    'Pull'
}
interface RegistryPermissions {
    readonly url: string
    readonly operation: Operation
}

export interface LicenseClass {
    readonly id: string
    readonly name: string
}

export interface UserGroupRoles {
    readonly userGroup: string
    readonly roles: string[]
}

export interface UserGroupRolesAndUsers {
    readonly name: string
    readonly amountOfRoles: number
    readonly amountOfUsers: number
}

export type PortalUserSummary = UserAndAdminGroups & {
    readonly numberOfAppliances: number
    readonly numberOfLicenses: number
    readonly numberOfServices: number
}

export interface UserAndAdminGroups {
    readonly adminGroups: UserGroup[]
    readonly userGroups: UserGroup[]
}

export interface UserGroup {
    name: string
}

export interface User {
    readonly userName: string
    readonly createdAt: Date
    readonly enabled: boolean
    readonly status: string
    readonly emailVerified: boolean
    readonly email: string
}

export interface ConnectwareLicenseCapabilities {
    readonly features: Record<string, boolean>
    readonly protocols: string[]
    readonly resources: Record<string, number>
}

interface FullConnectwareLicense {
    readonly id: string
    readonly associatedAccount: string
    readonly licenseClass: LicenseClass['name']
    readonly name: string
    readonly description: string | null
    readonly licenseKey: string
    readonly expiration: Date
    readonly autoRenewal: boolean
    readonly archived: boolean
    readonly roles: Pick<Roles, 'id' | 'name'>[]

    readonly capabilities: ConnectwareLicenseCapabilities
}

/**
 * For the licenses listed as an attribute of Appliances
 * @todo revise how to export this once appliances are mapped
 */
export type ApplianceConnectwareLicense = Pick<FullConnectwareLicense, 'id' | 'name'>

export interface ConnectwareAppliance {
    readonly id: string
    readonly name: string
    readonly license: ApplianceConnectwareLicense
}

/**
 * For when you need to list multiple licenses
 */
export type SimpleConnectwareLicense = Pick<FullConnectwareLicense, 'id' | 'name' | 'description' | 'expiration' | 'licenseClass' | 'autoRenewal'>

/**
 * For when you need a detailed breakdown of a license
 */
export type DetailedConnectwareLicense = Pick<
    FullConnectwareLicense,
    'id' | 'name' | 'expiration' | 'description' | 'autoRenewal' | 'associatedAccount' | 'licenseClass' | 'capabilities' | 'roles'
>

/**
 * Fully manageable license
 */
export type ManageableConnectwareLicense = FullConnectwareLicense

export enum ConnectwareAccountType {
    ADMIN = 'ADMIN',
    NORMAL = 'NORMAL',
    SUPER_ADMIN = 'SUPER_ADMIN'
}

export type ConnectwareAccount = {
    readonly type: ConnectwareAccountType
    readonly username: string
    readonly userGroups: string[]
}

const createAccountTypeChecker = (types: ConnectwareAccountType[]): ((typeOrAccount: ConnectwareAccountType | Pick<ConnectwareAccount, 'type'>) => boolean) => {
    const typesSet = new Set(types)

    return (typeOrAccount) => {
        const type = typeof typeOrAccount === 'string' ? typeOrAccount : typeOrAccount.type
        return typesSet.has(type)
    }
}

export const isAdmin = createAccountTypeChecker([ConnectwareAccountType.ADMIN, ConnectwareAccountType.SUPER_ADMIN])
export const isSuperAdmin = createAccountTypeChecker([ConnectwareAccountType.SUPER_ADMIN])
