import { ApplianceHealth, ConnectwareAppliance } from '../../../domain'

import { ConnectwareApplianceService, ConnectwareApplianceCreationRequest } from '../../../application/services'

import { ApolloConnectwareService } from './Connectware'
import buildApplianceOnline from './handlers/ApplianceOnline'
import buildApplianceHealth from './handlers/ApplianceHealth'
import buildConnectwareAppliances from './handlers/ConnectwareAppliances'
import buildBulkDeleteConnectwareAppliance from './handlers/BulkDeleteConnectwareAppliance'
import buildCreateConnectwareAppliance from './handlers/CreateConnectwareAppliance'

export class ApolloConnectwareApplianceService extends ApolloConnectwareService implements ConnectwareApplianceService {
    createAppliance(request: ConnectwareApplianceCreationRequest): Promise<void> {
        return this.mutate(buildCreateConnectwareAppliance(request))
    }

    deleteAppliances(ids: ConnectwareAppliance['id'][]): Promise<void> {
        return this.mutate(buildBulkDeleteConnectwareAppliance(ids))
    }

    fetchAppliances(): Promise<ConnectwareAppliance[]> {
        return this.query(buildConnectwareAppliances())
    }

    fetchHealth(id: string): Promise<ApplianceHealth> {
        return this.subscribe(buildApplianceHealth(id))
    }

    fetchIsOnline(id: string): Promise<boolean> {
        return this.subscribe(buildApplianceOnline(id))
    }
}
