import React, { FC, useEffect } from 'react'

import { ConnectwareAccount, isAdmin, isSuperAdmin, PortalError } from '../../../domain'

import { useState, useUsecases } from '.'

export const useAccount = (): ConnectwareAccount | null => {
    const { authenticationUsecase } = useUsecases()

    useEffect(() => void authenticationUsecase.loadAccountInformation(), [])

    return useState((s) => (s.account instanceof PortalError ? null : s.account))
}

export const useUsername = (): string | null => {
    const account = useAccount()
    return account && account.username
}

export const Username: FC = () => {
    const username = useUsername()
    return <>{username}</>
}

export const useIsAdmin = (): boolean | null => {
    const account = useAccount()
    return account && (isAdmin(account) || isSuperAdmin(account))
}

export const useIsSuperAdmin = (): boolean | null => {
    const account = useAccount()
    return account && isSuperAdmin(account)
}
