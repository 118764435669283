import React, { FC } from 'react'

import { makeStyles, IconButton, Tooltip } from '@material-ui/core'
import { Add } from '@material-ui/icons'

import { Translation } from '../../../../../domain'

import { FormattedTranslation, useUsecases } from '../../../hooks'

const useStyles = makeStyles({ container: { marginLeft: '15px' } })

export const CreateButton: FC = () => {
    const classes = useStyles()
    const { manageConnectwareLicensesUsecase } = useUsecases()

    return (
        <span className={classes.container}>
            <Tooltip title={<FormattedTranslation id={Translation.CREATE_LICENSE} />}>
                <IconButton id="create-license-button" color="primary" onClick={() => manageConnectwareLicensesUsecase.toggleCreation()}>
                    <Add />
                </IconButton>
            </Tooltip>
        </span>
    )
}
