import gql from 'graphql-tag'

import { buildSubscriptionHandler, SubcriptionHandler } from './Builders'

type ApplienceInput = { readonly id: string }

const APPLIANCE_ONLINE = gql`
    subscription ApplianceOnline($id: ID!) {
        applianceOnline(id: $id)
    }
`

type ApplienceOnlineResponse = { readonly applianceOnline: boolean }

export default (id: string): SubcriptionHandler<ApplienceInput, ApplienceOnlineResponse, boolean> =>
    buildSubscriptionHandler({ document: APPLIANCE_ONLINE, mapper: (d) => d.applianceOnline }, { id })
