import React, { FC } from 'react'
import { Divider, CircularProgress } from '@material-ui/core'

import { isPortalError } from '../../../../domain'

import { useParameters } from '../../hooks'

import { ErrorMessage, Page } from '../common'
import Header from './Header'
import LicenseStatus from './Status'
import {
    // Features, Resources,
    Protocols
} from './capabilities'
import { useLicense } from './hooks'

const License: FC = () => {
    const { id } = useParameters('id')
    const license = useLicense(id)

    if (!license) {
        return <CircularProgress />
    }

    if (isPortalError(license)) {
        return <ErrorMessage error={license} />
    }

    return (
        <Page>
            <Header id={id} />
            <Divider />
            <LicenseStatus id={id} />
            <Divider />
            {/* <Features id={id} />
            <Resources id={id} /> */}
            <Protocols id={id} />
        </Page>
    )
}

export default License
