import { PortalError } from '../../../domain'

import { Usecase } from '../Usecase'

const TOKEN_ID = 'token'

export class AuthenticationUsecase extends Usecase {
    /**
     * Clears the user authentication on the app and takes them to the login page
     */
    logOut(): void {
        this.persistenceService.delete(TOKEN_ID)
        this.navigationService.redirect(this.redirectAuthenticationService.getRedirectUrl())
    }

    /**
     * Retrieves token from persistence layer/authentication service
     */
    private retrieveToken(): string | null {
        /**
         * Retrieve token from the persitence layer
         */
        const token = this.persistenceService.find(TOKEN_ID)

        if (token) {
            return token
        }

        /**
         * There is no peristed token, so attempt to digest url
         */
        const hash = this.navigationService.getUrlHash()
        if (!hash) {
            return null
        }

        const parameters = new URLSearchParams(hash)

        return this.redirectAuthenticationService.parseUrlParameters(parameters)
    }

    /**
     * Loads the token from the persistence layer, and puts it into the application state
     */
    loadToken(): boolean {
        const token = this.retrieveToken()

        if (token) {
            this.setState({ token })
            this.persistenceService.insert(TOKEN_ID, token)
        }

        return Boolean(token)
    }

    /**
     * Loads the users account information into the application state
     */
    async loadAccountInformation(): Promise<void> {
        try {
            this.setState({ account: await this.connectwareUserService.fetchInformation() })
        } catch (e) {
            this.setState({ account: e as PortalError })
        }
    }
}
