import { ConnectwareAccount, PortalUserSummary, Role, Roles, User, UserAndAdminGroups, UserGroupRoles, UserGroupRolesAndUsers } from '../../../domain'

import { ConnectwareRoleAccessCreationRequest, ConnectwareRoleAccessDeletionRequest, ConnectwareUserService } from '../../../application/services'

import { ApolloConnectwareService } from './Connectware'
import buildUserInfo from './handlers/UserInfo'
import buildAccounts from './handlers/Accounts'
import buildUserGroups from './handlers/UserGroups'
import buldUserGroupsRoles from './handlers/PermissionGroupsByUserGroups'
import buildGrantAccessToRole from './handlers/GrantAccessToRole'
import buildRevokeAccessToRole from './handlers/RevokeAccessToRole'
import buildListUsersInUserGroup from './handlers/ListUsersInUserGroup'
import buildListAllUsers from './handlers/ListAllUsers'
import buildListUserGroupsRolesAndUsers from './handlers/ListUserGroupsRolesAndUsers'
import buildListUserAndAdminGroups from './handlers/ListUserAndAdminGroups'
import buildPortalSummary from './handlers/PortalSummary'
import buildRolesByUserGroupName from './handlers/RolesByUserGroupName'

export class ApolloConnectwareUserService extends ApolloConnectwareService implements ConnectwareUserService {
    updateRole(request: ConnectwareRoleAccessCreationRequest): Promise<Pick<Roles, 'userGroups'>> {
        return this.mutate(buildGrantAccessToRole(request))
    }
    fetchUserGroups(): Promise<string[]> {
        return this.query(buildUserGroups())
    }

    fetchInformation(): Promise<ConnectwareAccount> {
        return this.query(buildUserInfo())
    }

    fetchAccountNames(): Promise<string[]> {
        return this.query(buildAccounts())
    }

    fetchUserGroupsRoles(): Promise<UserGroupRoles[]> {
        return this.query(buldUserGroupsRoles())
    }
    deleteRole(request: ConnectwareRoleAccessDeletionRequest): Promise<Pick<Roles, 'userGroups'>> {
        return this.mutate(buildRevokeAccessToRole(request))
    }
    listUsersInUserGroup(groupName: string): Promise<User[]> {
        return this.query(buildListUsersInUserGroup(groupName))
    }
    listAllUsers(): Promise<User[]> {
        return this.query(buildListAllUsers())
    }
    listUserGroupsRolesAndUsers(): Promise<UserGroupRolesAndUsers[]> {
        return this.query(buildListUserGroupsRolesAndUsers())
    }
    listUserAndAdminGroups(): Promise<UserAndAdminGroups> {
        return this.query(buildListUserAndAdminGroups())
    }
    fetchPortalUserSummary(): Promise<PortalUserSummary> {
        return this.query(buildPortalSummary())
    }
    fetchRolesByUserGroupName(userGroupName: string): Promise<Role[]> {
        return this.query(buildRolesByUserGroupName(userGroupName))
    }
}
