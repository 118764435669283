import React, { FC } from 'react'
import { Theme, MuiThemeProvider } from '@material-ui/core'

import { AppState } from '../../domain'

import { Usecases } from '../../application/usecases'
import { ConfigurationService, LoggerService } from '../../application/services'

import { TranslationProvider, ServicesProvider, UsecasesProvider, StateProvider, Store, TranslationProviderProps } from './hooks'

export type ReactAppInjections = {
    /**
     * The main app style
     */
    readonly materailUiTheme: Theme

    /**
     * The logger and configuration to be used throughout the application
     */
    readonly logger: LoggerService
    readonly configuration: ConfigurationService
    readonly store: Store<AppState>

    /**
     * Translation relevant args
     */
    readonly intl: TranslationProviderProps['intl']

    readonly usecases: Usecases
}

export const AppWrapper: FC<ReactAppInjections> = ({ materailUiTheme, usecases, store, logger, configuration, intl, children }) => (
    <StateProvider store={store}>
        <UsecasesProvider {...usecases}>
            <ServicesProvider logger={logger} configuration={configuration}>
                <TranslationProvider intl={intl}>
                    <MuiThemeProvider theme={materailUiTheme}>{children}</MuiThemeProvider>
                </TranslationProvider>
            </ServicesProvider>
        </UsecasesProvider>
    </StateProvider>
)
