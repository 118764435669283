import React, { FC, useEffect, useState as useReactState } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    makeStyles,
    LinearProgress,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core'
import { ManageableConnectwareLicense, PortalError, Translation } from '../../../../../domain'

import { FormattedTranslation, useState, useUsecases } from '../../../hooks'
import { ErrorMessage, useSelectedRows } from '../../common'
import { cybusAmber } from '../../../colors'

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    dialogEntry: {
        marginTop: `${theme.spacing(3)}px`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        wordWrap: 'break-word'
    },
    dialogText: {
        width: '100%',
        textAlign: 'left'
    },
    errorText: {
        marginLeft: `${theme.spacing(3)}px`
    },
    icon: {
        marginRight: `${theme.spacing(2)}px`,
        // TODO Fix this
        color: ((theme.palette as unknown) as Partial<Record<string, typeof cybusAmber>>)?.accent?.[500]
    },
    form: {
        width: '100%',
        textAlign: 'center'
    },
    list: {
        alignSelf: 'baseline'
    }
}))

export const DeleteModal: FC = () => {
    const classes = useStyles()
    const { manageConnectwareLicensesUsecase } = useUsecases()

    const [loading, setLoading] = useReactState(false)
    const [error, setError] = useReactState<PortalError | null>(null)
    const [rows] = useSelectedRows<ManageableConnectwareLicense>()

    const isDeleting = useState((s) => Boolean(s.manageLicenses?.isBulkRemoving))

    useEffect(() => {
        if (!isDeleting) {
            setLoading(false)
            setError(null)
        }
    }, [isDeleting])

    if (!isDeleting) {
        return null
    }

    const remove = async (): Promise<void> => {
        try {
            setLoading(true)
            setError(null)
            await manageConnectwareLicensesUsecase.deleteLicenses(rows.map(({ id }) => id))
        } catch (e) {
            setError(e)
            return
        } finally {
            setLoading(false)
            handleClose()
        }
    }

    const handleClose = () => {
        void manageConnectwareLicensesUsecase.toggleDeletion()
    }

    return (
        <Dialog open fullWidth disableEscapeKeyDown onClose={handleClose} maxWidth="xs">
            <form
                className={classes.form}
                onSubmit={(e) => {
                    e.preventDefault()
                    void remove()
                }}
            >
                <DialogTitle>
                    <FormattedTranslation id={Translation.DELETE_LICENSES} />
                </DialogTitle>
                <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.dialogEntry}>
                        <Typography className={classes.dialogText} variant="subtitle1" gutterBottom>
                            <FormattedTranslation id={Translation.LICENSES_DELETING_DESCRIPTION} />
                        </Typography>
                        <List className={classes.list}>
                            {rows.map(({ name, associatedAccount }, k) => (
                                <ListItem key={k}>
                                    <ListItemText secondary={<Typography variant="body2">{`- ${name}: (${associatedAccount})`}</Typography>} />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    {error && <ErrorMessage error={error} />}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        <FormattedTranslation id={Translation.CANCEL} />
                    </Button>
                    <Button color="primary" type="submit" disabled={loading}>
                        <FormattedTranslation id={Translation.CONFIRM} />
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
