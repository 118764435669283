import { MetricsRequest, MetricsService } from '../../../application/services'
import { Metrics } from '../../../domain'
import { ApolloConnectwareService } from './Connectware'

import buildFetchAllMetrics from './handlers/Metrics'
import buildUploadMetrics from './handlers/UploadMetrics'

export class ApolloMetricsService extends ApolloConnectwareService implements MetricsService {
    async fetchAllMetrics(): Promise<Metrics[]> {
        return await this.query(buildFetchAllMetrics())
    }

    async upload(request: MetricsRequest): Promise<void> {
        return await this.mutate(buildUploadMetrics(request))
    }
}
