import gql from 'graphql-tag'

/**
 * Types and Interfaces
 */
export interface ConnectwareLicense {
    id: string
    associatedAccount: string
    connectwareLicenseClass: string
    licenseKey: string
    name: string
    expiration: string
    autoRenewal: boolean
    associatedApplianceId: string
    capabilities: Record<string, unknown>
    permissionGroups: Record<string, unknown>
    archived: boolean
}

export const RELEASES = gql`
    query Releases {
        releases {
            name
            recommended
        }
    }
`

export const ALL_LICENSES_IN_ACCOUNT = gql`
    query AllLicensesInAccount {
        availableConnectwareLicenses {
            id
            associatedAccount
            connectwareLicenseClass
            name
            expiration
            autoRenewal
            archived
            permissionGroups {
                id
                name
            }
        }
    }
`

export const LICENSE = gql`
    query ConnectwareLicense($id: ID) {
        connectwareLicense(input: { id: $id }) {
            id
            associatedAccount
            connectwareLicenseClass
            name
            description
            expiration
            autoRenewal
            capabilities
            archived
            permissionGroups {
                id
                name
            }
        }
    }
`

export const ALL_LICENSES = gql`
    query ConnectwareLicenses {
        connectwareLicenses {
            id
            associatedAccount
            connectwareLicenseClass
            licenseKey
            name
            description
            expiration
            autoRenewal
            capabilities
            archived
            permissionGroups {
                id
                name
            }
        }
    }
`

export const CONNECTWARE_LICENSES = gql`
    query ConnectwareLicenses {
        connectwareLicenses {
            id
            associatedAccount
            connectwareLicenseClass
            name
            description
            expiration
            autoRenewal
            archived
            permissionGroups {
                id
                name
            }
        }
    }
`

export const DEFAULT_CAPABILITIES = gql`
    query ConnectwareLicenses {
        defaultCapabilities
    }
`

export const ALL_PERMISSION_GROUPS = gql`
    query ReadAllPermissionGroups {
        permissionGroups {
            id
            name
            users
            userGroups
            connectwareLicenses {
                id
                name
                associatedAccount
            }
            registryPermissions {
                url
                operation
            }
        }
    }
`

export const PERMISSION_GROUP = gql`
    query ReadPermissionGroup($id: ID!) {
        permissionGroup(id: $id) {
            id
            name
            users
            userGroups
            connectwareLicenses {
                id
                name
                associatedAccount
            }
            registryPermissions {
                url
                operation
            }
        }
    }
`

export const LATEST_OS_VERSION = gql`
    query LatestOsVersion {
        latestOsVersion
    }
`
export const CONNECTWARE_INSTANCE = gql`
    query SelfHostedConnectwareAppliance($id: ID!) {
        selfHostedConnectwareAppliance(id: $id) {
            id
            name
            connectwareLicense {
                associatedAccount
                connectwareLicenseClass
                name
            }
        }
    }
`
