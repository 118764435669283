import React, { FC, useEffect, useRef, useState as useReactState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedTranslation, useTranslator, useUsecases } from '../../../hooks'
import { ErrorMessage } from '../../common'
import { isPortalError, PortalError, PortalErrorType, Translation } from '../../../../../domain'
import { customStrongFormatter } from '../../utils'
import { download } from '../../../../../utils'
import { useLicense } from '../hooks'

const useStyles = makeStyles((theme) => ({
    root: { width: '100%' },
    title: { textAlign: 'center' },
    dialogContent: { width: '100%', padding: '0px' },
    header: { color: 'rgba(0, 0, 0, 0.54)', textAlign: 'left' },
    container: { display: 'flex', width: '100%', padding: '10px 0px' },
    column: { width: '50%', padding: '10px' },
    columnLeft: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' },
    configuration: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' },
    divider: { width: '100%', height: '1.5px', backgroundColor: 'rgba(0, 0, 0, 0.12)' },
    hidden: { height: '0px', width: '0px', opacity: '0', padding: '0px', margin: '0px' },
    innerContainer: { marginTop: '15px' },
    buttonArea: { marginTop: '7px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
    textField: { width: '100%', textAlign: 'left' },
    dialogText: { width: '100%', textAlign: 'left' },
    form: { marginBottom: theme.spacing(2) },
    licenseFileDownload: { display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }
}))

type Props = {
    id?: string
}

const Download: FC<Props> = ({ id }) => {
    const { manageConnectwareLicensesUsecase } = useUsecases()
    const [connectwareLicenseKey, setConnectwareLicenseKey] = useReactState('')
    const [error, setError] = useReactState<string | null>(null)
    const [licenseDescription, setLicenseDescription] = useReactState<string | null>('')
    const textArea = useRef<HTMLTextAreaElement>(null)
    const classes = useStyles()
    const translator = useTranslator()
    const license = useLicense(id || '')

    useEffect(() => {
        if (id) {
            if (!license || isPortalError(license)) return
            setLicenseDescription(license.description)
        }
        return () => setLicenseDescription(null)
    }, [id])

    const downloadFile = (licenseFile?: string) => {
        const file = new File([id ? connectwareLicenseKey : (licenseFile as string)], id ? 'connectware-license.key' : 'connectware-license-file.lic')
        download(file)
    }

    const copyLicenseKey = () => {
        if (!textArea.current) return
        textArea.current.select()
        document.execCommand('copy')
    }

    const handleGenerateClick = async () => {
        try {
            let key: string
            if (id) {
                if (!licenseDescription) {
                    throw new PortalError(PortalErrorType.STATE, Translation.ERROR_DESCRIPTION_NOT_SET)
                }
                await manageConnectwareLicensesUsecase.updateUserLicense(licenseDescription, id)
                key = await manageConnectwareLicensesUsecase.generateLicenseKey(id)
            } else {
                key = await manageConnectwareLicensesUsecase.generateLicenseFile(connectwareLicenseKey)
                downloadFile(key)
            }
            setConnectwareLicenseKey(key)
            setError(null)
        } catch (err) {
            setError(err)
        }
    }

    const handleOnClose = () => {
        setConnectwareLicenseKey('')
        if (id) {
            void manageConnectwareLicensesUsecase.toggleDownloadLicenseKey()
        } else {
            void manageConnectwareLicensesUsecase.toggleDownloadLicenseFile()
        }
    }

    if (!open) return null

    if (id) {
        return (
            <Dialog open fullWidth maxWidth="md" onClose={handleOnClose}>
                <div className={classes.root}>
                    <DialogTitle className={classes.title}>
                        <FormattedTranslation id={Translation.DOWNLOAD_LICENSE_KEY_DIALOG_TITLE} />
                    </DialogTitle>
                    <LinearProgress variant="determinate" value={0} />
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.container}>
                            <div className={`${classes.column} ${classes.columnLeft}`}>
                                <form className={classes.form}>
                                    <Typography variant="subtitle1">
                                        <FormattedTranslation id={Translation.ASSIGN_LICENSE_TO} />
                                    </Typography>
                                    <TextField
                                        name="name"
                                        id="name"
                                        label={<FormattedTranslation id={Translation.DESCRIPTION} />}
                                        className={classes.dialogText}
                                        InputProps={{ placeholder: '' }}
                                        value={licenseDescription}
                                        autoComplete="off"
                                        disabled={!!connectwareLicenseKey}
                                        onChange={({ target: { value } }) => setLicenseDescription(value)}
                                    />
                                </form>
                                <Typography variant="subtitle1" gutterBottom>
                                    <FormattedTranslation id={Translation.DOWNLOAD_LICENSE_KEY_DESCRIPTION} />
                                </Typography>
                                <Button onClick={handleGenerateClick} variant="contained" color="primary" size="small" disabled={!licenseDescription}>
                                    <FormattedTranslation id={Translation.DOWNLOAD_LICENSE_KEY_GENERATE_BUTTON_TEXT} />
                                </Button>
                                {Boolean(connectwareLicenseKey) && (
                                    <div className={classes.innerContainer}>
                                        <textarea className={classes.hidden} ref={textArea} value={connectwareLicenseKey} />
                                        <Typography variant="subtitle2" gutterBottom>
                                            <FormattedTranslation
                                                id={Translation.DOWNLOAD_LICENSE_KEY_GENERATED_TEXT}
                                                values={{ strong: customStrongFormatter(), licenseDescription }}
                                            />
                                        </Typography>
                                        <TextField
                                            disabled
                                            name="ip"
                                            id="ip"
                                            className={classes.textField}
                                            InputProps={{ placeholder: '' }}
                                            value={connectwareLicenseKey}
                                            autoComplete="off"
                                        />
                                        <div className={classes.buttonArea}>
                                            <Button onClick={copyLicenseKey} variant="contained" size="small">
                                                <FormattedTranslation id={Translation.DOWNLOAD_LICENSE_KEY_COPY_BUTTON_TEXT} />
                                            </Button>
                                            <Button onClick={() => downloadFile()} variant="contained" size="small">
                                                <FormattedTranslation id={Translation.DOWNLOAD_LICENSE_KEY_DOWNLOAD_BUTTON_TEXT} />
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={classes.column}>
                                <Typography variant="subtitle1" gutterBottom>
                                    <FormattedTranslation id={Translation.DOWNLOAD_LICENSE_KEY_USER_DESCRIPTION_1} />
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <FormattedTranslation id={Translation.DOWNLOAD_LICENSE_KEY_USER_DESCRIPTION_2} />
                                </Typography>
                            </div>
                        </div>
                        {error && <ErrorMessage error={error} />}
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={handleOnClose}>
                            <FormattedTranslation id={Translation.CLOSE_ACTION} />
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        )
    } else {
        return (
            <Dialog open fullWidth maxWidth="md" onClose={handleOnClose}>
                <div className={classes.root}>
                    <DialogTitle className={classes.title}>
                        <FormattedTranslation id={Translation.DOWNLOAD_LICENSE_FILE_DIALOG_TITLE} />
                    </DialogTitle>
                    <LinearProgress variant="determinate" value={0} />
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.container}>
                            <div className={`${classes.column} ${classes.columnLeft}`}>
                                <Typography variant="subtitle1" gutterBottom>
                                    <FormattedTranslation id={Translation.DOWNLOAD_LICENSE_FILE_DESCRIPTION} />
                                </Typography>
                                <div className={classes.licenseFileDownload}>
                                    <TextField
                                        name="connectwareLicenseKey"
                                        id="connectwareLicenseKey"
                                        label={translator.formatTranslation(Translation.DOWNLOAD_LICENSE_FILE_KEY_INPUT_LABEL)}
                                        InputProps={{ placeholder: '' }}
                                        value={connectwareLicenseKey}
                                        autoComplete="off"
                                        onChange={({ target: { value } }) => setConnectwareLicenseKey(value)}
                                    />
                                    <Button onClick={handleGenerateClick} variant="contained" size="small" disabled={!connectwareLicenseKey}>
                                        <FormattedTranslation id={Translation.DOWNLOAD_LICENSE_FILE_BUTTON_TEXT} />
                                    </Button>
                                </div>
                            </div>
                            <div className={classes.column}>
                                <Typography variant="subtitle1" gutterBottom>
                                    <FormattedTranslation id={Translation.DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_1} />
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <FormattedTranslation id={Translation.DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_2} />
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>
                                    <FormattedTranslation id={Translation.DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_3} />
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>
                                    <FormattedTranslation id={Translation.DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_4} />
                                </Typography>
                            </div>
                        </div>
                        {error && <ErrorMessage error={error} />}
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={handleOnClose}>
                            <FormattedTranslation id={Translation.CLOSE_ACTION} />
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        )
    }
}

export default Download
