import React, { FC } from 'react'
import { useIsAdmin } from '../../hooks'
import { Page } from '../common'
import ManageUserGroupTable from './ManageUserGroup'
import RolesTable from './RolesTable'

const Wrapper: FC = () => {
    const isAdmin = useIsAdmin()
    return (
        <Page>
            {isAdmin && (
                <>
                    <ManageUserGroupTable />
                    <br></br>
                </>
            )}
            <RolesTable />
        </Page>
    )
}

export default Wrapper
