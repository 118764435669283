import React, { Context, createContext, FC, useContext, useState } from 'react'

import { PortalError, PortalErrorType } from '../../../../../domain'

const SelectedRowsStateContext = createContext<unknown[] | null>(null)
const SelectedRowsDispatchContext = createContext<((rows: unknown[]) => void) | null>(null)

/**
 * This provider is here to allow the use of table information as hooks
 *
 * It has a slight performance issue due to how slow mui-datatables
 * if this is to be revised, better value handling (E.G. Redux) should be considered
 */
export const TableProvider: FC = ({ children }) => {
    const [selection, setSelection] = useState<unknown[]>([])

    return (
        <SelectedRowsDispatchContext.Provider value={setSelection}>
            <SelectedRowsStateContext.Provider value={selection}>{children}</SelectedRowsStateContext.Provider>
        </SelectedRowsDispatchContext.Provider>
    )
}

const getContext = <T extends unknown>(context: Context<T | null>, caller: typeof Function): T => {
    const foundContext = useContext(context)

    if (foundContext) {
        return foundContext
    }

    try {
        throw new PortalError(PortalErrorType.STATE, 'Must be used with-in a TableProvider')
    } catch (e) {
        Error.captureStackTrace(e, caller)
        throw e
    }
}

/**
 * To use this, on a particular table, wrap it with the provider above
 * Then, on the table options.onRowSelectionChange call the setter, so the getter with-in the provider is properly set
 */
export const useSelectedRows = <R extends unknown>(): [R[], (rows: R[]) => void] => {
    const selectedRows = getContext(SelectedRowsStateContext, (useSelectedRows as unknown) as typeof Function) as R[]
    const selectRows = getContext(SelectedRowsDispatchContext, (useSelectedRows as unknown) as typeof Function) as (rows: R[]) => void
    return [selectedRows, selectRows]
}
