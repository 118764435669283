export enum RoutePath {
    ADMIN_LICENSE = 'ADMIN_LICENSE',
    ADMIN_LICENSES = 'ADMIN_LICENSES',
    ADMIN_ROLE = 'ADMIN_ROLE',
    ADMIN_ROLES = 'ADMIN_ROLES',
    ADMIN_USER_GROUP = 'ADMIN_USER_GROUP',
    ADMIN_USER_GROUPS = 'ADMIN_USER_GROUPS',
    ADMIN_USERS = 'ADMIN_USERS',
    LICENSE = 'LICENSE',
    LICENSES = 'LICENSES',
    APPLIANCE = 'APPLIANCE',
    APPLIANCES = 'APPLIANCES',
    SERVICES_CATALOG = 'SERVICES_CATALOG',
    HOME = 'HOME',
    METRICS = 'METRICS'
}
