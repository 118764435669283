import React, { createContext, FC, useContext } from 'react'

import { AppState, PortalError, PortalErrorType } from '../../../domain'

import { Usecases } from '../../../application/usecases'

import { Comparator, Selector, useStoreState as useBaseState, StoreProvider as BaseStateProvider, Store } from './Store'

const UsecasesContext = createContext<Usecases | undefined>(undefined)

export const UsecasesProvider: FC<Usecases> = ({ children, ...usecases }) => <UsecasesContext.Provider value={usecases}>{children}</UsecasesContext.Provider>

export const useUsecases = (): Usecases => {
    const context = useContext(UsecasesContext)
    if (context === undefined) {
        throw new PortalError(PortalErrorType.STATE, 'useUsecases must be used with-in a UsecasesProvider')
    }
    return context
}

export const useState = <R extends unknown = unknown>(selector: Selector<AppState, R>, comparator?: Comparator<R>): R => useBaseState(selector, comparator)

export const StateProvider: FC<{ readonly store: Store<AppState> }> = (props) => <BaseStateProvider {...props} />

export { Store }
