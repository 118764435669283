import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, makeStyles, TextField } from '@material-ui/core'
import React, { FC, useEffect, useState as useReactState } from 'react'
import { isPortalError, PortalError, PortalErrorType, Translation } from '../../../../../domain'
import { FormattedTranslation, useParameters, useState, useTranslator, useUsecases } from '../../../hooks'
import { ErrorMessage } from '../../common'
import { useLicense } from '../hooks'

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    dialogEntry: {
        marginTop: `${theme.spacing(3)}px`,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        wordWrap: 'break-word'
    },
    dialogText: {
        width: '100%',
        textAlign: 'left'
    },
    errorText: {
        marginLeft: `${theme.spacing(3)}px`
    },
    form: {
        width: '100%',
        textAlign: 'center'
    }
}))

const Edit: FC = () => {
    const classes = useStyles()
    const translator = useTranslator()
    const { manageConnectwareLicensesUsecase } = useUsecases()
    const { editLicenseAsUser } = useState((s) => s.manageLicenses)
    const [licenseDescription, setlicenseDescription] = useReactState<string>('')
    const [error, setError] = useReactState<PortalError | null>(null)
    const [loading, setLoading] = useReactState(false)
    const { id } = useParameters('id')
    const license = useLicense(id)
    useEffect(() => {
        if (!isPortalError(license) && license !== null) {
            setlicenseDescription(license.description || '')
        }
    }, [license])

    if (!license) {
        return <CircularProgress />
    }

    if (isPortalError(license)) {
        return <ErrorMessage error={license} />
    }

    const handleClose = () => {
        void manageConnectwareLicensesUsecase.toggleUserLicenseEdit()
    }
    const handleUpdate = async (): Promise<void> => {
        if (!licenseDescription) {
            setError(new PortalError(PortalErrorType.STATE, translator.formatTranslation(Translation.ERROR_DESCRIPTION_NOT_SET)))
            return
        }
        try {
            setLoading(true)
            setError(null)
            await manageConnectwareLicensesUsecase.updateUserLicense(licenseDescription, id)
        } catch (e) {
            setError(e)
            return
        } finally {
            setLoading(false)
            handleClose()
        }
    }
    return (
        <Dialog fullWidth disableEscapeKeyDown open={editLicenseAsUser} onClose={handleClose} maxWidth="xs">
            <form
                className={classes.form}
                onSubmit={(e) => {
                    e.preventDefault()
                    void handleUpdate()
                }}
            >
                <DialogTitle>
                    <FormattedTranslation id={Translation.UPDATE_USER_LICENSE_DIALOG_TITLE} values={{ name: license.name }} />
                </DialogTitle>
                <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.dialogEntry}>
                        <TextField
                            name="description"
                            id="description"
                            label={<FormattedTranslation id={Translation.DESCRIPTION} />}
                            className={classes.dialogText}
                            InputProps={{ placeholder: '' }}
                            value={licenseDescription}
                            autoComplete="off"
                            onChange={({ target: { value } }) => setlicenseDescription(value)}
                        />
                    </div>
                    {error && <ErrorMessage error={error} />}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        <FormattedTranslation id={Translation.CLOSE_ACTION} />
                    </Button>
                    <Button color="primary" type="submit">
                        <FormattedTranslation id={Translation.UPDATE} />
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default Edit
