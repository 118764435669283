import { CircularProgress } from '@material-ui/core'
import React, { FC } from 'react'

import { isPortalError, Translation } from '../../../../../domain'

import { useTranslator } from '../../../hooks'
import { ErrorMessage, Table } from '../../common'
import { useLicense } from '../hooks'

export const Protocols: FC<{ id: string }> = ({ id }) => {
    const translator = useTranslator()
    const license = useLicense(id)

    if (!license) {
        return <CircularProgress />
    }

    if (isPortalError(license)) {
        return <ErrorMessage error={license} />
    }
    const {
        capabilities: { protocols }
    } = license
    return (
        <div>
            <Table
                title={translator.formatTranslation(Translation.PROTOCOLS_TITLE)}
                data={protocols.map((name) => ({ name }))}
                columns={[
                    { name: 'name', label: translator.formatTranslation(Translation.NAME), options: { filter: false, sort: true, sortDescFirst: false } }
                ]}
                options={{ selectableRows: 'none', filter: false, viewColumns: false, elevation: 0, textLabels: { body: { noMatch: '' } } }}
                smallHeader
            />
        </div>
    )
}
