import React, { useState, FC } from 'react'
import { IconButton, Tooltip, makeStyles } from '@material-ui/core'
import { AddShoppingCart } from '@material-ui/icons'

import { Translation } from '../../../../../domain'

import { FormattedTranslation } from '../../../hooks'

import Modal from './Modal'

const useStyles = makeStyles({ container: { marginLeft: '15px' } })

const Create: FC = () => {
    const [open, setOpen] = useState(false)
    const classes = useStyles()

    const toggleOpen = () => setOpen((open) => !open)

    return (
        <span className={classes.container}>
            <Tooltip title={<FormattedTranslation id={Translation.REQUEST_LICENSE} />}>
                <IconButton color="primary" onClick={toggleOpen}>
                    <AddShoppingCart />
                </IconButton>
            </Tooltip>
            <Modal open={open} toggleOpen={toggleOpen} />
        </span>
    )
}

export default Create
