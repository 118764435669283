import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, makeStyles, Typography } from '@material-ui/core'
import React, { FC, useState as useReactState } from 'react'
import { PortalError } from '../../../../../domain'
import { cybusAmber } from '../../../colors'
import { useState, useUsecases } from '../../../hooks'
import { ErrorMessage } from '../../common'

const useStyles = makeStyles((theme) => ({
    dialogContent: { display: 'flex', flexDirection: 'column', width: '100%' },
    dialogEntry: {
        marginTop: `${theme.spacing(3)}px`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        wordWrap: 'break-word'
    },
    dialogHeader: { color: 'rgba(0, 0, 0, 0.54)', fontSize: '.9rem' },
    dialogText: { width: '100%', textAlign: 'left' },
    errorText: { marginLeft: `${theme.spacing(3)}px` },
    icon: { marginRight: `${theme.spacing(2)}px`, color: ((theme.palette as unknown) as Partial<Record<string, typeof cybusAmber>>)?.accent?.[500] },
    form: { width: '100%', textAlign: 'center' }
}))

export const Delete: FC = () => {
    const classes = useStyles()
    const { manageServicesCatalogUsecase } = useUsecases()
    const app = useState((s) => s.manageServices.details)
    const toBeDeleted = useState((s) => s.manageServices.toBeDeleted)
    const [loading, setLoading] = useReactState(false)
    const [error, setError] = useReactState<PortalError | null>(null)
    if (!app || !toBeDeleted) return null
    const { filename, directory, name } = app

    const handleSubmit = async (): Promise<void> => {
        setLoading(true)
        let error: PortalError | null = null
        try {
            await manageServicesCatalogUsecase.deleteService()
        } catch (e) {
            error = e as PortalError
        } finally {
            setLoading(false)
            setError(error)
            if (!error) {
                manageServicesCatalogUsecase.toggleDeletion()
            }
        }
    }

    return (
        <Dialog open fullWidth disableEscapeKeyDown maxWidth="xs">
            <form className={classes.form} onSubmit={handleSubmit}>
                <DialogTitle>Delete Service</DialogTitle>
                <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.dialogEntry}>
                        <Typography className={`${classes.dialogText} ${classes.dialogHeader}`} variant="subtitle1" gutterBottom>
                            Do you really want to delete the following Service from the Services Catalog?
                        </Typography>
                        <Typography className={classes.dialogText} variant="subtitle1" gutterBottom>
                            {name} ({directory + filename})
                        </Typography>
                    </div>
                    {error && <ErrorMessage error={error} />}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => manageServicesCatalogUsecase.toggleDeletion()}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit" disabled={loading}>
                        Confirm
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
