import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import { Mutation } from '@apollo/client/react/components'
import { ALL_PERMISSION_GROUPS, CREATE_PERMISSION_GROUP } from '../../../graphql'
import { ErrorMessage } from '../../../infrastructure/react/components/common'

const styles = (theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  dialogEntry: {
    marginTop: `${theme.spacing(3)}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    wordWrap: 'break-word'
  },
  dialogText: {
    width: '100%',
    textAlign: 'left'
  },
  errorText: {
    marginLeft: `${theme.spacing(3)}px`
  },
  icon: {
    marginRight: `${theme.spacing(2)}px`,
    color: theme.palette.accent[500]
  },
  form: {
    width: '100%',
    textAlign: 'center'
  },
  select: {
    textAlign: 'left',
    width: '100%'
  }
})

class Modal extends Component {
  state = { name: '' }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleClose = () => {
    const { toggleOpen } = this.props
    this.setState({ name: '' }, toggleOpen)
  }

  render() {
    const { classes, open } = this.props
    const { name } = this.state
    return (
      <Mutation
        mutation={CREATE_PERMISSION_GROUP}
        variables={{
          name,
          registryPermissions: []
        }}
        refetchQueries={[{ query: ALL_PERMISSION_GROUPS }]}
        onCompleted={this.handleClose}
      >
        {(createAppliance, { loading, data, error }) => (
          <Dialog fullWidth disableEscapeKeyDown open={open} onClose={this.handleClose} maxWidth='xs'>
            <form
              className={classes.form}
              onSubmit={(e) => {
                e.preventDefault()
                createAppliance()
              }}
            >
              <DialogTitle>Create Role</DialogTitle>
              <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
              <DialogContent className={classes.dialogContent}>
                <div className={classes.dialogEntry}>
                  <TextField
                    name='name'
                    id='name'
                    label='Name'
                    className={classes.dialogText}
                    InputProps={{ placeholder: '' }}
                    value={name}
                    autoComplete='off'
                    onChange={this.handleChange}
                  />
                </div>
                <FormError data={data} />
                <ErrorMessage error={error} />
              </DialogContent>
              <DialogActions>
                <Button color='primary' onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button color='primary' type='submit' disabled={!name || loading}>
                  Create
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )}
      </Mutation>
    )
  }
}

function FormError({ data = {} }) {
  const { createPermissionGroup = {} } = data
  const { success, message } = createPermissionGroup
  if (success) return false
  return <p>{message}</p>
}

export default withStyles(styles)(Modal)
