import { ApolloQueryResult, FetchResult, ErrorPolicy } from '@apollo/client'
import { DocumentNode } from 'graphql'

/**
 * The types below are used in the Apollo client
 *
 * The client interfaces with them in order to make GraphQL queries easier
 */

type Handler<R, O> = {
    /**
     * The GraphQL document that will be sent to the backend
     */
    readonly document: DocumentNode

    /**
     * Any custom error policy
     */
    readonly errorPolicy?: ErrorPolicy

    /**
     * The mapper of the response
     */
    readonly mapper: (response: R) => O
}

type WithVariables<V> = {
    /**
     * If there are any expected custom variables
     *
     * It is added separatedly as variables need to be past dynamically
     */
    readonly variables: V
}

type BaseQueryHandler<R, O> = Handler<ApolloQueryResult<R>, O>
export type QueryHandler<V, R, O> = BaseQueryHandler<R, O> & WithVariables<V>
/**
 * Outputs a GraphQL.query handler, that later can be used with-in the Apollo Client to execute queries
 */
export const buildQueryHandler = <V, R, O>(args: BaseQueryHandler<R, O>, variables: V): QueryHandler<V, R, O> => ({ ...args, variables })

type BaseMutationHandler<R, O> = Handler<FetchResult<R>, O>
export type MutationHandler<V, R, O> = BaseMutationHandler<R, O> & WithVariables<V>

/**
 * Outputs a GraphQL.mutation handler, that later can be used with-in the Apollo Client to apply mutations
 */
export const buildMutationHandler = <V, R, O>(args: BaseMutationHandler<R, O>, variables: V): MutationHandler<V, R, O> => ({ ...args, variables })

type BaseSubscriptionHandler<R, O> = Handler<R, O>
export type SubcriptionHandler<V, R, O> = BaseSubscriptionHandler<R, O> & WithVariables<V>

/**
 * Outputs a GraphQL.subscription handler, that later can be used with-in the Apollo Client to create subscriptions
 */
export const buildSubscriptionHandler = <V, R, O>(args: BaseSubscriptionHandler<R, O>, variables: V): SubcriptionHandler<V, R, O> => ({ ...args, variables })
