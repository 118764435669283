import { EditMode, RedirectAuthenticationService } from '../../application/services'

export type AWSCognitoConfig = {
    readonly redirectUrl: string

    readonly userPoolBaseUrl: string
}

export class AWSCognitoAuthentication implements RedirectAuthenticationService {
    private redirectUrl: URL

    private userPoolBaseUrl: string

    constructor({ redirectUrl, userPoolBaseUrl }: AWSCognitoConfig) {
        this.redirectUrl = new URL(redirectUrl)
        this.userPoolBaseUrl = userPoolBaseUrl
    }

    getRedirectUrl(): URL {
        return this.redirectUrl
    }

    parseUrlParameters(parameters: URLSearchParams): string | null {
        return parameters.get('access_token')
    }

    buildEditUserURL(args: EditMode): URL {
        const poolURL = new URL(this.userPoolBaseUrl)

        if (args === 'USERS') {
            poolURL.hash += `/users`
        } else if (args === 'GROUPS') {
            poolURL.hash += `/groups`
        } else {
            poolURL.hash += `/groups/${args.group}`
        }

        return poolURL
    }
}
