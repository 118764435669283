import { AppState, PortalError, PortalErrorType } from '../../domain'

import {
    LoggerService,
    NavigationService,
    RedirectAuthenticationService,
    ConnectwareLicenseService,
    ConnectwareApplianceService,
    ConfigurationService,
    ApplicationStateService,
    TranslationService,
    PersistenceService,
    ConnectwareUserService,
    ServicesCatalogService,
    MetricsService
} from '../services'

export type UsecaseServices = {
    readonly logger: LoggerService
    readonly configurationService: ConfigurationService
    readonly navigationService: NavigationService
    readonly redirectAuthenticationService: RedirectAuthenticationService
    readonly connectwareLicenseService: ConnectwareLicenseService
    readonly connectwareApplianceService: ConnectwareApplianceService
    readonly applicationState: ApplicationStateService
    readonly translationService: TranslationService
    readonly persistenceService: PersistenceService<string, string>
    readonly connectwareUserService: ConnectwareUserService
    readonly servicesCatalogService: ServicesCatalogService
    readonly metricsService: MetricsService
}

/**
 * The registry of all created usecases, so they can call each other internally if needed
 */
export type UsecasesRegistry = Map<typeof Usecase, Usecase>

/**
 * This is a base usecase
 *
 * Business related code should be run only in classes that extend this one
 */
export class Usecase {
    private readonly registry: UsecasesRegistry

    private readonly applicationState: UsecaseServices['applicationState']
    protected readonly logger: UsecaseServices['logger']
    protected readonly redirectAuthenticationService: UsecaseServices['redirectAuthenticationService']
    protected readonly navigationService: UsecaseServices['navigationService']
    protected readonly connectwareLicenseService: UsecaseServices['connectwareLicenseService']
    protected readonly connectwareApplianceService: UsecaseServices['connectwareApplianceService']
    protected readonly configurationService: UsecaseServices['configurationService']
    protected readonly translationService: UsecaseServices['translationService']
    protected readonly persistenceService: UsecaseServices['persistenceService']
    protected readonly connectwareUserService: UsecaseServices['connectwareUserService']
    protected readonly servicesCatalogService: UsecaseServices['servicesCatalogService']
    protected readonly metricsService: UsecaseServices['metricsService']

    constructor(registry: UsecasesRegistry, services: UsecaseServices) {
        this.registry = registry

        this.logger = services.logger
        this.redirectAuthenticationService = services.redirectAuthenticationService
        this.navigationService = services.navigationService
        this.connectwareLicenseService = services.connectwareLicenseService
        this.connectwareApplianceService = services.connectwareApplianceService
        this.configurationService = services.configurationService
        this.applicationState = services.applicationState
        this.translationService = services.translationService
        this.persistenceService = services.persistenceService
        this.connectwareUserService = services.connectwareUserService
        this.servicesCatalogService = services.servicesCatalogService
        this.metricsService = services.metricsService

        this.setUsecase(this.constructor as typeof Usecase)
    }

    private setUsecase<T extends typeof Usecase>(usecaseConstructor: T): void {
        this.registry.set(usecaseConstructor, this)
    }

    /**
     * To get any other registered usecases
     */
    protected getUsecase<T extends typeof Usecase>(usecaseConstructor: T): InstanceType<T> {
        const usecase = this.registry.get(usecaseConstructor)

        if (!usecase) {
            throw new PortalError(PortalErrorType.STATE, 'Could not find usecase on registry')
        }

        return usecase as InstanceType<T>
    }

    protected setState(state: Partial<AppState>): void {
        this.applicationState.setState(state)
    }

    protected getState(): AppState {
        return this.applicationState.getState()
    }
}
