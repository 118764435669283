import React, { Component } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { ALL_PERMISSION_GROUPS, REVOKE_ACCESS_TO_PERMISSION_GROUP } from '../../../graphql'
import { ErrorMessage } from '../../../infrastructure/react/components/common'
import Table from '../../common/Table'
import Create from './Create'

class ConnectwareLicenses extends Component {
  getColumns = () => {
    return [
      {
        name: 'name',
        label: 'Name',
        options: {
          filter: false,
          sort: true,
          sortDescFirst: false
        }
      },
      {
        name: 'associatedAccount',
        label: 'Associated Account',
        options: {
          filter: true,
          sort: true,
          sortDescFirst: false
        }
      }
    ]
  }

  getOptions = ({ permissionGroupId, connectwareLicenses, revokeAccessToPermissionGroup }) => {
    return {
      filter: false,
      viewColumns: false,
      elevation: 0,
      textLabels: {
        body: {
          noMatch: ''
        }
      },
      customToolbar: () => <Create permissionGroupId={permissionGroupId} currentConnectwareLicenseIds={connectwareLicenses.map(({ id }) => id)} />,
      onRowsDelete: (options) => {
        revokeAccessToPermissionGroup({
          variables: {
            permissionGroupId,
            connectwareLicenseIds: connectwareLicenses.filter((value, index) => options.data.find(({ dataIndex }) => dataIndex === index)).map(({ id }) => id)
          }
        })
      }
    }
  }

  render() {
    const { permissionGroupId, connectwareLicenses } = this.props
    return (
      <Mutation mutation={REVOKE_ACCESS_TO_PERMISSION_GROUP} refetchQueries={[{ query: ALL_PERMISSION_GROUPS }]}>
        {(revokeAccessToPermissionGroup, { data = {}, error }) => {
          const columns = this.getColumns()
          const options = this.getOptions({
            permissionGroupId,
            connectwareLicenses,
            revokeAccessToPermissionGroup
          })
          const { updatePermissionGroup: { success, message } = {} } = data
          return (
            <div>
              <Table title='Connectware Licenses' data={connectwareLicenses} columns={columns} options={options} smallHeader />
              <ErrorMessage error={error} />
            </div>
          )
        }}
      </Mutation>
    )
  }
}

export default ConnectwareLicenses
