import gql from 'graphql-tag'

import { PortalError, PortalErrorType, ConnectwareAppliance } from '../../../../domain'

import { TypedResponse } from './Response'
import { buildMutationHandler, MutationHandler } from './Builders'

const BULK_DELETE_CONNECTWARE_INSTANCE = gql`
    mutation BulkDeleteSelfHostedConnectwareAppliance($ids: [ID]!) {
        bulkDeleteSelfHostedConnectwareAppliance(ids: $ids) {
            success
            message
        }
    }
`

interface ConnectwareApplianceActionResponse extends TypedResponse<'ConnectwareApplianceActionResponse'> {
    readonly success: boolean
    readonly message: string
}

export default (
    ids: ConnectwareAppliance['id'][]
): MutationHandler<
    { readonly ids: ConnectwareAppliance['id'][] },
    { readonly bulkDeleteSelfHostedConnectwareAppliance: ConnectwareApplianceActionResponse },
    void
> =>
    buildMutationHandler(
        {
            document: BULK_DELETE_CONNECTWARE_INSTANCE,
            mapper: ({ data }) => {
                if (!data?.bulkDeleteSelfHostedConnectwareAppliance.success) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, 'Unexpected deletion response', { ...data?.bulkDeleteSelfHostedConnectwareAppliance })
                }
            }
        },
        { ids }
    )
