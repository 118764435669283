export enum Translation {
    ACCOUNT = 'ACCOUNT',
    ACTIVE = 'ACTIVE',
    ADD_USER_GROUP_LABEL = 'ADD_USER_GROUP_LABEL',
    ADD_USER_GROUP_TITLE = 'ADD_USER_GROUP_TITLE',
    ADMIN_LICENSES_HEADER_TITLE = 'ADMIN_LICENSES_HEADER_TITLE',
    ADMIN_LICENSE_HEADER_TITLE = 'ADMIN_LICENSE_HEADER_TITLE',
    ADMIN_ROLES_HEADER_TITLE = 'ADMIN_ROLES_HEADER_TITLE',
    ADMIN_ROLE_HEADER_TITLE = 'ADMIN_ROLE_HEADER_TITLE',
    ADMIN_USERS_TITLE = 'ADMIN_USERS_TITLE',
    ADMIN_USER_GROUPS_HEADER_TITLE = 'ADMIN_USER_GROUPS_HEADER_TITLE',
    ADMIN_USER_GROUP_HEADER_TITLE = 'ADMIN_USER_GROUP_HEADER_TITLE',
    APPLIANCES_HEADER_TITLE = 'APPLIANCES_HEADER_TITLE',
    APPLIANCES_IN_ACCOUNT = 'APPLIANCES_IN_ACCOUNT',
    APPLIANCES_NO_LONGER_SUPPORTED_1 = 'APPLIANCES_NO_LONGER_SUPPORTED_1',
    APPLIANCES_NO_LONGER_SUPPORTED_2 = 'APPLIANCES_NO_LONGER_SUPPORTED_2',
    APPLIANCES_REDIRECT_TEXT = 'APPLIANCES_REDIRECT_TEXT',
    APPLIANCES_TITLE = 'APPLIANCES_TITLE',
    APPLIANCE_HEADER_TITLE = 'APPLIANCE_HEADER_TITLE',
    APPLIANCE_HEALTH_LABEL = 'APPLIANCE_HEALTH_LABEL',
    APPLIANCE_IS_ONLINE_LABEL = 'APPLIANCE_IS_ONLINE_LABEL',
    APPLICATION_TITLE = 'APPLICATION_TITLE',
    ARCHIVED = 'ARCHIVED',
    ASSIGN_LICENSE_TO = 'ASSIGN_LICENSE_TO',
    ASSOCIATED_ACCOUNT = 'ASSOCIATED_ACCOUNT',
    ASSOCIATED_LICENSE = 'ASSOCIATED_LICENSE',
    AUTOMATIC_LICENSE_RENEWAL = 'AUTOMATIC_LICENSE_RENEWAL',
    AUTO_RENEWAL = 'AUTO_RENEWAL',
    BETA = 'BETA',
    BOOLEAN = 'BOOLEAN',
    CANCEL = 'CANCEL',
    CLOSE_ACTION = 'CLOSE_ACTION',
    CONFIRM = 'CONFIRM',
    CONTACT_SUMMARY = 'CONTACT_SUMMARY',
    COPY_OF = 'COPY_OF',
    CREATE = 'CREATE',
    CREATED_AT = 'CREATED_AT',
    CREATE_LICENSE = 'CREATE_LICENSE',
    CREATE_SELFHOSTED_APPLIANCE = 'CREATE_SELFHOSTED_APPLIANCE',
    CURRENTLY_USED = 'CURRENTLY_USED',
    CUSTOMER = 'CUSTOMER',
    DATE = 'DATE',
    DELETE = 'DELETE',
    DELETE_APPLIANCE = 'DELETE_APPLIANCE',
    DELETE_LICENSE = 'DELETE_LICENSE',
    DELETE_LICENSES = 'DELETE_LICENSES',
    DELETE_USER_GROUP_LABEL = 'DELETE_USER_GROUP_LABEL',
    DELETE_USER_GROUP_TITLE = 'DELETE_USER_GROUP_TITLE',
    DEPRECATED = 'DEPRECATED',
    DESCRIPTION = 'DESCRIPTION',
    DIRECTORY_FIELD_CONTROL_LABEL = 'DIRECTORY_FIELD_CONTROL_LABEL',
    DIRECTORY_LABEL = 'DIRECTORY_LABEL',
    DOWNLOAD_ACTION = 'DOWNLOAD_ACTION',
    DOWNLOAD_LICENSE_FILE_BUTTON_TEXT = 'DOWNLOAD_LICENSE_FILE_BUTTON_TEXT',
    DOWNLOAD_LICENSE_FILE_DESCRIPTION = 'DOWNLOAD_LICENSE_FILE_DESCRIPTION',
    DOWNLOAD_LICENSE_FILE_DIALOG_TITLE = 'DOWNLOAD_LICENSE_FILE_DIALOG_TITLE',
    DOWNLOAD_LICENSE_FILE_KEY_INPUT_LABEL = 'DOWNLOAD_LICENSE_FILE_KEY_INPUT_LABEL',
    DOWNLOAD_LICENSE_FILE_TOOLTIP_TITLE = 'DOWNLOAD_LICENSE_FILE_TOOLTIP_TITLE',
    DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_1 = 'DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_1',
    DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_2 = 'DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_2',
    DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_3 = 'DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_3',
    DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_4 = 'DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_4',
    DOWNLOAD_LICENSE_KEY_COPY_BUTTON_TEXT = 'DOWNLOAD_LICENSE_KEY_COPY_BUTTON_TEXT',
    DOWNLOAD_LICENSE_KEY_DESCRIPTION = 'DOWNLOAD_LICENSE_KEY_DESCRIPTION',
    DOWNLOAD_LICENSE_KEY_DIALOG_TITLE = 'DOWNLOAD_LICENSE_KEY_DIALOG_TITLE',
    DOWNLOAD_LICENSE_KEY_DOWNLOAD_BUTTON_TEXT = 'DOWNLOAD_LICENSE_KEY_DOWNLOAD_BUTTON_TEXT',
    DOWNLOAD_LICENSE_KEY_GENERATED_TEXT = 'DOWNLOAD_LICENSE_KEY_GENERATED_TEXT',
    DOWNLOAD_LICENSE_KEY_GENERATE_BUTTON_TEXT = 'DOWNLOAD_LICENSE_KEY_GENERATE_BUTTON_TEXT',
    DOWNLOAD_LICENSE_KEY_TOOLTIP_TITLE = 'DOWNLOAD_LICENSE_KEY_TOOLTIP_TITLE',
    DOWNLOAD_LICENSE_KEY_USER_DESCRIPTION_1 = 'DOWNLOAD_LICENSE_KEY_USER_DESCRIPTION_1',
    DOWNLOAD_LICENSE_KEY_USER_DESCRIPTION_2 = 'DOWNLOAD_LICENSE_KEY_USER_DESCRIPTION_2',
    DUPLICATE = 'DUPLICATE',
    DUPLICATE_LICENSE = 'DUPLICATE_LICENSE',
    EDIT_AWS_ACTION = 'EDIT_AWS_ACTION',
    EDIT_LICENSES = 'EDIT_LICENSES',
    EDIT_SELECTED_LICENSE = 'EDIT_SELECTED_LICENSE',
    EMAIL = 'EMAIL',
    ENABLED = 'ENABLED',
    ERROR_DESCRIPTION = 'ERROR_DESCRIPTION',
    ERROR_DESCRIPTION_NOT_SET = 'ERROR_DESCRIPTION_NOT_SET',
    EXPIRATION = 'EXPIRATION',
    EXPIRATION_DATE = 'EXPIRATION_DATE',
    FEATURES_TITLE = 'FEATURES_TITLE',
    GRANT = 'GRANT',
    GROUP_LABEL = 'GROUP_LABEL',
    HOME_TITLE = 'HOME_TITLE',
    LDAP = 'LDAP',
    LICENSES = 'LICENSES',
    LICENSES_DELETING_DESCRIPTION = 'LICENSES_DELETING_DESCRIPTION',
    LICENSES_EDITING_DESCRIPTION = 'LICENSES_EDITING_DESCRIPTION',
    LICENSES_HEADER_TITLE = 'LICENSES_HEADER_TITLE',
    LICENSES_IN_ACCOUNT = 'LICENSES_IN_ACCOUNT',
    LICENSES_TITLE = 'LICENSES_TITLE',
    LICENSE_CLASS = 'LICENSE_CLASS',
    LICENSE_DESCRIPTION_CUSTOM_FILTER_OPTIONS_RENDER = 'LICENSE_DESCRIPTION_CUSTOM_FILTER_OPTIONS_RENDER',
    LICENSE_DESCRIPTION_NOT_SET = 'LICENSE_DESCRIPTION_NOT_SET',
    LICENSE_DESCRIPTION_VALUE = 'LICENSE_DESCRIPTION_VALUE',
    LICENSE_EXPIRATION_LABEL = 'LICENSE_EXPIRATION_LABEL',
    LICENSE_HEADER_TITLE = 'LICENSE_HEADER_TITLE',
    LICENSE_KEY = 'LICENSE_KEY',
    LICENSE_NAME = 'LICENSE_NAME',
    LICENSE_TITLE = 'LICENSE_TITLE',
    LOADING = 'LOADING',
    LOGOUT = 'LOGOUT',
    LOGO_TEXT_DESCRIPTION = 'LOGO_TEXT_DESCRIPTION',
    MANAGE_LICENSES_TITLE = 'MANAGE_LICENSES_TITLE',
    MANAGE_LICENSE_TITLE = 'MANAGE_LICENSE_TITLE',
    MANAGE_ROLES_SUBTITLE = 'MANAGE_ROLES_SUBTITLE',
    MANAGE_ROLES_TITLE = 'MANAGE_ROLES_TITLE',
    MANAGE_USERS_SUBTITLE = 'MANAGE_USERS_SUBTITLE',
    MANAGE_USERS_TITLE = 'MANAGE_USERS_TITLE',
    MANAGE_USER_GROUPS_NO_MATCH = 'MANAGE_USER_GROUPS_NO_MATCH',
    MANAGE_USER_GROUPS_SUBTITLE = 'MANAGE_USER_GROUPS_SUBTITLE',
    MANAGE_USER_GROUPS_TITLE = 'MANAGE_USER_GROUPS_TITLE',
    MANAGE_USER_GROUP_TITLE = 'MANAGE_USER_GROUP_TITLE',
    MESSAGES_PER_DAY = 'MESSAGES_PER_DAY',
    METRICS_TABLE_TITLE = 'METRICS_TABLE_TITLE',
    METRICS_TITLE = 'METRICS_TITLE',
    METRICS_UPLOAD_DIALOG_BUTTON = 'METRICS_UPLOAD_DIALOG_BUTTON',
    METRICS_UPLOAD_DIALOG_SELECT_FILE = 'METRICS_UPLOAD_DIALOG_SELECT_FILE',
    METRICS_UPLOAD_DIALOG_SELECT_FILE_INFO_TEXT = 'METRICS_UPLOAD_DIALOG_SELECT_FILE_INFO_TEXT',
    METRICS_UPLOAD_DIALOG_CONFIRM_LICENSE = 'METRICS_UPLOAD_DIALOG_CONFIRM_LICENSE',
    METRICS_UPLOAD_TITLE = 'METRICS_UPLOAD_TITLE',
    NAME = 'NAME',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    NO_ALLOWED_DIRECTORIES = 'NO_ALLOWED_DIRECTORIES',
    NO_LICENSES = 'NO_LICENSES',
    NUMBER_OF_CONNECTIONS = 'NUMBER_OF_CONNECTIONS',
    NUMBER_OF_DATAPOINTS = 'NUMBER_OF_DATAPOINTS',
    NUMBER_OF_DUPLICATES = 'NUMBER_OF_DUPLICATES',
    NUMBER_OF_ENDPOINTS = 'NUMBER_OF_ENDPOINTS',
    NUMBER_OF_MAPPINGS = 'NUMBER_OF_MAPPINGS',
    NUMBER_OF_PROTOCOLS = 'NUMBER_OF_PROTOCOLS',
    NUMBER_OF_ROLES = 'NUMBER_OF_ROLES',
    NUMBER_OF_SERVICES = 'NUMBER_OF_SERVICES',
    NUMBER_OF_USERS = 'NUMBER_OF_USERS',
    NUMBER_OF_X_LABEL = 'NUMBER_OF_X_LABEL',
    OPEN_DRAWER = 'OPEN_DRAWER',
    PERMISSIONS = 'PERMISSIONS',
    PORTAL_ERROR_EMTPY_FILE = 'PORTAL_ERROR_EMTPY_FILE',
    PORTAL_ERROR_MISSING_COLUMN = 'PORTAL_ERROR_MISSING_COLUMN',
    PORTAL_ERROR_STATE_NULL = 'PORTAL_ERROR_STATE_NULL',
    PREFERENCES_LANGUAGE = 'PREFERENCES_LANGUAGE',
    PREFERENCES_TITLE = 'PREFERENCES_TITLE',
    PROTOCOLS_TITLE = 'PROTOCOLS_TITLE',
    PURCHASED = 'PURCHASED',
    RECEIVED = 'RECEIVED',
    REQUEST_LICENSE = 'REQUEST_LICENSE',
    REQUEST_LICENSE_TIPS = 'REQUEST_LICENSE_TIPS',
    RESOURCES_TITLE = 'RESOURCES_TITLE',
    REVOKE = 'REVOKE',
    ROLES = 'ROLES',
    ROLES_TABLE_NO_MATCH = 'ROLES_TABLE_NO_MATCH',
    SAVE = 'SAVE',
    SELECTED_APPLIANCES = 'SELECTED_APPLIANCES',
    SERVICES_CATALOG_HEADER_TITLE = 'SERVICES_CATALOG_HEADER_TITLE',
    SERVICES_CATALOG_TITLE = 'SERVICES_CATALOG_TITLE',
    SERVICES_IN_CATALOG = 'SERVICES_IN_CATALOG',
    SERVICE_DETAILS_ADDITIONAL_INFO_DIRECTORY = 'SERVICE_DETAILS_ADDITIONAL_INFO_DIRECTORY',
    SERVICE_DETAILS_ADDITIONAL_INFO_DOWNLOADS = 'SERVICE_DETAILS_ADDITIONAL_INFO_DOWNLOADS',
    SERVICE_DETAILS_ADDITIONAL_INFO_FILENAME = 'SERVICE_DETAILS_ADDITIONAL_INFO_FILENAME',
    SERVICE_DETAILS_ADDITIONAL_INFO_HOMEPAGE = 'SERVICE_DETAILS_ADDITIONAL_INFO_HOMEPAGE',
    SERVICE_DETAILS_ADDITIONAL_INFO_OPTIONAL_TITLE = 'SERVICE_DETAILS_ADDITIONAL_INFO_OPTIONAL_TITLE',
    SERVICE_DETAILS_ADDITIONAL_INFO_PROVIDER = 'SERVICE_DETAILS_ADDITIONAL_INFO_PROVIDER',
    SERVICE_DETAILS_ADDITIONAL_INFO_PUBLICLY_OFFERED = 'SERVICE_DETAILS_ADDITIONAL_INFO_PUBLICLY_OFFERED',
    SERVICE_DETAILS_ADDITIONAL_INFO_PUBLICLY_OFFERED_ARIA_LABEL = 'SERVICE_DETAILS_ADDITIONAL_INFO_PUBLICLY_OFFERED_ARIA_LABEL',
    SERVICE_DETAILS_ADDITIONAL_INFO_RELEASED_AT = 'SERVICE_DETAILS_ADDITIONAL_INFO_RELEASED_AT',
    SERVICE_DETAILS_ADDITIONAL_INFO_TEMPLATE = 'SERVICE_DETAILS_ADDITIONAL_INFO_TEMPLATE',
    SERVICE_DETAILS_ADDITIONAL_INFO_TEMPLATE_ARIA_LABEL = 'SERVICE_DETAILS_ADDITIONAL_INFO_TEMPLATE_ARIA_LABEL',
    SERVICE_DETAILS_ADDITIONAL_INFO_TITLE = 'SERVICE_DETAILS_ADDITIONAL_INFO_TITLE',
    SERVICE_DETAILS_ADDITIONAL_INFO_UPDATED_AT = 'SERVICE_DETAILS_ADDITIONAL_INFO_UPDATED_AT',
    SERVICE_DETAILS_ADDITIONAL_INFO_VERSION = 'SERVICE_DETAILS_ADDITIONAL_INFO_VERSION',
    SERVICE_DETAILS_DESCRIPTION = 'SERVICE_DETAILS_DESCRIPTION',
    SERVICE_DETAILS_EXPANDED = 'SERVICE_DETAILS_EXPANDED',
    SERVICE_DETAILS_TITLE = 'SERVICE_DETAILS_TITLE',
    SHOW_LICENSE = 'SHOW_LICENSE',
    STATUS = 'STATUS',
    SUMMARY_TITLE = 'SUMMARY_TITLE',
    TABLE_LABEL_ID = 'TABLE_LABEL_ID',
    TYPE_OF_LICENSE = 'TYPE_OF_LICENSE',
    UPDATE = 'UPDATE',
    UPDATE_USER_LICENSE_DIALOG_TITLE = 'UPDATE_USER_LICENSE_DIALOG_TITLE',
    UPLOAD_DIALOG_CUSTOM_DIRECTORY_LABEL = 'UPLOAD_DIALOG_CUSTOM_DIRECTORY_LABEL',
    UPLOAD_DIALOG_DESCRIPTION = 'UPLOAD_DIALOG_DESCRIPTION',
    UPLOAD_DIALOG_DESCRIPTION_PLACEHOLDER = 'UPLOAD_DIALOG_DESCRIPTION_PLACEHOLDER',
    UPLOAD_DIALOG_NAME = 'UPLOAD_DIALOG_NAME',
    UPLOAD_DIALOG_NAME_PLACEHOLDER = 'UPLOAD_DIALOG_NAME_PLACEHOLDER',
    UPLOAD_DIALOG_SELECT_CATEGORY = 'UPLOAD_DIALOG_SELECT_CATEGORY',
    UPLOAD_DIALOG_SELECT_CATEGORY_LABEL = 'UPLOAD_DIALOG_SELECT_CATEGORY_LABEL',
    UPLOAD_DIALOG_SELECT_DIRECTORY = 'UPLOAD_DIALOG_SELECT_DIRECTORY',
    UPLOAD_DIALOG_SELECT_DIRECTORY_LABEL = 'UPLOAD_DIALOG_SELECT_DIRECTORY_LABEL',
    UPLOAD_DIALOG_SELECT_FILE = 'UPLOAD_DIALOG_SELECT_FILE',
    UPLOAD_DIALOG_SELECT_PUBLICLY_AVAILABLE = 'UPLOAD_DIALOG_SELECT_PUBLICLY_AVAILABLE',
    UPLOAD_DIALOG_SELECT_TEMPLATE = 'UPLOAD_DIALOG_SELECT_TEMPLATE',
    UPLOAD_METRICS = 'UPLOAD_METRICS',
    USERS = 'USERS',
    USER_GROUPS_FORM_LABEL = 'USER_GROUPS_FORM_LABEL',
    USER_GROUPS_TITLE = 'USER_GROUPS_TITLE',
    VALUE = 'VALUE',
    WELCOME_TITLE = 'WELCOME_TITLE',
    YES_NO = 'YES_NO'
}
