import gql from 'graphql-tag'
import moment from 'moment'

import { PortalError, PortalErrorType } from '../../../../domain'

import { ConnectwareLicenseDuplicationRequest } from '../../../../application/services'

import { buildMutationHandler, MutationHandler } from './Builders'
import { TypedResponse } from './Response'

const DUPLICATE_CONNECTWARE_LICENSE = gql`
    mutation DuplicateConnectwareLicense(
        $id: ID!
        $numberOfRequiredDuplicates: Int!
        $associatedAccount: String!
        $connectwareLicenseClass: String!
        $name: String!
        $expiration: Timestamp!
        $autoRenewal: Boolean!
        $permissionGroups: [String]!
    ) {
        duplicateConnectwareLicense(
            input: {
                id: $id
                numberOfRequiredDuplicates: $numberOfRequiredDuplicates
                associatedAccount: $associatedAccount
                connectwareLicenseClass: $connectwareLicenseClass
                name: $name
                expiration: $expiration
                autoRenewal: $autoRenewal
                permissionGroups: $permissionGroups
            }
        ) {
            success
            message
        }
    }
`

interface CreationInput {
    readonly numberOfRequiredDuplicates: number
    readonly associatedAccount: string
    readonly connectwareLicenseClass: string
    readonly name: string
    readonly expiration: string
    readonly autoRenewal: boolean
    readonly id: string
    readonly permissionGroups: string[]
}

interface DuplicateConnectwareLicenseResponse extends TypedResponse<'DuplicateConnectwareLicenseResponse'> {
    readonly success: boolean
    readonly message: string
}

// TODO Fix backend not handling duplicated name errors

export default ({
    id,
    name,
    associatedAccount,
    expiration,
    autoRenewal,
    licenseClass,
    duplicates,
    permissionGroups
}: ConnectwareLicenseDuplicationRequest): MutationHandler<CreationInput, { readonly duplicateConnectwareLicense: DuplicateConnectwareLicenseResponse }, void> =>
    buildMutationHandler(
        {
            document: DUPLICATE_CONNECTWARE_LICENSE,
            mapper: ({ data }) => {
                if (!data?.duplicateConnectwareLicense.success) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, data?.duplicateConnectwareLicense.message)
                }
            }
        },
        {
            id,
            name,
            associatedAccount,
            expiration: moment(expiration, 'DD.MM.YYYY', true).format(),
            autoRenewal,
            connectwareLicenseClass: licenseClass,
            numberOfRequiredDuplicates: duplicates,
            permissionGroups
        }
    )
