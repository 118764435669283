import gql from 'graphql-tag'

import { PortalError, PortalErrorType } from '../../../../domain'

import { ConnectwareApplianceCreationRequest } from '../../../../application/services'

import { TypedResponse } from './Response'
import { buildMutationHandler, MutationHandler } from './Builders'

const CREATE_CONNECTWARE_INSTANCE = gql`
    mutation CreateSelfHostedConnectwareAppliance($name: String!, $connectwareLicenseId: String!) {
        createSelfHostedConnectwareAppliance(input: { name: $name, connectwareLicenseId: $connectwareLicenseId }) {
            success
            message
        }
    }
`

type CreationInput = { readonly name: string; readonly connectwareLicenseId: string }

interface CreateConnectwareApplianceResponse extends TypedResponse<'CreateConnectwareApplianceResponse'> {
    readonly success: boolean
    readonly message: string
}

export default ({
    name,
    license
}: ConnectwareApplianceCreationRequest): MutationHandler<
    CreationInput,
    { readonly createSelfHostedConnectwareAppliance: CreateConnectwareApplianceResponse },
    void
> =>
    buildMutationHandler(
        {
            document: CREATE_CONNECTWARE_INSTANCE,
            mapper: ({ data }) => {
                if (!data?.createSelfHostedConnectwareAppliance.success) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, data?.createSelfHostedConnectwareAppliance.message)
                }
            }
        },
        { name, connectwareLicenseId: license }
    )
