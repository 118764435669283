import React, { useState as useReactState } from 'react'
import { makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Mutation } from '@apollo/client/react/components'
import { LICENSE, BULK_GRANT_ACCESS_TO_PERMISSION_GROUP } from '../../../../graphql'
import PermissionGroups from '../../../common/PermissionGroups'
import { ErrorMessage } from '../../../../infrastructure/react/components/common'
import { useUsecases } from '../../../../infrastructure/react/hooks'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  dialogEntry: {
    marginTop: `${theme.spacing(3)}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    wordWrap: 'break-word'
  },
  dialogText: {
    width: '100%',
    textAlign: 'left'
  },
  errorText: {
    marginLeft: `${theme.spacing(3)}px`
  },
  icon: {
    marginRight: `${theme.spacing(2)}px`,
    color: theme.palette.accent[500]
  },
  form: {
    width: '100%',
    textAlign: 'center'
  },
  select: {
    textAlign: 'left',
    width: '100%'
  }
}))

const RoleCreationModal = ({ open, handleClose: close, connectwareLicenseId, currentPermissionGroupIds }) => {
  const [permissionGroupIds, setpermissionGroupIds] = useReactState([])
  const classes = useStyles()
  const { manageConnectwareLicensesUsecase } = useUsecases()

  const handleChange = (e) => {
    setpermissionGroupIds(e.target.value)
  }

  const handleClose = () => {
    setpermissionGroupIds([])
    close()
  }

  const isDisabled = () => {
    return !Boolean(permissionGroupIds.length)
  }

  return (
    <Mutation
      mutation={BULK_GRANT_ACCESS_TO_PERMISSION_GROUP}
      variables={{
        permissionGroupIds,
        connectwareLicenseId
      }}
      onCompleted={async () => await manageConnectwareLicensesUsecase.loadLicenses()}
      refetchQueries={[{ query: LICENSE, variables: { id: connectwareLicenseId } }]}
    >
      {(grantAccessToPermissionGroup, { loading, data = {}, error }) => {
        const { bulkGrantAccessToPermissionGroup: { success, message } = {} } = data
        return (
          <Dialog fullWidth disableEscapeKeyDown open={open} onClose={handleClose} maxWidth='xs'>
            <form
              className={classes.form}
              onSubmit={(e) => {
                e.preventDefault()
                grantAccessToPermissionGroup()
                handleClose()
              }}
            >
              <DialogTitle>Grant Connectware License Access to Permission Groups</DialogTitle>
              <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
              <DialogContent className={classes.dialogContent}>
                <div className={classes.dialogEntry}>
                  <PermissionGroups filter={({ id }) => !currentPermissionGroupIds.includes(id)} selected={permissionGroupIds} handleSelect={handleChange} />
                </div>
                <ErrorMessage error={error} />
              </DialogContent>
              <DialogActions>
                <Button color='primary' onClick={handleClose}>
                  Cancel
                </Button>
                <Button color='primary' type='submit' disabled={isDisabled() || loading}>
                  Grant
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )
      }}
    </Mutation>
  )
}

export default RoleCreationModal
