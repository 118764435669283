import gql from 'graphql-tag'
import { UserAndAdminGroups, UserGroup } from '../../../../domain'

import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

const USER_AND_ADMIN_GROUPS = gql`
    query ListUserAndAdminGroups {
        listUserAndAdminGroups {
            userGroups
            adminGroups
        }
    }
`
interface UserAndAdminGroupsResponse extends TypedResponse<'listUserAndAdminGroups'> {
    readonly userGroups: UserGroup[]
    readonly adminGroups: UserGroup[]
}

export default (): QueryHandler<void, { readonly listUserAndAdminGroups: UserAndAdminGroupsResponse }, UserAndAdminGroups> =>
    buildQueryHandler(
        {
            document: USER_AND_ADMIN_GROUPS,
            mapper: ({ data }) => {
                const userGroups = data.listUserAndAdminGroups.userGroups
                const adminGroups = data.listUserAndAdminGroups.adminGroups
                return {
                    userGroups,
                    adminGroups
                }
            }
        },
        void 0
    )
