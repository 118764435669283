import React, { FC, useEffect, useState as useReactState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, LinearProgress, TextField, makeStyles } from '@material-ui/core'

import { FormattedTranslation, useState, useUsecases } from '../../../hooks'
import { ManageableConnectwareLicenses, PortalError, Translation } from '../../../../../domain'

import { DateField, ErrorMessage } from '../../common'

import { cybusAmber } from '../../../colors'
import { UserGroupsSelect } from '../../UserGroups'
import ConnectwareLicenseClass from '../../ConnectwareLicenseClass'

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    dialogEntry: {
        marginTop: `${theme.spacing(3)}px`,
        display: 'flex',
        // TODO Fix this
        flexDirection: ('left' as unknown) as undefined,
        alignItems: 'center',
        width: '100%',
        wordWrap: 'break-word'
    },
    dialogText: {
        width: '100%',
        textAlign: 'left'
    },
    errorText: {
        marginLeft: `${theme.spacing(3)}px`
    },
    icon: {
        marginRight: `${theme.spacing(2)}px`,
        // TODO Fix this
        color: ((theme.palette as unknown) as Partial<Record<string, typeof cybusAmber>>)?.accent?.[500]
    },
    form: {
        width: '100%',
        textAlign: 'center'
    },
    select: {
        textAlign: 'left',
        width: '100%'
    }
}))

export const DuplicateModal: FC = () => {
    const classes = useStyles()

    const { manageConnectwareLicensesUsecase } = useUsecases()
    const toBeDuplicated = useState((s) => s.manageLicenses?.toBeDuplicated || null)

    const [disabled, setDisabled] = useReactState(false)
    const [loading, setLoading] = useReactState(false)
    const [error, setError] = useReactState<PortalError | null>(null)

    useEffect(() => {
        if (!toBeDuplicated) {
            setDisabled(false)
            setLoading(false)
            setError(null)
        }
    }, [toBeDuplicated === null])

    if (!toBeDuplicated) {
        return null
    }

    const updateLicenseToBeDuplicated = (license: Partial<ManageableConnectwareLicenses['toBeDuplicated']>): void =>
        setDisabled(!manageConnectwareLicensesUsecase.updateLicenseToBeDuplicated(license))

    const duplicate = async (): Promise<void> => {
        try {
            setLoading(true)
            setError(null)
            await manageConnectwareLicensesUsecase.duplicateLicense()
            handleClose()
        } catch (e) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        manageConnectwareLicensesUsecase.toggleDuplication()
    }

    return (
        <Dialog fullWidth disableEscapeKeyDown open onClose={handleClose} maxWidth="xs">
            <form
                className={classes.form}
                onSubmit={(e) => {
                    e.preventDefault()
                    void duplicate()
                }}
            >
                <DialogTitle>
                    <FormattedTranslation id={Translation.DUPLICATE_LICENSE} />
                </DialogTitle>
                <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.dialogEntry}>
                        <TextField
                            name="numberOfRequiredDuplicates"
                            id="numberOfRequiredDuplicates"
                            label={<FormattedTranslation id={Translation.NUMBER_OF_DUPLICATES} />}
                            type="number"
                            className={classes.dialogText}
                            value={toBeDuplicated.duplicates}
                            autoComplete="off"
                            onChange={({ target: { value } }) => updateLicenseToBeDuplicated({ duplicates: Number(value) })}
                        />
                    </div>
                    <div className={classes.dialogEntry}>
                        <TextField
                            name="name"
                            id="name"
                            label={<FormattedTranslation id={Translation.NAME} />}
                            className={classes.dialogText}
                            value={toBeDuplicated.name}
                            autoComplete="off"
                            onChange={({ target: { value } }) => updateLicenseToBeDuplicated({ name: value })}
                        />
                    </div>
                    <div className={classes.dialogEntry}>
                        <UserGroupsSelect
                            account={toBeDuplicated.associatedAccount}
                            onChange={(associatedAccount) => updateLicenseToBeDuplicated({ associatedAccount })}
                        />
                    </div>
                    <div className={classes.dialogEntry}>
                        <DateField
                            name="expiration"
                            id="expiration"
                            label={<FormattedTranslation id={Translation.EXPIRATION} />}
                            className={classes.dialogText}
                            initialValue={toBeDuplicated.expiration || new Date()}
                            onChange={(expiration) => updateLicenseToBeDuplicated({ expiration })}
                        />
                    </div>
                    {/* <div className={classes.dialogEntry}>
                        <FormControlLabel
                            control={
                                <Switch
                                    name="autoRenewal"
                                    checked={toBeDuplicated.autoRenewal}
                                    onChange={() => updateLicenseToBeDuplicated({ autoRenewal: !toBeDuplicated.autoRenewal })}
                                    color="primary"
                                />
                            }
                            label={<FormattedTranslation id={Translation.AUTOMATIC_LICENSE_RENEWAL} />}
                        />
                    </div> */}
                    <div className={classes.dialogEntry}>
                        <ConnectwareLicenseClass
                            valueKey="name"
                            selected={toBeDuplicated.licenseClass}
                            onChange={(value) => updateLicenseToBeDuplicated({ licenseClass: value.name })}
                        />
                    </div>
                    {error && <ErrorMessage error={error} />}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        <FormattedTranslation id={Translation.CANCEL} />
                    </Button>
                    <Button color="primary" type="submit" disabled={disabled || loading}>
                        <FormattedTranslation id={Translation.DUPLICATE} />
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
