import React, { FC, useState } from 'react'
import { useTranslator } from '../../../hooks'
import { Table } from '../../common'
import AddUserGroup from './Toolbar/AddUserGroup'
import AddUserGroupModal from './Dialogs/AddUserGroupDialog'
import { TableProvider } from '../../common/Table'
import { Translation } from '../../../../../domain'
import DeleteUserGroupModal from './Dialogs/DeleteUserGroupDialog'
import DeleteUserGroup from './Toolbar/DeleteUserGroup'

interface Props {
    id: string
    userGroups?: string[]
}

const UserGroups: FC<Props> = ({ id, userGroups = [] }) => {
    const translator = useTranslator()
    const [data, setData] = useState(userGroups)
    return (
        <TableProvider>
            <Table
                title={translator.formatTranslation(Translation.USER_GROUPS_TITLE)}
                data={data.map((name) => ({ name }))}
                columns={[
                    { name: 'name', label: translator.formatTranslation(Translation.NAME), options: { filter: false, sort: true, sortDescFirst: false } }
                ]}
                options={{
                    selectableRows: 'multiple',
                    filter: false,
                    viewColumns: false,
                    elevation: 0,
                    textLabels: { body: { noMatch: '' } },
                    customToolbar: () => <AddUserGroup />,
                    customToolbarSelect: (selectedRows, _displayData, setSelected) => {
                        const selected: string[] = selectedRows.data.map(({ dataIndex }) => data[dataIndex])
                        return (
                            <>
                                <DeleteUserGroup />
                                <DeleteUserGroupModal id={id} onUpdate={setData} selected={selected} setSelected={setSelected} />
                            </>
                        )
                    }
                }}
                smallHeader
            />
            <AddUserGroupModal id={id} currentUserGroups={data} onUpdate={setData} />
        </TableProvider>
    )
}

export default UserGroups
