import { makeStyles, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { Translation } from '../../../../domain'
import { FormattedTranslation, useLocale } from '../../hooks'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2)
    }
}))

const Preferences: FC = () => {
    const classes = useStyles()
    const locale = useLocale()
    return (
        <div className={classes.container}>
            <Typography variant="subtitle1" gutterBottom>
                <FormattedTranslation id={Translation.PREFERENCES_TITLE} />
            </Typography>
            <Typography gutterBottom data-testid="language">
                <FormattedTranslation id={Translation.PREFERENCES_LANGUAGE} />
                {locale}
            </Typography>
        </div>
    )
}
export default Preferences
