import { ApolloClient, ApolloQueryResult, isApolloError, NormalizedCacheObject, ServerError, ServerParseError } from '@apollo/client'

import { isPortalError, PortalError, PortalErrorType } from '../../../domain'

import { MutationHandler, QueryHandler, SubcriptionHandler } from './handlers/Builders'

const mapApolloError = (e: unknown): PortalError => {
    if (isPortalError(e)) {
        return e
    }

    if (!e) {
        return new PortalError(PortalErrorType.UNEXPECTED, 'Unexpected falsy error thrown by apollo')
    }

    if (!(e instanceof Error)) {
        return new PortalError(PortalErrorType.UNEXPECTED, 'Unexpected error thrown by apollo')
    }

    if (!isApolloError(e)) {
        return new PortalError(PortalErrorType.UNEXPECTED, e.message, { ...e })
    }

    const graphQLErrors = e.graphQLErrors.reduce<string[]>((r, { message }) => (message ? r : [...r, message]), [])
    const statusCode = (e.networkError as ServerParseError | ServerError | null)?.statusCode || 0

    return new PortalError(
        PortalErrorType.SERVER_ERROR,
        `Not able to connect to server. StatusCode '${statusCode}'. GraphQLErrors '${JSON.stringify(graphQLErrors)}'`
    )
}

/**
 * This represents a query/mutation response from GraphQL
 * The current type definition is not the actual response
 */
type BaseResponse<T> = Partial<Pick<ApolloQueryResult<T>, 'data' | 'errors'>>

/**
 * For some reason, the error/no data scenarios do not automatically throw an exception
 * Until that is better understood, this throws the expected errors
 */
const handleErrorResponse = <R>(response: BaseResponse<R | null>): void => {
    if (response.errors) {
        response.errors.map(({ extensions, message }) => {
            const code = extensions?.code as unknown

            if (code === 'UNAUTHENTICATED' || code === 'FORBIDDEN') {
                throw new PortalError(PortalErrorType.MISSING_CREDENTIALS, message, { code })
            }

            if (code === 'INTERNAL_SERVER_ERROR') {
                throw new PortalError(PortalErrorType.SERVER_ERROR, message)
            }
        })
    }

    if (response.data === null || response.data === undefined) {
        throw new PortalError(PortalErrorType.SERVER_ERROR, 'No data was sent back')
    }
}

export class ApolloConnectwareService {
    private apolloClient: ApolloClient<NormalizedCacheObject>

    constructor(apolloClient: ApolloClient<NormalizedCacheObject>) {
        this.apolloClient = apolloClient
    }

    private async withApolloClient<R, M>(
        requestMaker: (client: ApolloClient<NormalizedCacheObject>) => Promise<R> | R,
        mapper: (result: R) => Promise<M> | M
    ): Promise<M> {
        let result: R

        try {
            result = await requestMaker(this.apolloClient)
        } catch (e) {
            throw mapApolloError(e)
        }

        return await mapper(result)
    }

    protected query<V, R, O>(args: QueryHandler<V, R, O>): Promise<O> {
        return this.withApolloClient(async (client) => {
            const response = await client.query<R, V>({ query: args.document, errorPolicy: args.errorPolicy || 'all', variables: args.variables })
            handleErrorResponse(response)
            return response
        }, args.mapper)
    }

    protected mutate<V, R, O>(args: MutationHandler<V, R, O>): Promise<O> {
        return this.withApolloClient(async (c) => {
            const response = await c.mutate({ mutation: args.document, errorPolicy: args.errorPolicy || 'all', variables: args.variables })
            handleErrorResponse(response)
            return response
        }, args.mapper)
    }

    /**
     * Subscribes to the GraphQL WebSocket handler
     * There are some issues with it, as described with-in
     * But it mostly works
     */
    protected subscribe<V, R, O>(args: SubcriptionHandler<V, R, O>): Promise<O> {
        return this.withApolloClient(
            (client) => client.subscribe<R, V>({ query: args.document, errorPolicy: args.errorPolicy || 'all', variables: args.variables }),
            (observable) =>
                new Promise<O>((resolve, reject) => {
                    try {
                        /**
                         * @todo once we are sure the backend is properly implemented, we might need to create a Subscription<...> on the domain
                         */
                        observable.subscribe((result) =>
                            result.data
                                ? resolve(args.mapper(result.data))
                                : reject(new PortalError(PortalErrorType.SERVER_ERROR, 'Subscription data was not expected'))
                        )
                    } catch (e) {
                        reject(e)
                    }
                })
        )
    }
}
