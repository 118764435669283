import { IconButton, Tooltip } from '@material-ui/core'
import React, { FC } from 'react'
import { Add } from '@material-ui/icons'
import { useToggler } from '../../../ManageRole.context'
import { FormattedTranslation } from '../../../../../hooks'
import { Translation } from '../../../../../../../domain'
const AddUserGroup: FC = () => {
    const toggle = useToggler('addRoleModalOpen')
    return (
        <Tooltip title={<FormattedTranslation id={Translation.ADD_USER_GROUP_LABEL} />}>
            <IconButton color={'primary'} onClick={toggle}>
                <Add />
            </IconButton>
        </Tooltip>
    )
}

export default AddUserGroup
