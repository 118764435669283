import React, { FC } from 'react'
import { AssignmentReturned, Edit, VpnKey } from '@material-ui/icons'
import Download from './Download'
import { FormattedTranslation, useParameters, useState, useUsecases } from '../../../hooks'
import { IconButton, Tooltip } from '@material-ui/core'
import { Translation } from '../../../../../domain'
import EditLicense from './Edit'

const Toolbar: FC = () => {
    const { id } = useParameters('id')
    const { manageConnectwareLicensesUsecase } = useUsecases()
    const { downloadLicenseKey, downloadLicenseFile, editLicenseAsUser } = useState((s) => s.manageLicenses)
    return (
        <div>
            <Tooltip title={<FormattedTranslation id={Translation.DOWNLOAD_LICENSE_KEY_TOOLTIP_TITLE} />}>
                <IconButton onClick={() => manageConnectwareLicensesUsecase.toggleDownloadLicenseKey()} color={'primary'}>
                    <VpnKey />
                </IconButton>
            </Tooltip>
            <Tooltip title={<FormattedTranslation id={Translation.DOWNLOAD_LICENSE_FILE_TOOLTIP_TITLE} />}>
                <IconButton onClick={() => manageConnectwareLicensesUsecase.toggleDownloadLicenseFile()} color={'primary'}>
                    <AssignmentReturned />
                </IconButton>
            </Tooltip>
            <Tooltip title={<FormattedTranslation id={Translation.EDIT_SELECTED_LICENSE} />}>
                <IconButton onClick={() => manageConnectwareLicensesUsecase.toggleUserLicenseEdit()} color={'primary'}>
                    <Edit />
                </IconButton>
            </Tooltip>
            {downloadLicenseKey && <Download id={id} />}
            {downloadLicenseFile && <Download />}
            {editLicenseAsUser && <EditLicense />}
        </div>
    )
}

export default Toolbar
