import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    uploadDialogContent: { display: 'flex', flexDirection: 'column' },
    uploadDialogEntry: { marginTop: `${theme.spacing(2)}px`, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' },
    dialogEntry: { marginTop: `${theme.spacing(3)}px`, display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' },
    uploadIcon: { marginRight: `${theme.spacing(4)}px`, color: theme.palette.secondary.main },
    uploadForm: { textAlign: 'left' },
    formControl: { textAlign: 'left', minWidth: '200px' }
}))
