import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Query } from '@apollo/client/react/components'
import { DEFAULT_CAPABILITIES } from '../../../../graphql'
import { ErrorMessage } from '../../../../infrastructure/react/components/common'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  formControl: {
    margin: `${theme.spacing(1)} 0px`,
    textAlign: 'left',
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  selected: {
    fontWeight: 600
  }
})

function Protocols({ selected, handleSelect, filter, classes }) {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor='versions'>Protocols</InputLabel>
      <Query query={DEFAULT_CAPABILITIES}>
        {({ loading, error, data: { defaultCapabilities } = {} }) => {
          if (loading) return <CircularProgress />
          if (error) return <ErrorMessage error={error} />
          const { protocols } = defaultCapabilities
          const protocolsFilter = protocols.filter(filter)
          return (
            <Select
              multiple
              value={selected}
              onChange={handleSelect}
              inputProps={{
                name: 'selectedProtocols',
                id: 'selectedProtocols'
              }}
            >
              {protocolsFilter.map((value) => (
                <MenuItem value={value} key={value} classes={selected.includes(value) ? { root: classes.selected } : {}}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          )
        }}
      </Query>
    </FormControl>
  )
}

export default withStyles(styles)(Protocols)
