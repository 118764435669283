import React, { createRef, FC, useEffect, useState } from 'react'
import { CssBaseline, makeStyles } from '@material-ui/core'

import { NavigationProvider } from './Navigation.context'

import { Header } from './Header'
import { LeftSideNavigation, WIDTH } from './LeftSide'

const useStyles = makeStyles((theme) => ({
    root: { display: 'flex' },
    content: { maxWidth: '100%', width: '100%', marginTop: '56px', flexGrow: 1, padding: theme.spacing(3) }
}))

const Navigation: FC = ({ children }) => {
    const [width, setWidth] = useState(0)
    const classes = useStyles()

    const mainRef = createRef<HTMLDivElement>()

    useEffect(() => {
        const ref = mainRef.current

        if (!ref) {
            return
        }

        const handleResize = () => setWidth(ref.clientWidth - WIDTH)

        window.addEventListener('resize', handleResize)
        setTimeout(handleResize, 10)

        return () => window.removeEventListener('resize', handleResize)
    }, [mainRef, mainRef.current])

    return (
        <NavigationProvider isDrawerOpen={false}>
            <div className={classes.root}>
                <CssBaseline />
                <Header />
                <LeftSideNavigation />
                <main ref={mainRef} style={{ width }} className={classes.content}>
                    {children}
                </main>
            </div>
        </NavigationProvider>
    )
}

export default Navigation
