import React, { FC, useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import { MUIDataTableMeta } from 'mui-datatables'

import { Translation, isPortalError } from '../../../../domain'

import { RoutePath, useRedirect, useState, useTranslator, useUsecases } from '../../hooks'

import { ErrorMessage, ExpirationStatus, Table } from '../common'
import Create from '../Licenses/Create'
import IsInUse from '../IsInUse'

const renderInUse = (description: string | null) => <IsInUse description={description || false} />

const createCustomToolbar = () => <Create />

export const Licenses: FC = () => {
    const translator = useTranslator()
    const r = useRedirect()
    const { licenses } = useState((s) => s.manageLicenses)
    const { manageConnectwareLicensesUsecase } = useUsecases()
    useEffect(() => {
        // load licenses everytime the component mounts
        // this is not the best, but enables us to fetch the data without having to fully reload the page
        void manageConnectwareLicensesUsecase.loadLicenses()
    }, [])

    if (!licenses) {
        return <CircularProgress />
    }

    if (isPortalError(licenses)) {
        return <ErrorMessage error={licenses} />
    }

    const expirationRenderer = (expiration: Date, { rowIndex }: MUIDataTableMeta) => (
        <ExpirationStatus date={expiration} autoRenewal={licenses[rowIndex].autoRenewal} />
    )

    return (
        <Table
            data={licenses}
            columns={[
                {
                    name: 'name',
                    label: translator.formatTranslation(Translation.NAME),
                    options: { filter: false, sort: true }
                },
                {
                    name: 'licenseClass',
                    label: translator.formatTranslation(Translation.LICENSE_CLASS),
                    options: { filter: true, sort: true }
                },
                {
                    name: 'description',
                    label: translator.formatTranslation(Translation.DESCRIPTION),
                    options: {
                        filter: true,
                        filterType: 'dropdown',
                        filterOptions: {
                            renderValue: (v) => (v ? v : translator.formatTranslation(Translation.LICENSE_DESCRIPTION_NOT_SET))
                        },
                        customFilterListOptions: {
                            render: (value: string | null) => {
                                return translator.formatTranslation(Translation.LICENSE_DESCRIPTION_CUSTOM_FILTER_OPTIONS_RENDER, { value: value || false })
                            }
                        },
                        sort: true,
                        customBodyRender: renderInUse
                    }
                },
                {
                    name: 'expiration',
                    label: translator.formatTranslation(Translation.EXPIRATION_DATE),
                    options: { filter: true, filterType: 'dropdown', sort: true, customBodyRender: expirationRenderer }
                }
            ]}
            options={{
                sortOrder: {
                    name: 'name',
                    direction: 'asc'
                },
                selectableRows: 'none',
                onCellClick: (_, { dataIndex }) => r.redirect(RoutePath.LICENSE, licenses[dataIndex].id),
                customToolbar: createCustomToolbar
            }}
        />
    )
}
