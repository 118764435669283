import gql from 'graphql-tag'

import { ConnectwareAccount, ConnectwareAccountType } from '../../../../domain'

import { TypedResponse } from './Response'
import { buildQueryHandler, QueryHandler } from './Builders'

const USER_INFO = gql`
    query UserInfo {
        userInfo {
            accounts
            username
            type
        }
    }
`

interface UserInfoResponse extends TypedResponse<'UserInfo'> {
    readonly accounts: string[]
    readonly username: string
    readonly type: ConnectwareAccountType
}

export default (): QueryHandler<void, { userInfo: UserInfoResponse }, ConnectwareAccount> =>
    buildQueryHandler(
        {
            document: USER_INFO,
            mapper: ({ data: { userInfo } }) => {
                return {
                    type: userInfo.type,
                    username: userInfo.username,
                    userGroups: userInfo.accounts
                }
            }
        },
        void 0
    )
