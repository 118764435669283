import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Query } from '@apollo/client/react/components'
import { ALL_PERMISSION_GROUPS } from '../../graphql'
import { ErrorMessage } from '../../infrastructure/react/components/common'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  formControl: {
    margin: `${theme.spacing(1)} 0px`,
    textAlign: 'left',
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  selected: {
    fontWeight: 600
  }
})

function PermissionGroups({ selected, handleSelect, filter, classes }) {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor='versions'>Roles</InputLabel>
      <Query query={ALL_PERMISSION_GROUPS}>
        {({ loading, error, data: { permissionGroups } = {} }) => {
          if (loading) return <CircularProgress />
          if (error) return <ErrorMessage error={error} />
          const permissionGroupsFilter = permissionGroups.filter(filter).sort((a, b) => a.name.localeCompare(b.name))
          return (
            <Select
              multiple
              value={selected}
              onChange={handleSelect}
              inputProps={{
                name: 'permissionGroupIds',
                id: 'permissionGroupIds'
              }}
            >
              {permissionGroupsFilter.map(({ name, id }) => (
                <MenuItem value={id} key={name} classes={selected.includes(id) ? { root: classes.selected } : {}}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          )
        }}
      </Query>
    </FormControl>
  )
}

export default withStyles(styles)(PermissionGroups)
