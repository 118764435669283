import gql from 'graphql-tag'

import { PortalError, PortalErrorType, Roles } from '../../../../domain'

import { ConnectwareRoleAccessCreationRequest } from '../../../../application/services'

import { buildMutationHandler, MutationHandler } from './Builders'
import { TypedResponse } from './Response'

const Revoke_ACCESS_TO_ROLE = gql`
    mutation RevokeAccessToRole($permissionGroupId: ID!, $userGroups: [String]) {
        revokeAccessToPermissionGroup(input: { permissionGroupId: $permissionGroupId, userGroups: $userGroups }) {
            permissionGroup {
                userGroups
            }
            success
            message
        }
    }
`

interface CreationInput {
    readonly permissionGroupId: string
    readonly userGroups: string[]
    /**
     * These additional properties are needed in the future
     */
    // readonly users?: string[]
    // readonly connectwareLicenseIds?: string[]
}

interface PermissionGroup {
    userGroups: string[]
}

interface RevokeAccessToRoleResponse extends TypedResponse<'RevokeAccessToRoleResponse'> {
    readonly success: boolean
    readonly message: string
    readonly permissionGroup: PermissionGroup
}

export default ({
    id: permissionGroupId,
    userGroups
}: ConnectwareRoleAccessCreationRequest): MutationHandler<
    CreationInput,
    { readonly revokeAccessToPermissionGroup: RevokeAccessToRoleResponse },
    Pick<Roles, 'userGroups'>
> =>
    buildMutationHandler(
        {
            document: Revoke_ACCESS_TO_ROLE,
            mapper: ({ data }) => {
                if (!data?.revokeAccessToPermissionGroup.success) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, 'Unexpected deletion response', { ...data?.revokeAccessToPermissionGroup })
                }
                return { userGroups: data.revokeAccessToPermissionGroup.permissionGroup.userGroups }
            }
        },
        { permissionGroupId, userGroups }
    )
