import React, { FC, useMemo } from 'react'
import {
    makeStyles,
    InputLabel,
    MenuItem,
    Link,
    DialogContentText,
    Typography,
    FormControl,
    Select,
    CircularProgress,
    colors,
    SelectProps
} from '@material-ui/core'
import { isPending, isRejected, usePromise } from 'react-sync-promise'

import { ApplianceConnectwareLicense, PortalError, Translation } from '../../../../domain'

import { buildMailToHref } from '../../../utils'
import { FormattedTranslation, useServices, useUsecases } from '../../hooks'
import { ErrorMessage } from '../common'

const useStyles = makeStyles((theme) => ({
    root: { display: 'flex', flexWrap: 'wrap', width: '100%' },
    formControl: { margin: `${theme.spacing(1)} 0px`, textAlign: 'left', width: '100%' },
    selectEmpty: { marginTop: theme.spacing(2) },
    recommended: { color: colors.blue[500] },
    label: { color: 'rgba(0,0,0,0.54)', marginRight: 'auto', fontSize: 'small' },
    context: { color: theme.palette.text.primary, textAlign: 'left' },
    selected: { fontWeight: 600 }
}))

export const AvailableLicenses: FC<{
    account: string | null
    selected: string
    handleSelect: SelectProps['onChange']
    filter?: (license: ApplianceConnectwareLicense) => boolean
}> = ({ account, selected, handleSelect, filter }) => {
    const classes = useStyles()

    const { connectwareLicenseUsecase } = useUsecases()
    const { configuration } = useServices()

    const promise = usePromise<ApplianceConnectwareLicense[], PortalError>(useMemo(() => connectwareLicenseUsecase.fetchAvailableLicenses(account), [account]))
    const { email } = configuration.getContactInformation()
    if (!account) return null

    if (isPending(promise)) {
        return <CircularProgress />
    }

    if (isRejected(promise)) {
        return <ErrorMessage error={promise.value} />
    }

    /**
     * @TODO revise if this filter is actually needed, it seems to have not been used at least since 2021 started
     */
    const licenses = filter ? promise.value.filter(filter) : promise.value

    if (licenses.length) {
        return (
            <FormControl className={classes.formControl} disabled={false}>
                <InputLabel htmlFor="versions">
                    <FormattedTranslation id={Translation.LICENSE_TITLE} />
                </InputLabel>
                <Select multiple value={selected} onChange={handleSelect} inputProps={{ name: 'license', id: 'licenses' }}>
                    {licenses.map(({ name, id }) => (
                        <MenuItem value={id} key={name} disabled={false} className={selected.includes(id) ? classes.selected : undefined}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }

    return (
        <>
            <Typography className={classes.label}>
                <FormattedTranslation id={Translation.LICENSE_TITLE} />
            </Typography>
            <DialogContentText className={classes.context}>
                <FormattedTranslation id={Translation.NO_LICENSES} values={{ emailLink: <Link href={buildMailToHref(email)}>{email}</Link> }} />
            </DialogContentText>
        </>
    )
}
