import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { Mutation } from '@apollo/client/react/components'
import { ALL_PERMISSION_GROUPS, DELETE_PERMISSION_GROUP } from '../../../graphql'
import { ErrorMessage } from '../../../infrastructure/react/components/common'

const styles = (theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  dialogEntry: {
    marginTop: `${theme.spacing(3)}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    wordWrap: 'break-word'
  },
  dialogText: {
    width: '100%',
    textAlign: 'left'
  },
  errorText: {
    marginLeft: `${theme.spacing(3)}px`
  },
  icon: {
    marginRight: `${theme.spacing(2)}px`,
    color: theme.palette.accent[500]
  },
  form: {
    width: '100%',
    textAlign: 'center'
  }
})

function Delete({ classes, open, id, toggleOpen, history }) {
  if (!open) return false
  return (
    <Mutation mutation={DELETE_PERMISSION_GROUP} variables={{ id }} refetchQueries={[{ query: ALL_PERMISSION_GROUPS }]} onCompleted={history.goBack}>
      {(deletePermissionGroup, { loading, error, data: { deletePermissionGroup: { message, success } = {} } = {} }) => (
        <Dialog open fullWidth disableEscapeKeyDown onClose={toggleOpen} maxWidth='xs'>
          <form
            className={classes.form}
            onSubmit={(e) => {
              e.preventDefault()
              deletePermissionGroup()
            }}
          >
            <DialogTitle>Delete Permission Group</DialogTitle>
            <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
            <DialogContent className={classes.dialogContent}>
              <div className={classes.dialogEntry}>
                <Typography className={classes.dialogText} variant='subtitle1' gutterBottom>
                  Are you sure you want to delete this permission group?
                </Typography>
              </div>
              <ErrorMessage error={error} />
            </DialogContent>
            <DialogActions>
              <Button color='primary' onClick={toggleOpen}>
                Cancel
              </Button>
              <Button color='primary' type='submit' disabled={loading}>
                Confirm
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Mutation>
  )
}

export default withRouter(withStyles(styles)(Delete))
