import { CircularProgress, Divider, makeStyles, Typography } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { isPending, isRejected, usePromise } from 'react-sync-promise'
import { PortalError, PortalUserSummary, Translation } from '../../../../domain'
import { FormattedTranslation, resolveRoute, RoutePath, useUsecases } from '../../hooks'
import { ErrorMessage } from '../common'
import { Tables } from './Tables'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2)
    }
}))

const Summary: FC = () => {
    const classes = useStyles()
    const { connectwareUsersUsecase } = useUsecases()
    const promise = usePromise<PortalUserSummary, PortalError>(useMemo(() => connectwareUsersUsecase.fetchPortalUserSummary(), []))

    if (isPending(promise)) {
        return <CircularProgress />
    }

    if (isRejected(promise)) {
        return <ErrorMessage error={promise.value} />
    }
    return (
        <div className={classes.container}>
            <Typography variant="subtitle1" gutterBottom>
                <FormattedTranslation id={Translation.SUMMARY_TITLE} />
            </Typography>
            <Typography gutterBottom data-testid="numberOfLicenses">
                <Link to={resolveRoute(RoutePath.LICENSES)}>
                    <FormattedTranslation id={Translation.LICENSES_IN_ACCOUNT} values={{ value: promise.value.numberOfLicenses }} />
                </Link>
            </Typography>
            <Typography gutterBottom data-testid="numberOfServices">
                <Link to={resolveRoute(RoutePath.SERVICES_CATALOG)}>
                    <FormattedTranslation id={Translation.SERVICES_IN_CATALOG} values={{ value: promise.value.numberOfServices }} />
                </Link>
            </Typography>
            <Typography gutterBottom data-testid="numberOfAppliances">
                <Link to={resolveRoute(RoutePath.APPLIANCES)}>
                    <FormattedTranslation id={Translation.APPLIANCES_IN_ACCOUNT} values={{ value: promise.value.numberOfAppliances }} />
                </Link>
            </Typography>
            <Divider />
            <Tables admin={promise.value.adminGroups} user={promise.value.userGroups} />
        </div>
    )
}
export default Summary
