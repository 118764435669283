import gql from 'graphql-tag'
import { ServiceContentRequest } from '../../../../application/services'
import { ServiceContent } from '../../../../domain'

import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

const SERVICE_CONTENT = gql`
    query ServiceContent($directory: String!, $filename: String!, $increaseDownloadCount: Boolean) {
        getRepositoryFile(input: { directory: $directory, filename: $filename, increaseDownloadCount: $increaseDownloadCount }) {
            filename
            data
            contentType
            size
            updatedAt
        }
    }
`

interface ServiceContentResponse extends TypedResponse<'ServiceContent'> {
    readonly filename: string
    readonly data: string
    readonly contentType: string
    readonly size: number
    readonly updatedAt: string
}

export default (
    { directory, filename }: ServiceContentRequest,
    increaseDownloadCount = false
): QueryHandler<ServiceContentRequest, { readonly getRepositoryFile: ServiceContentResponse }, ServiceContent> =>
    buildQueryHandler(
        {
            document: SERVICE_CONTENT,
            mapper: ({
                data: {
                    getRepositoryFile: { data, filename, contentType, size, updatedAt }
                }
            }) => {
                return {
                    filename,
                    directory,
                    contentType,
                    size,
                    updatedAt: new Date(updatedAt),
                    data: Buffer.from(data, 'base64').toString('utf-8')
                }
            }
        },
        { directory, filename, increaseDownloadCount }
    )
