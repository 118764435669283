import gql from 'graphql-tag'
import { ApplianceConnectwareLicense } from '../../../../domain'

import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

const AVAILABLE_CONNECTWARE_LICENSES = gql`
    query AvailableConnectwareLicenses($associatedAccount: String) {
        availableConnectwareLicenses(associatedAccount: $associatedAccount) {
            id
            name
        }
    }
`

interface ConnectwareLicenseResponse extends TypedResponse<'ConnectwareLicense'> {
    readonly id: string
    readonly name: string
}

export default (
    associatedAccount: string | null
): QueryHandler<
    { readonly associatedAccount: string | null },
    { readonly availableConnectwareLicenses: ConnectwareLicenseResponse[] },
    ApplianceConnectwareLicense[]
> =>
    buildQueryHandler(
        { document: AVAILABLE_CONNECTWARE_LICENSES, mapper: (r) => r.data.availableConnectwareLicenses.map(({ id, name }) => ({ id, name })) },
        { associatedAccount: associatedAccount }
    )
