import { Button, CircularProgress, Collapse, Divider, makeStyles, Paper, Typography } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import React, { FC } from 'react'
import { isPortalError } from '../../../../domain'
import { useIsAdmin, useState, useUsecases } from '../../hooks'
import { ErrorMessage } from '../common'
import ServiceCard from './ServiceCard'

const useStyles = makeStyles((theme) => ({
    root: { padding: `${theme.spacing(2)}px` },
    header: { display: 'flex', marginBottom: `${theme.spacing(2)}px` },
    headerTitle: { flexGrow: 1 },
    headerActions: { margin: `0 ${theme.spacing(2)}px`, display: 'flex', justifyContent: 'space-between', height: '40px' },
    bodyContent: { margin: `${theme.spacing(2)}px` },
    container: { width: '100%', display: 'flex', flexWrap: 'wrap' },
    item: { width: '200px', height: '200px', margin: `${theme.spacing(3)}px` },
    content: { height: '100%' },
    icon: { height: '80px', objectFit: 'scale-down', padding: '10px' },
    delete: { backgroundColor: 'rgba(255, 0, 0, 0.8)' },
    danger: {
        backgroundColor: 'rgba(255, 0, 0, 0.8)',
        color: 'white',
        '&:hover': {
            backgroundColor: 'rgba(255, 0, 0, 1)'
        }
    },
    cardContent: { alignSelf: 'baseline', display: 'flex', flexDirection: 'column', flexGrow: 1 },
    cardTitle: { fontSize: '1.2rem', flexGrow: 1 },
    categoryCollapsable: { display: 'flex' },
    marginBottom: { marginBottom: `${theme.spacing(4)}px` }
}))

export const ServicesCatalog: FC = () => {
    const classes = useStyles()
    const isAdmin = useIsAdmin()
    const services = useState((s) => s.manageServices?.services || null)
    const expanded = useState((s) => s.manageServices.expanded)
    const { manageServicesCatalogUsecase } = useUsecases()

    if (isPortalError(services)) {
        return <ErrorMessage error={services} />
    }

    return (
        <Paper elevation={4} className={classes.root}>
            <div className={classes.header}>
                <Typography variant="h4" gutterBottom component="h1" className={classes.headerTitle}>
                    Available Services
                </Typography>
                {isAdmin && (
                    <div className={classes.headerActions}>
                        <Button variant="contained" color="primary" onClick={() => manageServicesCatalogUsecase.toggleCreate()}>
                            Add new Service
                        </Button>
                    </div>
                )}
            </div>
            {!services ? (
                <CircularProgress />
            ) : (
                <div className={classes.bodyContent}>
                    {services.map(({ name, services }) => (
                        <div className={classes.marginBottom} key={name}>
                            <div className={classes.categoryCollapsable}>
                                <Typography variant="h5">{name}</Typography>
                                <Button
                                    color="inherit"
                                    onClick={() => manageServicesCatalogUsecase.toggleExpanded(name)}
                                    endIcon={expanded[name] ? <ExpandLess /> : <ExpandMore />}
                                >
                                    {expanded[name] ? 'hide all' : 'show all'}
                                </Button>
                            </div>
                            <Divider />
                            <Collapse in={Boolean(expanded[name])} timeout="auto" unmountOnExit>
                                <div className={classes.container}>
                                    {services.length === 0 ? (
                                        <Typography>No {name} Services available</Typography>
                                    ) : (
                                        services.map((x) => <ServiceCard key={x.name} app={x} />)
                                    )}
                                </div>
                            </Collapse>
                        </div>
                    ))}
                </div>
            )}
        </Paper>
    )
}
