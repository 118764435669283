import gql from 'graphql-tag'
import moment from 'moment'

import { PortalError, PortalErrorType } from '../../../../domain'

import { ConnectwareLicenseCreationRequest } from '../../../../application/services'

import { buildMutationHandler, MutationHandler } from './Builders'
import { TypedResponse } from './Response'

const CREATE_CONNECTWARE_LICENSE = gql`
    mutation CreateConnectwareLicense(
        $associatedAccount: String!
        $connectwareLicenseClass: String!
        $name: String!
        $expiration: Timestamp!
        $autoRenewal: Boolean!
    ) {
        createConnectwareLicense(
            input: {
                associatedAccount: $associatedAccount
                connectwareLicenseClass: $connectwareLicenseClass
                name: $name
                expiration: $expiration
                autoRenewal: $autoRenewal
            }
        ) {
            success
            message
        }
    }
`

interface CreationInput {
    readonly associatedAccount: string
    readonly name: string
    readonly expiration: string
    readonly autoRenewal: boolean
    readonly connectwareLicenseClass: string
}

interface CreateConnectwareLicenseResponse extends TypedResponse<'CreateConnectwareLicenseResponse'> {
    readonly success: boolean
    readonly message: string
}

export default ({
    name,
    associatedAccount,
    expiration,
    autoRenewal,
    licenseClass
}: ConnectwareLicenseCreationRequest): MutationHandler<CreationInput, { readonly createConnectwareLicense: CreateConnectwareLicenseResponse }, void> =>
    buildMutationHandler(
        {
            document: CREATE_CONNECTWARE_LICENSE,
            mapper: ({ data }) => {
                if (!data?.createConnectwareLicense.success) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, data?.createConnectwareLicense.message)
                }
            }
        },
        { name, associatedAccount, expiration: moment(expiration, 'DD.MM.YYYY', true).format(), autoRenewal, connectwareLicenseClass: licenseClass }
    )
