import React, { FC, useState as useReactState } from 'react'
import { DialogContent, Grid, Typography, makeStyles } from '@material-ui/core'
import CSVReader from 'react-csv-reader'
import { PortalError, Translation } from '../../../../../domain'
import { FormattedTranslation, useUsecases } from '../../../hooks'
import { CloudUpload } from '@material-ui/icons'
import { ErrorMessage } from '../../common'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: `${theme.spacing(2)}px`
    },
    reader: {
        fontSize: theme.typography.body1.fontSize
    },
    icon: {
        marginRight: `${theme.spacing(4)}px`
    }
}))

const FileUpload: FC = () => {
    const classes = useStyles()
    const [error, setError] = useReactState<PortalError | null>(null)
    const { metricsUsecase } = useUsecases()
    return (
        <DialogContent>
            <Grid container className={classes.container}>
                <Grid item className={classes.icon}>
                    <CloudUpload color={'primary'} />
                </Grid>
                <Grid item>
                    <CSVReader
                        cssInputClass={classes.reader}
                        parserOptions={{ header: true }}
                        onFileLoaded={(data) => {
                            try {
                                metricsUsecase.configureUpload(data)
                            } catch (e) {
                                setError(e as PortalError)
                            }
                        }}
                    />
                </Grid>
            </Grid>
            {error && <ErrorMessage error={error} />}
        </DialogContent>
    )
}

export const FileUploadSection: FC = () => {
    return (
        <DialogContent>
            <Typography variant={'subtitle1'} align={'left'}>
                <FormattedTranslation id={Translation.METRICS_UPLOAD_DIALOG_SELECT_FILE} />
            </Typography>
            <Typography variant={'subtitle2'} align={'left'}>
                <FormattedTranslation id={Translation.METRICS_UPLOAD_DIALOG_SELECT_FILE_INFO_TEXT} />
            </Typography>
            <FileUpload />
        </DialogContent>
    )
}
