import React, { FC } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { ApolloError, isApolloError, ServerError, ServerParseError } from '@apollo/client'

import { PortalError, PortalErrorType, Translation } from '../../../../../domain'

import { FormattedTranslation, useServices } from '../../../hooks'
import { customStrongFormatter } from '../../utils'

const useStyles = makeStyles({ text: { width: '100%', textAlign: 'left' } })

type DeprecatedProps = {
    /**
     * @todo this component needs to be revised in order to remove the deprecated portion
     * @deprecated
     */
    readonly error?: ApolloError | string
}

const DeprecatedErrorMessage: FC<{ readonly error: PortalError } | DeprecatedProps> = ({ error }) => {
    const { logger } = useServices()

    if (error === undefined) {
        logger.warn('Not rendering error, no error is actually present')
        return <></>
    }

    if (typeof error === 'string') {
        logger.warn('This use of ErrorMessage is deprecated, please use PortalError', { error })
        error = new PortalError(PortalErrorType.UNEXPECTED, error)
    }

    if (isApolloError(error)) {
        logger.warn('This use of ErrorMessage is deprecated, please use PortalError', { error })

        const graphQLErrors = error.graphQLErrors.reduce<string[]>((r, { message }) => (message ? r : [...r, message]), [])
        const statusCode = (error.networkError as ServerParseError | ServerError | null)?.statusCode || 0

        error = new PortalError(
            PortalErrorType.SERVER_ERROR,
            `Not able to connect to server. StatusCode '${statusCode}'. GraphQLErrors '${JSON.stringify(graphQLErrors)}'`
        )
    }

    return <ErrorMessage error={error} />
}

const ErrorMessage: FC<{ error: PortalError }> = ({ error: { type, message } }) => {
    const classes = useStyles()

    return (
        <Typography className={classes.text} variant="subtitle1" gutterBottom>
            <FormattedTranslation id={Translation.ERROR_DESCRIPTION} values={{ type, message, strong: customStrongFormatter() }} />
        </Typography>
    )
}

export default DeprecatedErrorMessage
