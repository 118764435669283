import React from 'react'
import 'typeface-roboto'
import ReactDOM from 'react-dom'
import { createMuiTheme } from '@material-ui/core/styles'
import { ApolloProvider } from '@apollo/client'

import { createUsecases } from './application/usecases'
import { initialState } from './application/InitialState'

import App from './App'
import { cybusBlue, cybusAmber } from './infrastructure/react/colors'

import { AppWrapper } from './infrastructure/react'
import { resolveInjections } from './infrastructure/parcel/injections'
import config from './infrastructure/parcel/config.json'

// TODO Move this file to `frontend/src/infrastructure/parcel/index.js`

const cybusTheme = createMuiTheme({
  palette: {
    primary: cybusBlue,
    secondary: cybusAmber,
    accent: cybusAmber
  },
  overrides: {
    MuiChip: {
      outlinedSecondary: {
        color: cybusAmber[500],
        borderColor: cybusAmber[500]
      }
    },
    MuiBadge: {
      colorSecondary: {
        backgroundColor: cybusAmber[500],
        color: cybusBlue[500]
      },
      badge: {
        height: '30px',
        width: '30px',
        borderRadius: '15px'
      },
      anchorOriginTopLeftCircle: {
        top: '15%',
        left: '15%'
      }
    }
  },
  typography: {
    h1: {
      fontSize: '7rem',
      fontWeight: 300,
      lineHeight: '1.14286em',
      marginLeft: '-.04em',
      letterSpacing: '-.04em'
    },
    h2: {
      fontSize: '3.5rem',
      fontWeight: 400,
      lineHeight: '1.30357em',
      marginLeft: '-.02em',
      letterSpacing: '-.02em'
    },
    h3: {
      fontSize: '2.8125rem',
      fontWeight: 400,
      lineHeight: '1.13333em',
      marginLeft: '-.02em'
    },
    h4: {
      fontSize: '2.125rem',
      fontWeight: 400,
      lineHeight: '1.20588em'
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '1.35417em'
    },
    h6: {
      fontSize: '1.3125rem',
      fontWeight: 500,
      lineHeight: '1.16667em'
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.5em'
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.71429em'
    },
    body2: {
      fontSize: '0.8125rem',
      fontWeight: 400,
      lineHeight: '1.46429em'
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.46429em'
    }
  }
})

/**
 * Create the services to be injected into the application
 */
const [services, intl, wrappedApolloClient, appStateStore] = resolveInjections(initialState, config)
const usecases = createUsecases(services)

/**
 * Validate if authentication is present, otherwise, logout
 */
if (!usecases.authenticationUsecase.loadToken()) {
  usecases.authenticationUsecase.logOut()
} else {
  wrappedApolloClient.onNotAuthorized(() => usecases.authenticationUsecase.logOut())

  ReactDOM.render(
    <AppWrapper
      materailUiTheme={cybusTheme}
      intl={intl}
      logger={services.logger}
      configuration={services.configurationService}
      usecases={usecases}
      store={appStateStore}
    >
      <ApolloProvider client={wrappedApolloClient.client}>
        <App />
      </ApolloProvider>
    </AppWrapper>,
    document.getElementById('root')
  )
}
