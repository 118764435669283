import { IconButton, Tooltip } from '@material-ui/core'
import { Publish } from '@material-ui/icons'
import React, { FC } from 'react'
import { Translation } from '../../../../../domain'
import { FormattedTranslation, useUsecases } from '../../../hooks'

export const UploadButton: FC = () => {
    const { metricsUsecase } = useUsecases()
    return (
        <Tooltip title={<FormattedTranslation id={Translation.UPLOAD_METRICS} />}>
            <IconButton id="upload-metrics-button" color="primary" onClick={() => metricsUsecase.toggleUpload()}>
                <Publish />
            </IconButton>
        </Tooltip>
    )
}
