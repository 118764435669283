import gql from 'graphql-tag'

import { PortalError, PortalErrorType, ServiceMetaData } from '../../../../domain'

import { ServiceDeletionRequest } from '../../../../application/services'

import { TypedResponse } from './Response'
import { buildMutationHandler, MutationHandler } from './Builders'

const DELETE_SERVICE = gql`
    mutation DeleteRepositoryFile($filename: String!, $directory: String!) {
        deleteRepositoryFile(input: { filename: $filename, directory: $directory }) {
            success
            message
        }
    }
`
type DeleteInput = Pick<ServiceMetaData, 'filename' | 'directory'>

interface DeleteServiceResponse extends TypedResponse<'DeleteServiceResponse'> {
    readonly success: boolean
    readonly message: string
}

export default ({
    filename,
    directory
}: ServiceDeletionRequest): MutationHandler<DeleteInput, { readonly deleteRepositoryFile: DeleteServiceResponse }, void> =>
    buildMutationHandler(
        {
            document: DELETE_SERVICE,
            mapper: ({ data }) => {
                if (!data?.deleteRepositoryFile.success) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, 'Unexpected deletion response', { ...data?.deleteRepositoryFile })
                }
            }
        },
        {
            filename,
            directory
        }
    )
