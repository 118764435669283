import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import IsInUse from '../common/IsInUse'
import ExpirationStatus from '../common/ExpirationStatus'

const styles = (theme) => ({
  info: {
    margin: '10px 0px',
    display: 'grid',
    gridTemplateColumns: 'repeat(5, auto)',
    gridGap: '10px',
    gridAutoRows: 'minmax(60px, auto)',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      padding: '10px'
    }
  },
  infoText: {
    paddingBottom: '5px'
  },
  cell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  }
})

function Status({ connectwareLicense, classes }) {
  return (
    <div className={classes.info}>
      <div className={classes.cell}>
        <Typography variant='body2'>License Class</Typography>
        <Typography className={classes.infoText}>{connectwareLicense.licenseClass}</Typography>
      </div>
      <div className={classes.cell}>
        <Typography variant='body2'>Description</Typography>
        <Typography className={classes.infoText}>
          <IsInUse description={connectwareLicense.description || false} />
        </Typography>
      </div>
      <div className={classes.cell}>
        <Typography variant='body2'>Account</Typography>
        <Typography className={classes.infoText}>{connectwareLicense.associatedAccount || 'N/A'}</Typography>
      </div>
      <div className={classes.cell}>
        <Typography variant='body2'>Expiration Date</Typography>
        <Typography className={classes.infoText}>
          <ExpirationStatus date={connectwareLicense.expiration} autoRenewal={connectwareLicense.autoRenewal} />
        </Typography>
      </div>
      {/*<div className={classes.cell}>
        <Typography variant='body2'>Automatic license renewal</Typography>
        <Typography className={classes.infoText}>{connectwareLicense.autoRenewal ? 'Yes' : 'No'}</Typography>
      </div>*/}
    </div>
  )
}

export default withStyles(styles)(Status)
