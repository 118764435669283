import gql from 'graphql-tag'

import { buildQueryHandler, QueryHandler } from './Builders'

const ACCOUNTS = gql`
    query Accounts {
        accounts
    }
`

export default (): QueryHandler<void, { readonly accounts: string[] }, string[]> =>
    buildQueryHandler({ document: ACCOUNTS, mapper: ({ data }) => data.accounts }, void 0)
