import gql from 'graphql-tag'
import { ParsedMetadataRequest } from '../../../../application/services'
import { Metadata } from '../../../../domain'

import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

const PARSE_METADATA = gql`
    query ParseMeta($file: String!) {
        parseMetadata(file: $file) {
            name
        }
    }
`

interface MetadataResponse extends TypedResponse<'Metadata'> {
    readonly name: string
}

interface ParsedMetadataInput {
    file: string
}

export default (request: ParsedMetadataRequest): QueryHandler<ParsedMetadataInput, { readonly parseMetadata: MetadataResponse }, Metadata> =>
    buildQueryHandler(
        {
            document: PARSE_METADATA,
            mapper: ({
                data: {
                    parseMetadata: { name }
                }
            }) => {
                return {
                    name
                }
            }
        },
        { file: request.data }
    )
