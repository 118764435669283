import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import { Mutation } from '@apollo/client/react/components'
import { ALL_PERMISSION_GROUPS, UPDATE_PERMISSION_GROUP } from '../../../../graphql'
import { ErrorMessage } from '../../../../infrastructure/react/components/common'

const styles = (theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  dialogEntry: {
    marginTop: `${theme.spacing(3)}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    wordWrap: 'break-word'
  },
  dialogText: {
    width: '100%',
    textAlign: 'left'
  },
  errorText: {
    marginLeft: `${theme.spacing(3)}px`
  },
  icon: {
    marginRight: `${theme.spacing(2)}px`,
    color: theme.palette.accent[500]
  },
  form: {
    width: '100%',
    textAlign: 'center'
  },
  select: {
    textAlign: 'left',
    width: '100%'
  }
})

class Edit extends Component {
  state = { name: '' }

  componentDidMount() {
    this.setDefaults()
  }

  setDefaults(callback) {
    const { name } = this.props
    this.setState({ name }, () => {
      if (callback) callback()
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleClose = () => {
    const { toggleOpen } = this.props
    this.setDefaults(toggleOpen)
  }

  render() {
    const { id, classes, open } = this.props
    const { associatedAccount, connectwareLicenseClass, name } = this.state
    return (
      <Mutation
        mutation={UPDATE_PERMISSION_GROUP}
        variables={{
          id,
          associatedAccount,
          name,
          connectwareLicenseClass
        }}
        refetchQueries={[{ query: ALL_PERMISSION_GROUPS }]}
        onCompleted={this.handleClose}
      >
        {(deletePermissionGroup, { loading, data, error }) => (
          <Dialog fullWidth disableEscapeKeyDown open={open} onClose={this.handleClose} maxWidth='xs'>
            <form
              className={classes.form}
              onSubmit={(e) => {
                e.preventDefault()
                deletePermissionGroup()
              }}
            >
              <DialogTitle>Edit Permission Group</DialogTitle>
              <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
              <DialogContent className={classes.dialogContent}>
                <div className={classes.dialogEntry}>
                  <TextField
                    name='name'
                    id='name'
                    label='Name'
                    className={classes.dialogText}
                    InputProps={{ placeholder: '' }}
                    value={name}
                    autoComplete='off'
                    onChange={this.handleChange}
                  />
                </div>
                <FormError data={data} />
                <ErrorMessage error={error} />
              </DialogContent>
              <DialogActions>
                <Button color='primary' onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button color='primary' type='submit' disabled={!name || loading}>
                  Save
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )}
      </Mutation>
    )
  }
}

function FormError({ data = {} }) {
  const { deletePermissionGroup = {} } = data
  const { success, message } = deletePermissionGroup
  if (success) return false
  return <p>{message}</p>
}

export default withStyles(styles)(Edit)
