import gql from 'graphql-tag'
import { ServiceMetaData, ServicesByCategory } from '../../../../domain'

import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

const SERVICES_BY_CATEGORIES = gql`
    query ServicesByCategories {
        servicesByCategories {
            name
            services {
                directory
                filename
                name
                category
                description
                homepage
                icon
                version
                provider
                publiclyOffered
                availableInLicense
                downloads
                createdAt
                updatedAt
                isTemplate
                allowedToEdit
                allowedToDelete
            }
        }
    }
`

interface ServicesByCategoriesResponse extends TypedResponse<'ServicesByCategoriesResponse'> {
    readonly name: string
    readonly services: ServiceMetaData[]
}

export default (): QueryHandler<void, { readonly servicesByCategories: ServicesByCategoriesResponse[] }, ServicesByCategory[]> =>
    buildQueryHandler(
        {
            document: SERVICES_BY_CATEGORIES,
            mapper: ({ data }) => {
                return data.servicesByCategories.map(({ name, services }) => ({ name, services })).sort((a, b) => a.name.localeCompare(b.name))
            }
        },
        void 0
    )
