import { Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Translation } from '../../../../domain'
import { FormattedTranslation, RoutePath } from '../../hooks'

import { Page } from '../common'

export const Wrapper: FC = () => {
    return (
        <Page>
            <Typography paragraph>
                <FormattedTranslation id={Translation.APPLIANCES_NO_LONGER_SUPPORTED_1} />
            </Typography>
            <Typography color="textSecondary" paragraph>
                <FormattedTranslation id={Translation.APPLIANCES_NO_LONGER_SUPPORTED_2} />
            </Typography>
            <Typography>
                <FormattedTranslation id={Translation.APPLIANCES_REDIRECT_TEXT} />
                <Link to={RoutePath.LICENSES}>
                    <FormattedTranslation id={Translation.LICENSES} />
                </Link>
            </Typography>
        </Page>
    )
}
