import gql from 'graphql-tag'
import { ConnectwareLicense } from './queries'

/**
 * Types and Interfaces
 */
interface UpateResponse {
    success: boolean
    message: string
    connectwareLicense: ConnectwareLicense
}
export interface BulkUpdateLicense {
    bulkUpdateConnectwareLicense: UpateResponse
}

export const GENERATE_LICENSE_KEY = gql`
    mutation GenerateLicenseKey($id: ID!) {
        generateConnectwareLicenseKeyForSelfHostedConnectwareAppliance(id: $id) {
            message
            success
            connectwareLicenseKey
        }
    }
`

export const GENERATE_LICENSE_FILE = gql`
    mutation ConnectwareLicenseFile($connectwareLicenseKey: String!) {
        connectwareLicenseFile(connectwareLicenseKey: $connectwareLicenseKey) {
            connectwareLicenseFile
        }
    }
`

export const UPDATE_CONNECTWARE_LICENSE = gql`
    mutation UpdateConnectwareLicense(
        $id: ID!
        $connectwareLicenseClass: String
        $associatedAccount: String
        $name: String
        $description: String
        $expiration: Timestamp
        $autoRenewal: Boolean
        $capabilities: Capabilities
        $archived: Boolean
    ) {
        updateConnectwareLicense(
            input: {
                id: $id
                connectwareLicenseClass: $connectwareLicenseClass
                associatedAccount: $associatedAccount
                name: $name
                description: $description
                expiration: $expiration
                autoRenewal: $autoRenewal
                capabilities: $capabilities
                archived: $archived
            }
        ) {
            success
            message
            connectwareLicense {
                id
                associatedAccount
                connectwareLicenseClass
                name
                expiration
                autoRenewal
                capabilities
            }
        }
    }
`

export const DELETE_CONNECTWARE_LICENSE = gql`
    mutation DeleteConnectewareLicense($id: ID!) {
        deleteConnectwareLicense(id: $id) {
            success
            message
        }
    }
`

export const BULK_DELETE_CONNECTWARE_LICENSES = gql`
    mutation BulkDeleteConnectwareLicenses($ids: [ID]!) {
        bulkDeleteConnectwareLicenses(ids: $ids) {
            success
            message
        }
    }
`

export const DUPLICATE_CONNECTWARE_LICENSE = gql`
    mutation DuplicateConnectwareLicense(
        $id: ID!
        $numberOfRequiredDuplicates: Int!
        $associatedAccount: String!
        $connectwareLicenseClass: String!
        $name: String!
        $expiration: Timestamp!
        $autoRenewal: Boolean!
        $permissionGroups: [String]!
    ) {
        duplicateConnectwareLicense(
            input: {
                id: $id
                numberOfRequiredDuplicates: $numberOfRequiredDuplicates
                associatedAccount: $associatedAccount
                connectwareLicenseClass: $connectwareLicenseClass
                name: $name
                expiration: $expiration
                autoRenewal: $autoRenewal
                permissionGroups: $permissionGroups
            }
        ) {
            success
            message
            connectwareLicenses {
                id
                associatedAccount
                connectwareLicenseClass
                name
                expiration
                autoRenewal
            }
        }
    }
`

export const CREATE_PERMISSION_GROUP = gql`
    mutation CreatePermissionGroup($name: String!, $registryPermissions: [RegistryPermissionInput]!) {
        createPermissionGroup(input: { name: $name, registryPermissions: $registryPermissions }) {
            success
            message
            permissionGroup {
                id
                name
                users
                registryPermissions {
                    url
                    operation
                }
            }
        }
    }
`

export const UPDATE_PERMISSION_GROUP = gql`
    mutation UpdatePermissionGroup($id: ID!, $name: String, $registryPermissions: [RegistryPermissionInput]) {
        updatePermissionGroup(input: { id: $id, name: $name, registryPermissions: $registryPermissions }) {
            success
            message
            permissionGroup {
                id
                name
                users
                registryPermissions {
                    url
                    operation
                }
            }
        }
    }
`

export const DELETE_PERMISSION_GROUP = gql`
    mutation DeletePermissionGroup($id: ID!) {
        deletePermissionGroup(id: $id) {
            success
            message
            permissionGroup {
                id
                name
                users
                registryPermissions {
                    url
                    operation
                }
            }
        }
    }
`

export const GRANT_ACCESS_TO_PERMISSION_GROUP = gql`
    mutation GrantAccessToPermissionGroup($permissionGroupId: ID!, $users: [String], $connectwareLicenseIds: [String]) {
        grantAccessToPermissionGroup(input: { permissionGroupId: $permissionGroupId, users: $users, connectwareLicenseIds: $connectwareLicenseIds }) {
            success
            message
            permissionGroup {
                id
                name
                users
                registryPermissions {
                    url
                    operation
                }
            }
        }
    }
`

export const REVOKE_ACCESS_TO_PERMISSION_GROUP = gql`
    mutation RevokeAccessToPermissionGroup($permissionGroupId: ID!, $users: [String], $connectwareLicenseIds: [String]) {
        revokeAccessToPermissionGroup(input: { permissionGroupId: $permissionGroupId, users: $users, connectwareLicenseIds: $connectwareLicenseIds }) {
            success
            message
            permissionGroup {
                id
                name
                users
                registryPermissions {
                    url
                    operation
                }
            }
        }
    }
`

export const BULK_GRANT_ACCESS_TO_PERMISSION_GROUP = gql`
    mutation BulkGrantAccessToPermissionGroup($user: String, $connectwareLicenseId: String, $permissionGroupIds: [String]!) {
        bulkGrantAccessToPermissionGroup(input: { user: $user, connectwareLicenseId: $connectwareLicenseId, permissionGroupIds: $permissionGroupIds }) {
            success
            message
        }
    }
`

export const BULK_REVOKE_ACCESS_TO_PERMISSION_GROUP = gql`
    mutation BulkRevokeAccessToPermissionGroup($user: String, $connectwareLicenseId: String, $permissionGroupIds: [String]!) {
        bulkRevokeAccessToPermissionGroup(input: { user: $user, connectwareLicenseId: $connectwareLicenseId, permissionGroupIds: $permissionGroupIds }) {
            success
            message
        }
    }
`

export const BULK_UPDATE_CONNECTWARE_LICENSE = gql`
    mutation BulkUpdate($ids: [ID]!, $expiration: Timestamp!) {
        bulkUpdateConnectwareLicense(ids: $ids, expiration: $expiration) {
            success
            message
        }
    }
`
