import React, { FC, useMemo } from 'react'
import { usePromise, isPending, isRejected } from 'react-sync-promise'
import { makeStyles, InputLabel, MenuItem, FormControl, Select, SelectProps, CircularProgress, colors } from '@material-ui/core'

import { PortalError, Translation } from '../../../../domain'

import { FormattedTranslation, useUsecases } from '../../hooks'
import { ErrorMessage } from '../common'

const useStyles = makeStyles((theme) => ({
    root: { display: 'flex', flexWrap: 'wrap', width: '100%' },
    formControl: { margin: `${theme.spacing(1)} 0px`, textAlign: 'left', width: '100%' },
    selectEmpty: { marginTop: theme.spacing(2) },
    recommended: { color: colors.blue[500] }
}))

/**
 * @todo revise types once typescript migration is done
 */
type Props = { readonly selected: SelectProps['value']; readonly handleSelect: SelectProps['onChange'] }

const AccountsSelect: FC<Props> = ({ selected, handleSelect }) => {
    const { connectwareUsersUsecase } = useUsecases()
    const promise = usePromise<string[], PortalError>(useMemo(() => connectwareUsersUsecase.fetchAccountNames(), []))

    if (isPending(promise)) {
        return <CircularProgress />
    }

    if (isRejected(promise)) {
        return <ErrorMessage error={promise.value} />
    }

    return (
        <Select value={selected || ''} onChange={handleSelect} inputProps={{ name: 'account', id: 'accounts' }}>
            {promise.value.map((account) => (
                <MenuItem value={account} key={account}>
                    {account}
                </MenuItem>
            ))}
        </Select>
    )
}

export const Accounts: FC<Props> = (props) => {
    const classes = useStyles()

    return (
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor="account">
                <FormattedTranslation id={Translation.ACCOUNT} />
            </InputLabel>
            <AccountsSelect {...props} />
        </FormControl>
    )
}
