import gql from 'graphql-tag'

import { Category } from '../../../../domain'

import { buildQueryHandler, QueryHandler } from './Builders'

const CATEGORIES = gql`
    query Categories {
        categories
    }
`

export default (): QueryHandler<void, { readonly categories: string[] }, Category[]> =>
    buildQueryHandler(
        {
            document: CATEGORIES,
            mapper: ({ data }) => {
                return data.categories.map((name) => ({ name }))
            }
        },
        void 0
    )
