import { AuthenticationUsecase } from './Authentication'
import {
    ConnectwareLicenseUsecase,
    ManageConnectwareLicensesUsecase,
    ConnectwareUsersUsecase,
    ConnectwareApplianceUsecase,
    ServicesCatalogUsecase,
    ManageServicesUsecase,
    MetricsUsecase
} from './Connectware'

import { Usecase, UsecaseServices, UsecasesRegistry } from './Usecase'

export type Usecases = {
    readonly authenticationUsecase: AuthenticationUsecase
    readonly connectwareLicenseUsecase: ConnectwareLicenseUsecase
    readonly connectwareApplianceUsecase: ConnectwareApplianceUsecase
    readonly connectwareUsersUsecase: ConnectwareUsersUsecase
    readonly manageConnectwareLicensesUsecase: ManageConnectwareLicensesUsecase
    readonly servicesCatalogUsecase: ServicesCatalogUsecase
    readonly manageServicesCatalogUsecase: ManageServicesUsecase
    readonly metricsUsecase: MetricsUsecase
}

/**
 * @returns created use cases dependent on the given services
 */
export const createUsecases = (services: UsecaseServices): Usecases => {
    const registry: UsecasesRegistry = new Map<typeof Usecase, Usecase>()

    return {
        authenticationUsecase: new AuthenticationUsecase(registry, services),
        connectwareLicenseUsecase: new ConnectwareLicenseUsecase(registry, services),
        connectwareApplianceUsecase: new ConnectwareApplianceUsecase(registry, services),
        connectwareUsersUsecase: new ConnectwareUsersUsecase(registry, services),
        manageConnectwareLicensesUsecase: new ManageConnectwareLicensesUsecase(registry, services),
        servicesCatalogUsecase: new ServicesCatalogUsecase(registry, services),
        manageServicesCatalogUsecase: new ManageServicesUsecase(registry, services),
        metricsUsecase: new MetricsUsecase(registry, services)
    }
}
