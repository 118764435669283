import gql from 'graphql-tag'

import { PortalError, PortalErrorType, ServiceMetaData } from '../../../../domain'

import { ServiceRequest } from '../../../../application/services'

import { TypedResponse } from './Response'
import { buildMutationHandler, MutationHandler } from './Builders'

const CREATE_SERVICE = gql`
    mutation CreateRepositoryFile(
        $filename: String!
        $directory: String!
        $description: String!
        $name: String!
        $category: String!
        $data: Base64Encoded!
        $publiclyOffered: Boolean!
        $isTemplate: Boolean!
    ) {
        addRepositoryFile(
            input: {
                filename: $filename
                directory: $directory
                name: $name
                description: $description
                category: $category
                data: $data
                publiclyOffered: $publiclyOffered
                isTemplate: $isTemplate
            }
        ) {
            success
            message
        }
    }
`
interface CreateInput extends Pick<ServiceMetaData, 'filename' | 'directory' | 'description' | 'name' | 'publiclyOffered' | 'isTemplate'> {
    data: string
    category: string
}

interface CreateServiceResponse extends TypedResponse<'CreateServiceResponse'> {
    readonly success: boolean
    readonly message: string
}

export default ({
    filename,
    directory,
    data,
    name,
    description,
    publiclyOffered,
    isTemplate,
    category
}: ServiceRequest): MutationHandler<CreateInput, { readonly addRepositoryFile: CreateServiceResponse }, void> =>
    buildMutationHandler(
        {
            document: CREATE_SERVICE,
            mapper: ({ data }) => {
                if (!data?.addRepositoryFile.success) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, data?.addRepositoryFile.message)
                }
            }
        },
        {
            filename,
            directory,
            data,
            name,
            description,
            publiclyOffered,
            isTemplate,
            category
        }
    )
