import gql from 'graphql-tag'

import { ManageableConnectwareLicense, PortalError, PortalErrorType } from '../../../../domain'

import { TypedResponse } from './Response'
import { buildMutationHandler, MutationHandler } from './Builders'

const GENERATE_LICENSE_KEY = gql`
    mutation GenerateLicenseKey($id: ID!) {
        generateConnectwareLicenseKeyForSelfHostedConnectwareAppliance(id: $id) {
            message
            success
            connectwareLicenseKey
        }
    }
`
type GenerateLicenseKeyInput = Pick<ManageableConnectwareLicense, 'id'>

interface GenerateLicenseKeyResponse extends TypedResponse<'GenerateLicenseKey'> {
    readonly success: boolean
    readonly message: string
    readonly connectwareLicenseKey: string
}

export default (
    id: string
): MutationHandler<GenerateLicenseKeyInput, { readonly generateConnectwareLicenseKeyForSelfHostedConnectwareAppliance: GenerateLicenseKeyResponse }, string> =>
    buildMutationHandler(
        {
            document: GENERATE_LICENSE_KEY,
            mapper: ({ data }) => {
                if (!data?.generateConnectwareLicenseKeyForSelfHostedConnectwareAppliance.success) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, 'Unexpected generation response of license key', {
                        ...data?.generateConnectwareLicenseKeyForSelfHostedConnectwareAppliance
                    })
                }
                return data.generateConnectwareLicenseKeyForSelfHostedConnectwareAppliance.connectwareLicenseKey
            }
        },
        {
            id
        }
    )
