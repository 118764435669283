export type Metrics = {
    readonly id: string
    readonly connectwareLicenseId: string
    readonly associatedAccount: string | null
    readonly name: string | null
    readonly received: Date
    readonly date: Date
    readonly numberOfDatapoints: number
    readonly numberOfMessages: number
}

export type MetricsDataInput = {
    readonly metrics: string[]
}

export enum MetricsColumnTypes {
    LICENSE_ID = 'License Id',
    ENCRYPTED = 'ENCRYPTED'
}
