import React, { createContext, FC, useContext } from 'react'

import { PortalError, PortalErrorType } from '../../../../domain'

import { RoutePath } from './RoutePath'

const RoutePathContext = createContext<RoutePath | null>(null)

export const RouteProvider: FC<{ readonly route: RoutePath }> = ({ children, route }) => (
    <RoutePathContext.Provider value={route}>{children}</RoutePathContext.Provider>
)

export const useRoutpath = (): RoutePath => {
    const context = useContext(RoutePathContext)
    if (context === null) {
        throw new PortalError(PortalErrorType.STATE, 'useRoutpath must be used with-in a RouteProvider')
    }
    return context
}
