import gql from 'graphql-tag'

import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

const PERMISSION_GROUPS_BY_USER_GROUPS = gql`
    query PermissionGroupsByUserGroup {
        permissionGroupsByUserGroup {
            userGroup
            roles
        }
    }
`
interface PermissionGroupsByUserGroupResponse extends TypedResponse<'PermissionGroupsByUserGroupResponse'> {
    readonly userGroup: string
    readonly roles: string[]
}

export default (): QueryHandler<void, { readonly permissionGroupsByUserGroup: PermissionGroupsByUserGroupResponse[] }, PermissionGroupsByUserGroupResponse[]> =>
    buildQueryHandler(
        {
            document: PERMISSION_GROUPS_BY_USER_GROUPS,
            mapper: ({ data }) => {
                return data.permissionGroupsByUserGroup
            }
        },
        void 0
    )
