import { IntlShape } from '@formatjs/intl'

import { AppState } from '../../domain'

import { LoggerService } from '../../application/services'
import { UsecaseServices } from '../../application/usecases'

import { PortalWebAppConfig } from '../config'
import { Store } from '../react/hooks'
import {
    ApolloConnectwareApplianceService,
    ApolloConnectwareLicenseService,
    ApolloConnectwareUserService,
    ApolloServicesCatalogService,
    ApolloMetricsService,
    AWSCognitoAuthentication,
    ConfigurationRelayService,
    FormatJSTranslationService,
    LocationNavigationService,
    WebStorage,
    createIntlShape,
    WrappedApolloClient
} from '../services'

export const resolveInjections = (
    initialState: AppState,
    config: PortalWebAppConfig
): [UsecaseServices, IntlShape<string>, WrappedApolloClient, Store<AppState>] => {
    const appStateStore = new Store(initialState)
    const logger: LoggerService = console
    const wrappedApolloClient = new WrappedApolloClient({ ...config.graphQL, getToken: () => appStateStore.getState().token }, logger)
    const client = wrappedApolloClient.client
    const connectwareUserService = new ApolloConnectwareUserService(client)
    const redirectAuthenticationService = new AWSCognitoAuthentication(config.cognito)
    const intl = createIntlShape({ ...config.internationalization, logger })
    return [
        {
            logger,
            configurationService: new ConfigurationRelayService(config.contactInformation, config.appVersion),
            redirectAuthenticationService,
            navigationService: new LocationNavigationService(),
            connectwareLicenseService: new ApolloConnectwareLicenseService(client, config.expiration),
            connectwareApplianceService: new ApolloConnectwareApplianceService(client),
            applicationState: appStateStore,
            translationService: new FormatJSTranslationService(intl),
            persistenceService: new WebStorage(window.localStorage),
            connectwareUserService: connectwareUserService,
            servicesCatalogService: new ApolloServicesCatalogService(client),
            metricsService: new ApolloMetricsService(client)
        },
        intl,
        wrappedApolloClient,
        appStateStore
    ]
}
