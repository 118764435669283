import React, { useState as useReactState } from 'react'
import { Dialog, Button, LinearProgress, DialogActions, DialogTitle, DialogContent, makeStyles } from '@material-ui/core'
import { GRANT_ACCESS_TO_PERMISSION_GROUP, ALL_PERMISSION_GROUPS } from '../../../../graphql'
import Accounts from '../../../../infrastructure/react/components/Accounts'
import { ErrorMessage } from '../../../../infrastructure/react/components/common'
import AvailableLicenses from '../../../../infrastructure/react/components/AvailableLicenses'
import { useMutation } from '@apollo/client'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  dialogEntry: {
    marginTop: `${theme.spacing(3)}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    wordWrap: 'break-word'
  },
  dialogText: {
    width: '100%',
    textAlign: 'left'
  },
  errorText: {
    marginLeft: `${theme.spacing(3)}px`
  },
  icon: {
    marginRight: `${theme.spacing(2)}px`,
    color: theme.palette.accent[500]
  },
  form: {
    width: '100%',
    textAlign: 'center'
  },
  select: {
    textAlign: 'left',
    width: '100%'
  }
}))

const CreateLicenseRoleModal = ({ toggleOpen, open, permissionGroupId, currentConnectwareLicenseIds }) => {
  const [licenses, setLicenses] = useReactState([])
  const [account, setAccount] = useReactState(null)
  const classes = useStyles()

  const [grantAccessToPermissionGroup, { loading, error }] = useMutation(GRANT_ACCESS_TO_PERMISSION_GROUP, {
    variables: {
      permissionGroupId,
      connectwareLicenseIds: licenses
    },
    refetchQueries: [{ query: ALL_PERMISSION_GROUPS }],
    onCompleted: () => handleClose()
  })

  const handleChange = (e) => {
    setLicenses(e.target.value)
  }

  const handleChangeInAccount = (e) => {
    setAccount(e.target.value)
    setLicenses([])
  }

  const handleClose = () => {
    setLicenses([])
    setAccount(null)
    toggleOpen()
  }

  const isDisabled = () => !(Boolean(licenses.length) && Boolean(account))

  return (
    <Dialog fullWidth disableEscapeKeyDown open={open} onClose={handleClose} maxWidth='xs'>
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault()
          grantAccessToPermissionGroup()
        }}
      >
        <DialogTitle>Grant Connectware License Access to Role</DialogTitle>
        <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
        <DialogContent className={classes.dialogContent}>
          <div className={classes.dialogEntry}>
            <Accounts selected={account} handleSelect={handleChangeInAccount} />
          </div>
          <div className={classes.dialogEntry}>
            <AvailableLicenses
              filter={({ id }) => !currentConnectwareLicenseIds.includes(id)}
              handleSelect={handleChange}
              selected={licenses}
              account={account}
            />
          </div>
          <ErrorMessage error={error} />
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={handleClose}>
            Cancel
          </Button>
          <Button color='primary' type='submit' disabled={isDisabled() || loading}>
            Grant
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CreateLicenseRoleModal
