import React, { FC, useMemo } from 'react'
import { isPending, isRejected, usePromise } from 'react-sync-promise'
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core'

import { ValueOf } from '../../../../utils'

import { LicenseClass, PortalError, Translation } from '../../../../domain'

import { FormattedTranslation, useUsecases } from '../../hooks'
import { ErrorMessage } from '../common'

const useStyles = makeStyles((theme) => ({
    root: { display: 'flex', flexWrap: 'wrap', width: '100%' },
    formControl: { margin: `${theme.spacing(1)} 0px`, textAlign: 'left', width: '100%' },
    selectEmpty: { marginTop: theme.spacing(2) }
}))

interface Props {
    readonly valueKey: keyof LicenseClass
    readonly selected: ValueOf<LicenseClass>
    readonly onChange: (licenseClass: LicenseClass) => void
}

const Wrapper: FC = ({ children }) => {
    const classes = useStyles()

    return (
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor="applianceType">
                <FormattedTranslation id={Translation.LICENSE_CLASS} />
            </InputLabel>
            {children}
        </FormControl>
    )
}

export const ConnectwareLicenseClass: FC<Props> = ({ valueKey, selected, onChange }) => {
    const { connectwareLicenseUsecase } = useUsecases()
    const promise = usePromise<LicenseClass[], PortalError>(useMemo(() => connectwareLicenseUsecase.fetchLicenseClasses(), []))

    if (isPending(promise)) {
        return (
            <Wrapper>
                <CircularProgress size={30} />
            </Wrapper>
        )
    }

    if (isRejected(promise)) {
        return (
            <Wrapper>
                <ErrorMessage error={promise.value} />
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <Select
                value={selected}
                inputProps={{ name: 'connectwareLicenseClass', id: 'connectwareLicenseClass' }}
                onChange={({ target: { value } }) => {
                    const match = promise.value.find((l) => l[valueKey] === value)
                    match && onChange(match)
                }}
            >
                {promise.value.map((licenseClass) => (
                    <MenuItem value={licenseClass[valueKey]} key={licenseClass.id}>
                        {licenseClass.name}
                    </MenuItem>
                ))}
            </Select>
        </Wrapper>
    )
}
