import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import IconButton from '@material-ui/core/IconButton'
import HelpIcon from '@material-ui/icons/Help'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Mutation } from '@apollo/client/react/components'
import { ALL_PERMISSION_GROUPS, UPDATE_PERMISSION_GROUP } from '../../../../graphql'
import { ErrorMessage } from '../../../../infrastructure/react/components/common'
import Operation from './Operation'

const validateResource = (resource) => {
  return !/(#.+)|([^/]#)|([^/]\+)|(\+[^/]+)|[ \t]/.test(resource)
}

const getResourceValidationError = (resource) => {
  if (resource.match(/(#.+)|([^/]#)/g)) {
    return 'The resource given contains an invalid hash (#). A hash (#) represents a wildcard of any depth. There can only be one hash (#) and it can only go at the end of a resource. Please amend your resource to fit this specification'
  }

  if (resource.match(/([^/]\+)|(\+[^/]+)/g)) {
    return 'The resource given contains an invalid plus (+). A plus (+) represents a wildcard at one level and cannot be next to other characters that are not forward slashes (/). Please amend your resource to fit this specification'
  }

  if (resource.match(/[ \t]/g)) {
    return 'The resource given contains an invalid space or tab. A resource cannot contain a space or tab anywhere. Please amend your resource to fit this specification'
  }
  return null
}

const styles = (theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  dialogEntry: {
    marginTop: `${theme.spacing(3)}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    wordWrap: 'break-word'
  },
  helperDialogEntry: {
    marginTop: `${theme.spacing(3)}px`,
    display: 'flex',
    width: '100%'
  },
  dialogText: {
    width: '100%',
    textAlign: 'left'
  },
  errorText: {
    marginLeft: `${theme.spacing(3)}px`
  },
  icon: {
    marginRight: `${theme.spacing(2)}px`,
    color: theme.palette.accent[500]
  },
  helperIcon: {
    position: 'relative',
    bottom: '-10px',
    color: theme.palette.accent[500]
  },
  form: {
    width: '100%',
    textAlign: 'center'
  },
  select: {
    textAlign: 'left',
    width: '100%'
  }
})

class Modal extends Component {
  state = {
    url: '',
    operation: '',
    errorMessage: '',
    resourceHelpText: false
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value.trim()
      },
      () => this.handleValidateInput()
    )
  }

  toggleHelpText = (key) => {
    return () => {
      this.setState((props) => ({
        [key]: !props[key]
      }))
    }
  }

  handleValidateInput = () => {
    const { url } = this.state
    if (!validateResource(url)) {
      const errorMessage = getResourceValidationError(url)
      this.setState({ errorMessage })
    } else {
      this.setState({
        errorMessage: ''
      })
    }
  }

  handleClose = () => {
    const { toggleOpen } = this.props
    this.setState(
      {
        url: '',
        operation: '',
        errorMessage: ''
      },
      toggleOpen
    )
  }

  isDisabled = () => {
    const { url, operation, errorMessage } = this.state
    return !(Boolean(url.length) && Boolean(operation.length)) || Boolean(errorMessage.length)
  }

  render() {
    const { classes, open, permissionGroup } = this.props
    const { url, operation, errorMessage, resourceHelpText } = this.state
    return (
      <Mutation
        mutation={UPDATE_PERMISSION_GROUP}
        variables={{
          id: permissionGroup.id,
          name: permissionGroup.name,
          registryPermissions: [
            ...permissionGroup.registryPermissions.map(({ url, operation }) => ({
              url,
              operation
            })),
            {
              url,
              operation
            }
          ]
        }}
        refetchQueries={[{ query: ALL_PERMISSION_GROUPS }]}
      >
        {(updatePermissionGroup, { loading, data = {}, error }) => {
          const { updatePermissionGroup: { success, message } = {} } = data
          return (
            <Dialog fullWidth disableEscapeKeyDown open={open} onClose={this.handleClose} maxWidth='xs'>
              <form
                className={classes.form}
                onSubmit={(e) => {
                  e.preventDefault()
                  updatePermissionGroup()
                }}
              >
                <DialogTitle>Create Registry Permission</DialogTitle>
                <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
                <DialogContent className={classes.dialogContent}>
                  <div className={classes.helperDialogEntry}>
                    <TextField
                      name='url'
                      id='url'
                      label='Resource'
                      className={classes.dialogText}
                      InputProps={{ placeholder: '' }}
                      value={url}
                      autoComplete='off'
                      error={Boolean(errorMessage.length)}
                      helperText={errorMessage}
                      onChange={this.handleChange}
                    />
                    <IconButton
                      onClick={this.toggleHelpText('resourceHelpText')}
                      className={classes.helperIcon}
                      color='primary'
                      aria-label='Help understand resource'
                    >
                      <HelpIcon />
                    </IconButton>
                  </div>
                  {resourceHelpText && (
                    <Typography className={classes.dialogText}>
                      This is the resource path for the Docker image on the Cybus Docker registry. A hash wildcard (#) can be used to give plural permissions at
                      any depth and plus wildcard (+) can be used to give plural permission at certain levels.
                    </Typography>
                  )}
                  <div className={classes.dialogEntry}>
                    <Operation selected={operation} handleSelect={this.handleChange} />
                  </div>
                  <ErrorMessage error={error} />
                </DialogContent>
                <DialogActions>
                  <Button color='primary' onClick={this.handleClose}>
                    Cancel
                  </Button>
                  <Button color='primary' type='submit' disabled={this.isDisabled() || loading}>
                    Create
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
          )
        }}
      </Mutation>
    )
  }
}

export default withStyles(styles)(Modal)
