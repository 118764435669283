import React, { useState } from 'react'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withRouter } from 'react-router-dom'
import Page from '../common/Page'
import { ErrorMessage } from '../../infrastructure/react/components/common'
import Header from './Header'
import Status from './Status'
import Roles from './Roles'
import Protocols from './Protocols'
// import Resources from './Resources'
// import Features from './Features'
import { DuplicateModal } from '../../infrastructure/react/components/ManageLicenses/Duplicate'
import { useLicense } from '../../infrastructure/react/components/License/hooks'
import { isPortalError } from '../../domain'

function ManageLicense({ match: { params: { id } } = {}, history }) {
  const [isInUse, setIsInUse] = useState('')
  const license = useLicense(id)

  if (!license) return <CircularProgress />
  if (isPortalError(license)) return <ErrorMessage error={error} />

  return (
    <Page>
      <Header connectwareLicense={license} isInUse={isInUse} />
      <Divider />
      <Status connectwareLicense={license} setIsInUse={setIsInUse} />
      <Divider />
      <Roles connectwareLicense={license} />
      {/* <Features connectwareLicense={license} />
      <Resources connectwareLicense={license} /> */}
      <Protocols connectwareLicense={license} />
      <DuplicateModal />
    </Page>
  )
}

export default withRouter(ManageLicense)
