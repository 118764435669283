import gql from 'graphql-tag'

import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

const USER_GROUPS = gql`
    query UserGroups {
        userGroups {
            name
        }
    }
`
interface CognitoUserGroupResponse extends TypedResponse<'UserGroups'> {
    readonly name: string
}

export default (): QueryHandler<void, { readonly userGroups: CognitoUserGroupResponse[] }, string[]> =>
    buildQueryHandler(
        {
            document: USER_GROUPS,
            mapper: ({ data }) => {
                return data.userGroups.map(({ name }) => name)
            }
        },
        void 0
    )
