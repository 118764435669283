import gql from 'graphql-tag'

import { SimpleConnectwareLicense } from '../../../../domain'

import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

const ALL_LICENSES_IN_ACCOUNT = gql`
    query AllLicensesInAccount {
        connectwareLicenses {
            id
            connectwareLicenseClass
            name
            description
            expiration
            autoRenewal
        }
    }
`

interface ConnectwareLicenseResponse extends TypedResponse<'ConnectwareLicense'> {
    readonly id: string
    readonly connectwareLicenseClass: string
    readonly name: string
    readonly description: string | null
    readonly expiration: string
    readonly autoRenewal: boolean
}

export default (): QueryHandler<void, { readonly connectwareLicenses: ConnectwareLicenseResponse[] }, SimpleConnectwareLicense[]> =>
    buildQueryHandler(
        {
            document: ALL_LICENSES_IN_ACCOUNT,
            mapper: ({ data }) =>
                data.connectwareLicenses.map(({ id, name, description, expiration, connectwareLicenseClass, autoRenewal }) => ({
                    id,
                    name,
                    description,
                    expiration: new Date(expiration),
                    licenseClass: connectwareLicenseClass,
                    autoRenewal
                }))
        },
        void 0
    )
