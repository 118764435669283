export class Store<S> {
    private state: S
    private listeners: Set<VoidFunction>

    constructor(initialState: S) {
        this.state = initialState
        this.listeners = new Set()
    }

    setState(state: Partial<S>): void {
        this.state = { ...this.state, ...state }
        this.listeners.forEach((l) => l())
    }

    getState(): S {
        return this.state
    }

    subscribe(subscriber: VoidFunction): VoidFunction {
        this.listeners.add(subscriber)
        return () => void this.listeners.delete(subscriber)
    }
}
