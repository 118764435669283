import gql from 'graphql-tag'
import { User } from '../../../../domain'

import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

const LIST_USERS_IN_USER_GROUP = gql`
    query ListUsersInUserGroup($groupName: String!) {
        listUsersInGroup(input: { groupName: $groupName }) {
            userName
            email
            emailVerified
            status
            enabled
            createdAt
        }
    }
`

type ListUsersInGroupInput = { readonly groupName: string }

interface ListUsersInGroupResponse extends TypedResponse<'ListUsersInUserGroup'> {
    readonly userName: string
    readonly createdAt: Date
    readonly enabled: boolean
    readonly status: string
    readonly emailVerified: boolean
    readonly email: string
}

export default (groupName: string): QueryHandler<ListUsersInGroupInput, { readonly listUsersInGroup: ListUsersInGroupResponse[] }, User[]> =>
    buildQueryHandler(
        {
            document: LIST_USERS_IN_USER_GROUP,
            mapper: ({ data }) => {
                return data.listUsersInGroup.map(({ userName, email, emailVerified, status, enabled, createdAt }) => ({
                    userName,
                    email,
                    emailVerified,
                    status,
                    enabled,
                    createdAt
                }))
            }
        },
        { groupName }
    )
