import React, { FC, useState as useReactState } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    makeStyles,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    Typography
} from '@material-ui/core'

import { ManageableConnectwareLicense, PortalError, Translation } from '../../../../../domain'

import { FormattedTranslation, useState, useUsecases } from '../../../hooks'
import { DateField, ErrorMessage, useSelectedRows } from '../../common'
import { cybusAmber } from '../../../colors'

const useStyles = makeStyles((theme) => ({
    dialogContent: { display: 'flex', flexDirection: 'column', width: '100%' },
    dialogEntry: { marginTop: `${theme.spacing(3)}px`, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', wordWrap: 'break-word' },
    dialogText: { width: '100%', textAlign: 'left' },
    errorText: { marginLeft: `${theme.spacing(3)}px` },
    icon: { marginRight: `${theme.spacing(2)}px`, color: ((theme.palette as unknown) as Partial<Record<string, typeof cybusAmber>>)?.accent?.[500] },
    form: { width: '100%', textAlign: 'center' },
    list: { alignSelf: 'baseline' }
}))

export const EditModal: FC = () => {
    const classes = useStyles()

    const { manageConnectwareLicensesUsecase } = useUsecases()
    const isEditing = useState((s) => Boolean(s.manageLicenses?.isBulkEditing))
    const [licenses] = useSelectedRows<ManageableConnectwareLicense>()
    const [expiration, setExpiration] = useReactState<Date | null>(null)
    const [error, setError] = useReactState<PortalError | null>(null)
    const [loading, setLoading] = useReactState(false)

    if (!isEditing) {
        return null
    }

    const update = async (): Promise<void> => {
        if (!expiration) {
            return
        }

        try {
            setLoading(true)
            setError(null)
            await manageConnectwareLicensesUsecase.updateLicenseExpiration(expiration, ...licenses.map(({ id }) => id))
        } catch (e) {
            setError(e)
            return
        } finally {
            setLoading(false)
            handleClose()
        }
    }
    const handleClose = () => {
        setExpiration(null)
        manageConnectwareLicensesUsecase.toggleBulkEdit()
    }

    return (
        <Dialog open fullWidth disableEscapeKeyDown onClose={handleClose} maxWidth="xs">
            <form
                className={classes.form}
                onSubmit={(e) => {
                    e.preventDefault()
                    void update()
                }}
            >
                <DialogTitle>
                    <FormattedTranslation id={Translation.EDIT_LICENSES} />
                </DialogTitle>
                <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.dialogEntry}>
                        <Typography className={classes.dialogText} variant="subtitle1" gutterBottom>
                            <FormattedTranslation id={Translation.LICENSES_EDITING_DESCRIPTION} />
                        </Typography>
                        <List className={classes.list}>
                            {licenses.map(({ id, name, associatedAccount }) => (
                                <ListItem key={id}>
                                    <ListItemText secondary={<Typography variant="body2">{`- ${name}: (${associatedAccount})`}</Typography>} />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <div className={classes.dialogEntry}>
                        <DateField
                            name="expiration"
                            id="expiration"
                            label={<FormattedTranslation id={Translation.EXPIRATION} />}
                            className={classes.dialogText}
                            onChange={(expiration) => setExpiration(expiration)}
                        />
                    </div>
                    {error && <ErrorMessage error={error} />}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        <FormattedTranslation id={Translation.CANCEL} />
                    </Button>
                    <Button color="primary" type="submit" disabled={expiration === null || loading}>
                        <FormattedTranslation id={Translation.UPDATE} />
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
