import { ConfigurationService, ContactInformation } from '../../application/services'

export class ConfigurationRelayService implements ConfigurationService {
    private contactInformation: ContactInformation
    private version: string

    constructor(contactInformation: ContactInformation, appVersion: string) {
        this.contactInformation = contactInformation
        this.version = appVersion
    }

    getContactInformation(): ContactInformation {
        return this.contactInformation
    }

    getAppVersion(): string {
        return this.version
    }
}
