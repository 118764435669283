import { ManageableMetrics, MetricsColumnTypes, PortalError, PortalErrorType, Translation } from '../../../../domain'
import { initialState } from '../../../InitialState'
import { ConnectwareUsecase } from '../Base'

export class MetricsUsecase extends ConnectwareUsecase {
    // Utils

    private getManageMetrics(): ManageableMetrics {
        const { manageMetrics } = this.getState()
        return manageMetrics
    }

    private setManageMetrics(newState: Partial<ManageableMetrics>): void {
        const manageMetrics = this.getManageMetrics()
        this.setState({ manageMetrics: { ...manageMetrics, ...newState } })
    }

    /**
     * @description Read all metrics across all licenses
     */
    async loadAllMetrics(): Promise<void> {
        const { metrics } = this.getManageMetrics()
        if (!metrics) this.setManageMetrics(initialState.manageMetrics)
        try {
            this.setManageMetrics({ metrics: await this.withAuthentication(() => this.metricsService.fetchAllMetrics()) })
        } catch (e) {
            this.setManageMetrics({ metrics: e as PortalError })
        }
    }

    toggleUpload(): void {
        const manageMetrics = this.getManageMetrics()
        this.setManageMetrics({
            isUploading: !manageMetrics.isUploading,
            toBeUploaded: !manageMetrics.toBeUploaded
                ? {
                      id: '',
                      data: { metrics: [] }
                  }
                : null
        })
    }

    async upload(): Promise<void> {
        const { toBeUploaded } = this.getManageMetrics()
        if (!toBeUploaded) {
            throw new PortalError(PortalErrorType.STATE, 'Edited state null')
        }
        await this.withAuthentication(() => this.metricsService.upload(toBeUploaded))
        await this.loadAllMetrics()
    }

    private updateUploadRequest(metricsRequest: Partial<ManageableMetrics['toBeUploaded']>): void {
        const { toBeUploaded } = this.getManageMetrics()
        if (!toBeUploaded) {
            throw new PortalError(PortalErrorType.STATE, 'Edited state null')
        }
        this.setManageMetrics({ toBeUploaded: { ...toBeUploaded, ...metricsRequest } })
    }

    /**
     * @throws `PortalError`
     */
    configureUpload(data: Record<string, string>[]): void {
        if (data.length === 0) {
            throw new PortalError(PortalErrorType.BAD_INPUT, this.translationService.translate(Translation.PORTAL_ERROR_EMTPY_FILE, { format: '.csv' }))
        }
        const headers = Object.keys(data[0])
        if (!headers.includes(MetricsColumnTypes.ENCRYPTED)) {
            throw new PortalError(
                PortalErrorType.CONFIGURATION_ERROR,
                this.translationService.translate(Translation.PORTAL_ERROR_MISSING_COLUMN, { column: MetricsColumnTypes.ENCRYPTED })
            )
        }
        if (!headers.includes(MetricsColumnTypes.LICENSE_ID)) {
            throw new PortalError(
                PortalErrorType.CONFIGURATION_ERROR,
                this.translationService.translate(Translation.PORTAL_ERROR_MISSING_COLUMN, { column: MetricsColumnTypes.LICENSE_ID })
            )
        }
        // map data from file upload to metrics data
        // the id set in the object below is taken from the first item in the array, but all items have the same id
        const metricsData = {
            id: data[0][MetricsColumnTypes.LICENSE_ID],
            data: {
                metrics: data.map(({ [MetricsColumnTypes.ENCRYPTED]: encrpyted }) => encrpyted)
            }
        }
        this.updateUploadRequest(metricsData)
    }
}
