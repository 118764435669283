import gql from 'graphql-tag'
import { UpdateLicenseRequest, UpdateUserLicenseRequest } from '../../../../application/services'

import { ConnectwareLicenseCapabilities, PortalError, PortalErrorType } from '../../../../domain'

import { buildMutationHandler, MutationHandler } from './Builders'
import { TypedResponse } from './Response'

const UPDATE_CONNECTWARE_LICENSE = gql`
    mutation UpdateConnectwareLicense(
        $id: ID!
        $connectwareLicenseClass: String
        $associatedAccount: String
        $name: String
        $description: String
        $expiration: Timestamp
        $autoRenewal: Boolean
        $capabilities: Capabilities
        $archived: Boolean
    ) {
        updateConnectwareLicense(
            input: {
                id: $id
                connectwareLicenseClass: $connectwareLicenseClass
                associatedAccount: $associatedAccount
                name: $name
                description: $description
                expiration: $expiration
                autoRenewal: $autoRenewal
                capabilities: $capabilities
                archived: $archived
            }
        ) {
            success
            message
            connectwareLicense {
                id
                associatedAccount
                connectwareLicenseClass
                name
                expiration
                autoRenewal
                capabilities
            }
        }
    }
`

interface UpdateUserLicenseInput {
    readonly id: string
    readonly name?: string
    readonly description?: string
    readonly connectwareLicenseClass?: string
    readonly associatedAccount?: string
    readonly expiration?: Date
    readonly autoRenewal?: boolean
    readonly capabilities?: ConnectwareLicenseCapabilities
    readonly archived?: boolean
}

interface UpdateLicenseResponse extends TypedResponse<'UpdateLicenseResponse'> {
    readonly success: boolean
    readonly message: string
}

export const updateUserLicense = ({
    name: description,
    id
}: UpdateUserLicenseRequest): MutationHandler<UpdateUserLicenseInput, { readonly updateConnectwareLicense: UpdateLicenseResponse }, void> =>
    buildMutationHandler(
        {
            document: UPDATE_CONNECTWARE_LICENSE,
            mapper: ({ data }) => {
                if (!data?.updateConnectwareLicense.success) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, data?.updateConnectwareLicense.message, { ...data?.updateConnectwareLicense })
                }
            }
        },
        { id, description }
    )

export const updateLicense = ({
    name: licenseName,
    id,
    description,
    licenseClass: connectwareLicenseClass,
    associatedAccount,
    expiration,
    archived
}: UpdateLicenseRequest): MutationHandler<UpdateUserLicenseInput, { readonly updateConnectwareLicense: UpdateLicenseResponse }, void> =>
    buildMutationHandler(
        {
            document: UPDATE_CONNECTWARE_LICENSE,
            mapper: ({ data }) => {
                if (!data?.updateConnectwareLicense.success) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, data?.updateConnectwareLicense.message, { ...data?.updateConnectwareLicense })
                }
            }
        },
        {
            id,
            name: licenseName || '',
            description: description || '',
            connectwareLicenseClass: connectwareLicenseClass || '',
            associatedAccount: associatedAccount || '',
            expiration: expiration || new Date(),
            archived
        }
    )
