import React, { FC } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { Delete, Edit, FileCopy } from '@material-ui/icons'

import { FormattedTranslation, useUsecases } from '../../hooks'
import { useSelectedRows } from '../common/Table'
import { ManageableConnectwareLicense, Translation } from '../../../../domain'

export const Toolbar: FC = () => {
    const { manageConnectwareLicensesUsecase } = useUsecases()
    const [rows] = useSelectedRows<ManageableConnectwareLicense>()

    return (
        <div>
            <Tooltip title={<FormattedTranslation id={Translation.EDIT_SELECTED_LICENSE} />}>
                <span>
                    <IconButton id="edit-button" disabled={rows.length < 2} onClick={() => manageConnectwareLicensesUsecase.toggleBulkEdit()}>
                        <Edit />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title={<FormattedTranslation id={Translation.DUPLICATE_LICENSE} />}>
                <span>
                    <IconButton id="duplicate-button" disabled={rows.length > 1} onClick={() => manageConnectwareLicensesUsecase.toggleDuplication(rows[0])}>
                        <FileCopy />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title={<FormattedTranslation id={Translation.DELETE_LICENSE} />}>
                <IconButton id="delete-button" onClick={() => manageConnectwareLicensesUsecase.toggleDeletion()}>
                    <Delete />
                </IconButton>
            </Tooltip>
        </div>
    )
}
