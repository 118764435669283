import React, { FC } from 'react'
import { CircularProgress } from '@material-ui/core'
import { isPortalError, Translation } from '../../../../domain'
import { useState, useTranslator } from '../../hooks'
import { ErrorMessage, Table } from '../common'
import { formatDate, getToday } from '../utils'
import { UploadButton } from './Upload'

export const Metrics: FC = () => {
    const translator = useTranslator()
    const metrics = useState((s) => s.manageMetrics?.metrics || null)
    if (!metrics) return <CircularProgress />
    if (isPortalError(metrics)) return <ErrorMessage error={metrics} />

    return (
        <Table
            title={translator.formatTranslation(Translation.METRICS_TABLE_TITLE)}
            data={metrics}
            columns={[
                {
                    label: translator.formatTranslation(Translation.ASSOCIATED_ACCOUNT),
                    name: 'associatedAccount'
                },
                {
                    label: translator.formatTranslation(Translation.NAME),
                    name: 'name'
                },
                {
                    label: translator.formatTranslation(Translation.RECEIVED),
                    name: 'received',
                    options: {
                        customBodyRender: (createdAt: Date) => formatDate(createdAt)
                    }
                },
                {
                    label: translator.formatTranslation(Translation.DATE),
                    name: 'date',
                    options: {
                        customBodyRender: (createdAt: Date) => formatDate(createdAt)
                    }
                },
                {
                    label: translator.formatTranslation(Translation.NUMBER_OF_DATAPOINTS),
                    name: 'numberOfDatapoints'
                },
                {
                    label: translator.formatTranslation(Translation.MESSAGES_PER_DAY),
                    name: 'numberOfMessages'
                }
            ]}
            options={{
                selectableRows: 'none',
                filterType: 'dropdown',
                sortOrder: {
                    name: 'date',
                    direction: 'desc'
                },
                download: true,
                downloadOptions: {
                    filename: `metrics_${getToday().toLocaleDateString()}.csv`
                },
                customToolbar: () => <UploadButton />
            }}
        />
    )
}
