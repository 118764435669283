import React, { FC } from 'react'

import { Translation } from '../../../../domain'

import { useTranslator } from '..'
import { useRoutpath } from './RoutePath.context'
import { RoutePath } from './RoutePath'

const titles: Record<RoutePath, Translation> = {
    [RoutePath.HOME]: Translation.HOME_TITLE,
    [RoutePath.ADMIN_LICENSE]: Translation.ADMIN_LICENSE_HEADER_TITLE,
    [RoutePath.ADMIN_LICENSES]: Translation.ADMIN_LICENSES_HEADER_TITLE,
    [RoutePath.APPLIANCES]: Translation.APPLIANCES_HEADER_TITLE,
    [RoutePath.APPLIANCE]: Translation.APPLIANCE_HEADER_TITLE,
    [RoutePath.SERVICES_CATALOG]: Translation.SERVICES_CATALOG_HEADER_TITLE,
    [RoutePath.LICENSES]: Translation.LICENSES_HEADER_TITLE,
    [RoutePath.LICENSE]: Translation.LICENSE_HEADER_TITLE,
    [RoutePath.ADMIN_ROLES]: Translation.ADMIN_ROLES_HEADER_TITLE,
    [RoutePath.ADMIN_ROLE]: Translation.ADMIN_ROLE_HEADER_TITLE,
    [RoutePath.ADMIN_USER_GROUPS]: Translation.ADMIN_USER_GROUPS_HEADER_TITLE,
    [RoutePath.ADMIN_USER_GROUP]: Translation.ADMIN_USER_GROUP_HEADER_TITLE,
    [RoutePath.ADMIN_USERS]: Translation.ADMIN_USERS_TITLE,
    [RoutePath.METRICS]: Translation.METRICS_TITLE
}

const useTitle = (): string => {
    const path = useRoutpath()
    const translator = useTranslator()

    return translator.formatTranslation(titles[path])
}

export const Title: FC = () => {
    const title = useTitle()
    return <>{title}</>
}
