import React, { useState as useReactState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileCopy from '@material-ui/icons/FileCopy'
import Edit from '../../../infrastructure/react/components/ManageLicense/Edit'
import Delete from './Delete'
import { DuplicateModal } from '../../../infrastructure/react/components/ManageLicenses/Duplicate'
import { useState, useUsecases } from '../../../infrastructure/react/hooks'

export const Toolbar = ({ id, connectwareLicense }) => {
  const [remove, setRemove] = useReactState(false)
  const editLicense = useState((s) => s.manageLicenses.editLicense)
  const { manageConnectwareLicensesUsecase } = useUsecases()

  const toggleOpen = (key) => {
    return () => {
      this.setState((prevState) => ({
        [key]: !prevState[key]
      }))
    }
  }
  return (
    <div>
      <Tooltip title={'Edit'}>
        <IconButton onClick={() => manageConnectwareLicensesUsecase.toggleEdit(connectwareLicense)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={'Duplicate'}>
        <IconButton onClick={() => manageConnectwareLicensesUsecase.toggleDuplication(connectwareLicense)}>
          <FileCopy />
        </IconButton>
      </Tooltip>
      <Tooltip title={'Delete'}>
        <IconButton onClick={toggleOpen('remove')}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      {remove ? <Delete id={id} toggleOpen={setRemove(!remove)} /> : false}
      {editLicense ? <Edit connectwareLicense={connectwareLicense} /> : false}
    </div>
  )
}
