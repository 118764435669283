import React, { FC, ReactNode } from 'react'

import { FormattedDate, FormattedMessage, FormattedNumber, IntlProvider, IntlShape, useIntl } from 'react-intl'

import { Translation } from '../../../domain'

export type TranslationProviderProps = {
    /**
     * The service that actually does the translation
     */
    readonly intl: Pick<IntlShape, 'locale' | 'messages' | 'onError'>
}

export const TranslationProvider: FC<TranslationProviderProps> = ({ intl, children }) => (
    <IntlProvider locale={intl.locale} messages={intl.messages} onError={intl.onError}>
        {children}
    </IntlProvider>
)

type FormatTranslationProps = {
    /**
     * The targeted translation
     */
    readonly id: Translation
    /**
     * The values to the translation
     */
    readonly values?: Record<string, ReactNode>
}

/**
 * Translation hook
 */
class Translator {
    private intl: IntlShape

    constructor(intl: IntlShape) {
        this.intl = intl
    }

    formatTranslation(id: FormatTranslationProps['id'], values?: FormatTranslationProps['values']): string {
        return this.intl.formatMessage({ id }, values) as string
    }
}

export const useLocale = (): string => {
    const intl = useIntl()
    return intl.locale
}

export const useTranslator = (): Translator => new Translator(useIntl())

export const FormattedTranslation: FC<FormatTranslationProps> = ({ id, values }: FormatTranslationProps) => <FormattedMessage id={id} values={values} />

export { FormattedNumber, FormattedDate }
