import gql from 'graphql-tag'
import { Role } from '../../../../domain'

import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

const ROLES_BY_USER_GROUP_NAME = gql`
    query RolesByUserGroupName($userGroupName: String!) {
        rolesByUserGroupName(userGroupName: $userGroupName) {
            id
            name
        }
    }
`

interface RolesResponse extends TypedResponse<'Roles'> {
    readonly id: string
    readonly name: string
}

export default (userGroupName: string): QueryHandler<{ readonly userGroupName: string }, { readonly rolesByUserGroupName: RolesResponse[] }, Role[]> =>
    buildQueryHandler(
        {
            document: ROLES_BY_USER_GROUP_NAME,
            mapper: ({ data }) => {
                return data.rolesByUserGroupName.map(({ id, name }) => ({
                    id,
                    name
                }))
            }
        },
        { userGroupName }
    )
