import { simplify } from '../../../utils'

const resolveSearchableValue = (value: unknown): string | null => {
    if (typeof value === 'string') {
        return value
    }

    if (typeof value === 'number') {
        return String(value)
    }

    if (typeof value === 'boolean') {
        return String(value)
    }

    if (value instanceof Date) {
        return value.toString()
    }

    return null
}

export const customSearch = (searchQuery: string, currentRow: unknown[]): boolean => {
    searchQuery = simplify(searchQuery)

    return Boolean(
        currentRow.find((cell) => {
            const value = resolveSearchableValue(cell)
            return Boolean(value && simplify(value).includes(searchQuery))
        })
    )
}
