import { Category, Metadata, ServiceContent, ServicesByCategory } from '../../../../domain'
import { ServiceContentRequest } from '../../../services'
import { ConnectwareUsecase } from '../Base'

export class ServicesCatalogUsecase extends ConnectwareUsecase {
    fetchServiceContent(args: ServiceContentRequest): Promise<ServiceContent> {
        return this.withAuthentication(() => this.servicesCatalogService.fetchServiceContent(args))
    }

    fetchServicesByCategories(): Promise<ServicesByCategory[]> {
        return this.withAuthentication(() => this.servicesCatalogService.fetchServicesByCategories())
    }

    fetchCategories(): Promise<Category[]> {
        return this.withAuthentication(() => this.servicesCatalogService.fetchCategories())
    }
    parseMetadata(args: Pick<ServiceContent, 'data'>): Promise<Metadata> {
        return this.withAuthentication(() => this.servicesCatalogService.parseMetadata(args))
    }
}
