export const cybusBlue = {
    50: '#e0edf2',
    100: '#b3d3dd',
    200: '#80b5c7',
    300: '#4d97b1',
    400: '#2681a0',
    500: '#006b8f',
    600: '#006387',
    700: '#00587c',
    800: '#004e72',
    900: '#003c60',
    A100: '#90cdff',
    A200: '#5db6ff',
    A400: '#2a9fff',
    A700: '#1194ff',
    contrastDefaultColor: 'light'
}

export const cybusAmber = {
    50: '#fff3e0',
    100: '#ffe0b3',
    200: '#ffcc80',
    300: '#ffb84d',
    400: '#ffa826',
    500: '#ff9900',
    600: '#ff9100',
    700: '#ff8600',
    800: '#ff7c00',
    900: '#ff6b00',
    A100: '#ffffff',
    A200: '#fff7f2',
    A400: '#ffd6bf',
    A700: '#ffc6a6',
    contrastDefaultColor: 'dark'
}
