import React, { Context, createContext, FC, useContext, useState } from 'react'
import { PortalError, PortalErrorType } from '../../../../domain'

type State = 'addRoleModalOpen' | 'deleteRoleModalOpen' | false

const ManageRoleStateContext = createContext<State | null>(null)
const ManageRoleDispatchContext = createContext<((state: State) => void) | null>(null)

export const ManageRoleProvider: FC = ({ children }) => {
    const [isModalOpen, setModalOpen] = useState<State>(false)

    return (
        <ManageRoleStateContext.Provider value={isModalOpen}>
            <ManageRoleDispatchContext.Provider value={setModalOpen}>{children}</ManageRoleDispatchContext.Provider>
        </ManageRoleStateContext.Provider>
    )
}

const getContext = <T extends unknown>(context: Context<T | null>): T => {
    const foundContext = useContext(context)

    if (foundContext === null) {
        throw new PortalError(PortalErrorType.STATE, 'ManageRoleContext must be used with-in a ManageRoleProvider')
    }

    return foundContext
}

export const isAddModalOpen = (): boolean => getContext(ManageRoleStateContext) === 'addRoleModalOpen'
export const isDeleteModalOpen = (): boolean => getContext(ManageRoleStateContext) === 'deleteRoleModalOpen'

export const useToggler = (baseState: State): VoidFunction => {
    const state = getContext(ManageRoleStateContext)
    const setter = getContext(ManageRoleDispatchContext)

    return () => setter(state !== baseState ? baseState : false)
}
