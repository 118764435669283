import React from 'react'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { Query } from '@apollo/client/react/components'
import { withRouter } from 'react-router-dom'
import { PERMISSION_GROUP } from '../../graphql'
import Page from '../common/Page'
import { ErrorMessage } from '../../infrastructure/react/components/common'
import Header from './Header'
import Users from './Users'
import ConnectwareLicenses from './ConnectwareLicenses'
import RegistryPermissions from './RegistryPermissions'
import UserGroups from '../../infrastructure/react/components/ManageRole/UserGroups'
import { ManageRoleProvider } from '../../infrastructure/react/components/ManageRole/ManageRole.context'

const styles = (theme) => ({
  smallMargin: {
    marginBottom: theme.spacing(2)
  },
  largeMargin: {
    marginBottom: theme.spacing(4)
  }
})

function ManageRole({ match: { params: { id } } = {}, history, classes }) {
  return (
    <Query query={PERMISSION_GROUP} variables={{ id }}>
      {({ loading, error, data: { permissionGroup } = {} }) => {
        if (loading) return <CircularProgress />
        if (error) return <ErrorMessage error={error} />
        if (!permissionGroup) {
          history.push('/admin-permission-groups')
          return false
        }
        return (
          <ManageRoleProvider>
            <Page>
              <Header permissionGroup={permissionGroup} />
              <Divider classes={{ root: classes.smallMargin }} />
              <div className={classes.largeMargin}>
                <Page>
                  <ConnectwareLicenses permissionGroupId={permissionGroup.id} connectwareLicenses={permissionGroup.connectwareLicenses} />
                  <Users permissionGroupId={permissionGroup.id} users={permissionGroup.users} />
                  <UserGroups id={permissionGroup.id} userGroups={permissionGroup.userGroups} />
                </Page>
              </div>
              <Divider classes={{ root: classes.largeMargin }} />
              <div>
                <Page>
                  <RegistryPermissions permissionGroup={permissionGroup} />
                </Page>
              </div>
            </Page>
          </ManageRoleProvider>
        )
      }}
    </Query>
  )
}

export default withRouter(withStyles(styles)(ManageRole))
