import gql from 'graphql-tag'

import { LicenseClass } from '../../../../domain'

import { TypedResponse } from './Response'
import { buildQueryHandler, QueryHandler } from './Builders'

const GET_LICENSE_CLASSES = gql`
    query LicenseClasses {
        licenseClasses {
            id
            name
        }
    }
`

export interface LicenseClassResponse extends TypedResponse<'LicenseClass'> {
    readonly id: string
    readonly name: string
}

export default (): QueryHandler<void, { readonly licenseClasses: LicenseClassResponse[] }, LicenseClass[]> =>
    buildQueryHandler({ document: GET_LICENSE_CLASSES, mapper: ({ data }) => data.licenseClasses.map(({ id, name }) => ({ id, name })) }, void 0)
