import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Query } from '@apollo/client/react/components'
import { ALL_PERMISSION_GROUPS } from '../../graphql'
import { ErrorMessage } from '../../infrastructure/react/components/common'
import Table from '../common/Table'
import Create from './Create'

class ManageRoles extends Component {
  getColumns = () => {
    return [
      {
        name: 'name',
        label: 'Name',
        options: {
          filter: false,
          sort: true,
          sortDescFirst: false
        }
      },
      {
        name: 'users',
        label: 'Number of Users',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (users) => users.length
        }
      },
      {
        name: 'userGroups',
        label: 'Number of User Groups',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (userGroups) => userGroups.length
        }
      },
      {
        name: 'connectwareLicenses',
        label: 'Number of Connectware Licenses',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (connectwareLicenses) => connectwareLicenses.length
        }
      },
      {
        name: 'registryPermissions',
        label: 'Number of Registry Permissions',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (registryPermissions) => registryPermissions.length
        }
      }
    ]
  }

  getOptions = (data) => {
    return {
      selectableRows: 'none',
      onCellClick: (rowData, { dataIndex }) => {
        this.handleNavigation(`/admin-role/${data[dataIndex].id}`)
      },
      customToolbar: () => <Create />
    }
  }

  handleNavigation = (path) => {
    const { history } = this.props
    history.push(path)
  }

  render() {
    return (
      <Query query={ALL_PERMISSION_GROUPS}>
        {({ loading, error, data: { permissionGroups } = {} }) => {
          if (loading) return <CircularProgress />
          if (error) return <ErrorMessage error={error} />
          const columns = this.getColumns()
          const options = this.getOptions(permissionGroups)
          return <Table data={permissionGroups} columns={columns} options={options} />
        }}
      </Query>
    )
  }
}

export default withRouter(ManageRoles)
