import React, { FC, useMemo, useState } from 'react'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardDatePickerProps } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { formatDate, getDate, getToday, MOMENT_DATE_FORMAT } from '../../utils'

type Props = Omit<KeyboardDatePickerProps, 'value' | 'onChange'> & {
    /**
     * Called only when the parsing result of a date changes
     */
    readonly onChange: (date: Date) => unknown
    readonly initialValue?: Date
}

export const DateField: FC<Props> = ({ initialValue, onChange, ...props }) => {
    const firstValue = useMemo(() => formatDate(initialValue || getToday()), [])
    const [selectedDate, setDate] = useState<MaterialUiPickersDate>(moment())
    const [inputValue, setInputValue] = useState<string | undefined>(firstValue)
    const onDateChange = (date: MaterialUiPickersDate, value: string | null | undefined) => {
        setDate(date)
        setInputValue(value || undefined)
        if (!date) return
        onChange(getDate(date))
    }

    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
                {...props}
                variant="inline"
                placeholder="01.01.2020"
                format={MOMENT_DATE_FORMAT}
                value={selectedDate}
                inputValue={inputValue}
                onChange={onDateChange}
                autoOk={true}
            />
        </MuiPickersUtilsProvider>
    )
}
