import React, { Context, createContext, PropsWithChildren, useContext, useEffect, useMemo, useState as useReactState } from 'react'

import { PortalError, PortalErrorType } from '../../../../domain'

import { Store } from './Store'

/**
 * The stored value
 */
const StoreContext = createContext<Store<unknown> | null>(null)

type Props<S> = { readonly initial: S } | { readonly store: Store<S> }

/**
 * Lighter redux alternative without middlewares or dispatchers
 *
 * @example <StoreProvider initial={{ value: 'initial' }} />
 */
export const StoreProvider = <S extends unknown>({ children, ...props }: PropsWithChildren<Props<S>>): JSX.Element => (
    <StoreContext.Provider value={'initial' in props ? new Store(props.initial) : props.store}>{children}</StoreContext.Provider>
)

const getContext = <T extends unknown>(context: Context<T | null>, caller: typeof Function): T => {
    const foundContext = useContext(context)

    if (foundContext) {
        return foundContext
    }

    try {
        throw new PortalError(PortalErrorType.STATE, 'Must be used with-in a StateProvider')
    } catch (e) {
        Error.captureStackTrace(e, caller)
        throw e
    }
}

/**
 * Compare state changes if you wish to not perform unecessary re-renders
 *
 * @example `(before, after) => before === after` as a equality validation
 * @example `(value) => String(value)` as a hash
 */
export type Comparator<Result> = ((result: Result) => string | number) | ((a: Result, b: Result) => boolean)
export type Selector<State, Result> = (s: State) => Result

const areResultsEqual = <R extends unknown>([a, b]: [R, R], comparator?: Comparator<R>): boolean => {
    if (a === b) {
        return true
    }

    if (!comparator) {
        return false
    }

    const result = comparator(a, b)

    /**
     * If result is boolean, hash method is not used
     */
    if (typeof result === 'boolean') {
        return result
    }

    /**
     * Else, hash method is being used
     */
    return result === comparator(b, a)
}

export const useStoreState = <S extends unknown, R extends unknown = unknown>(selector: Selector<S, R>, comparator?: Comparator<R>): R => {
    /**
     * First retrieve the context
     */
    const store = getContext(StoreContext, (useStoreState as unknown) as typeof Function) as Store<S>

    /**
     * Calulate initial value and set it to the result
     */
    const [currentResult, setResult] = useReactState(useMemo(() => selector(store.getState()), []))

    useEffect(
        () =>
            store.subscribe(() => {
                const newResult = selector(store.getState())

                if (!areResultsEqual([currentResult, newResult], comparator)) {
                    setResult(newResult)
                }
            }),
        [currentResult]
    )

    return currentResult
}

/**
 * This is only for tests, should NOT be used throug-out the code
 */
export const useStoreDispatcher = <S extends unknown>(): ((newState: Partial<S>) => void) => {
    const store = getContext(StoreContext, (useStoreState as unknown) as typeof Function) as Store<S>
    return (s) => store.setState(s)
}
