import gql from 'graphql-tag'

import { ConnectwareAppliance } from '../../../../domain'

import { TypedResponse } from './Response'
import { buildQueryHandler, QueryHandler } from './Builders'

const CONNECTWARE_INSTANCES = gql`
    query SelfHostedConnectwareAppliances {
        selfHostedConnectwareAppliances {
            id
            name
            connectwareLicense {
                id
                name
            }
        }
    }
`

interface ConnectwareLicenseResponse extends TypedResponse<'ConnectwareLicense'> {
    readonly id: string
    readonly name: 'Testing3'
}

interface ConnectwareApplianceResponse extends TypedResponse<'ConnectwareAppliance'> {
    readonly id: string
    readonly name: string
    readonly connectwareLicense: ConnectwareLicenseResponse
}

export default (): QueryHandler<void, { readonly selfHostedConnectwareAppliances: ConnectwareApplianceResponse[] }, ConnectwareAppliance[]> =>
    buildQueryHandler(
        {
            document: CONNECTWARE_INSTANCES,
            mapper: ({ data }) =>
                data.selfHostedConnectwareAppliances.map(({ id, name, connectwareLicense }) => ({
                    id,
                    name,
                    license: { id: connectwareLicense.id, name: connectwareLicense.name }
                }))
        },
        void 0
    )
