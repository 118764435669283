import gql from 'graphql-tag'
import { User } from '../../../../domain'

import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

const LIST_ALL_USERS = gql`
    query AllUsers {
        listUsers {
            userName
            email
            emailVerified
            status
            enabled
            createdAt
        }
    }
`

interface ListUsersInGroupResponse extends TypedResponse<'AllUsers'> {
    readonly userName: string
    readonly createdAt: Date
    readonly enabled: boolean
    readonly status: string
    readonly emailVerified: boolean
    readonly email: string
}

export default (): QueryHandler<void, { readonly listUsers: ListUsersInGroupResponse[] }, User[]> =>
    buildQueryHandler(
        {
            document: LIST_ALL_USERS,
            mapper: ({ data }) => {
                return data.listUsers.map(({ userName, email, emailVerified, status, enabled, createdAt }) => ({
                    userName,
                    email,
                    emailVerified,
                    status,
                    enabled,
                    createdAt
                }))
            }
        },
        void 0
    )
