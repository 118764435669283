import React, { FC } from 'react'
import { Drawer, Hidden, makeStyles, useTheme } from '@material-ui/core'

import { isOpen, useToggler } from './Navigation.context'

import { DrawerContent } from './DrawerContent'

export const WIDTH = 270

const useStyles = makeStyles((theme) => ({ drawer: { [theme.breakpoints.up('lg')]: { width: WIDTH, flexShrink: 0 } }, drawerPaper: { width: WIDTH } }))

export const LeftSideNavigation: FC = () => {
    const classes = useStyles()
    const theme = useTheme()

    const open = isOpen()
    const toggle = useToggler()

    return (
        <nav className={classes.drawer}>
            <Hidden lgDown implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={open}
                    onClose={toggle}
                    classes={{ paper: classes.drawerPaper }}
                >
                    <DrawerContent />
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open>
                    <DrawerContent />
                </Drawer>
            </Hidden>
        </nav>
    )
}
