import gql from 'graphql-tag'

import { MetricsDataInput, PortalError, PortalErrorType } from '../../../../domain'

import { MetricsRequest } from '../../../../application/services'

import { TypedResponse } from './Response'
import { buildMutationHandler, MutationHandler } from './Builders'

const UPLOAD_METRICS = gql`
    mutation AddMetrics($id: ID!, $data: AddMetricsInput!) {
        addMetricsToLicenseById(id: $id, data: $data) {
            success
            message
        }
    }
`

type UploadInput = {
    data: MetricsDataInput
    id: string
}

interface UploadMetricsResponse extends TypedResponse<'UploadMetricsResponse'> {
    readonly success: boolean
    readonly message: string
}

export default ({ id, data }: MetricsRequest): MutationHandler<UploadInput, { readonly addMetricsToLicenseById: UploadMetricsResponse }, void> =>
    buildMutationHandler(
        {
            document: UPLOAD_METRICS,
            mapper: ({ data }) => {
                if (!data?.addMetricsToLicenseById.success) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, data?.addMetricsToLicenseById.message || '')
                }
            }
        },
        {
            id,
            data
        }
    )
