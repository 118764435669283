import { PortalUserSummary, Role, Roles, User, UserAndAdminGroups, UserGroupRoles, UserGroupRolesAndUsers } from '../../../domain'

import { EditMode } from '../../services'
import { ConnectwareUsecase } from './Base'

export class ConnectwareUsersUsecase extends ConnectwareUsecase {
    buildExternalEditUserURL(args: EditMode): URL {
        return this.redirectAuthenticationService.buildEditUserURL(args)
    }

    deleteRole(id: string, userGroups: string[]): Promise<Pick<Roles, 'userGroups'>> {
        return this.withAuthentication(() => this.connectwareUserService.deleteRole({ id, userGroups }))
    }

    updateRole(id: string, userGroups: string[]): Promise<Pick<Roles, 'userGroups'>> {
        return this.withAuthentication(() => this.connectwareUserService.updateRole({ id, userGroups }))
    }

    fetchAccountNames(): Promise<string[]> {
        return this.withAuthentication(() => this.connectwareUserService.fetchAccountNames())
    }

    fetchUserGroups(): Promise<string[]> {
        return this.withAuthentication(() => this.connectwareUserService.fetchUserGroups())
    }

    fetchUserGroupsRoles(): Promise<UserGroupRoles[]> {
        return this.withAuthentication(() => this.connectwareUserService.fetchUserGroupsRoles())
    }

    listUsersInUserGroup(groupName: string): Promise<User[]> {
        return this.withAuthentication(() => this.connectwareUserService.listUsersInUserGroup(groupName))
    }

    listAllUsers(): Promise<User[]> {
        return this.withAuthentication(() => this.connectwareUserService.listAllUsers())
    }

    listUserGroupsRolesAndUsers(): Promise<UserGroupRolesAndUsers[]> {
        return this.withAuthentication(() => this.connectwareUserService.listUserGroupsRolesAndUsers())
    }
    listUserAndAdminGroups(): Promise<UserAndAdminGroups> {
        return this.withAuthentication(() => this.connectwareUserService.listUserAndAdminGroups())
    }
    fetchPortalUserSummary(): Promise<PortalUserSummary> {
        return this.withAuthentication(() => this.connectwareUserService.fetchPortalUserSummary())
    }
    fetchRolesByUserGroupName(name: string): Promise<Role[]> {
        return this.withAuthentication(() => this.connectwareUserService.fetchRolesByUserGroupName(name))
    }
}
