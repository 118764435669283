import React, { FC, ReactNode } from 'react'
import { Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import { Devices, Gavel, VpnKey, Dvr, LowPriority, Group, AccountBox, Home, PieChart } from '@material-ui/icons'
import { Translation } from '../../../../domain'
import { RoutePath, useRedirect, RedirectHook, useTranslator, useIsSuperAdmin } from '../../hooks'

import Logo from './Logo.png'

type ItemProps = {
    readonly icon: ReactNode
    readonly title: Translation
    readonly subTitle?: Translation
    readonly secondaryIcon?: ReactNode
    readonly onClick: (history: RedirectHook) => void
}

const NavigationListItem: FC<ItemProps> = ({ onClick, icon, title, subTitle, secondaryIcon }) => {
    const redirect = useRedirect()
    const intl = useTranslator()

    return (
        <ListItem button onClick={() => onClick(redirect)}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={intl.formatTranslation(title)} secondary={subTitle && intl.formatTranslation(subTitle)} />
            {secondaryIcon}
        </ListItem>
    )
}

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    userSection: {
        marginTop: '-20px',
        marginBottom: '40px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    cybusLogo: { height: '3rem' }
}))

export const DrawerContent: FC = () => {
    const classes = useStyles()
    const r = useRedirect()
    const intl = useTranslator()
    const isSuperAdmin = useIsSuperAdmin()

    return (
        <div>
            <div className={classes.toolbar} />
            <List>
                <div className={classes.userSection} onClick={() => r.redirect(RoutePath.HOME)}>
                    <img alt={intl.formatTranslation(Translation.LOGO_TEXT_DESCRIPTION)} src={Logo} className={classes.cybusLogo} />
                </div>
                <NavigationListItem icon={<Home />} title={Translation.HOME_TITLE} onClick={(r) => r.redirect(RoutePath.HOME)} />
                <Divider />
                <NavigationListItem icon={<VpnKey />} title={Translation.LICENSES_TITLE} onClick={(r) => r.redirect(RoutePath.LICENSES)} />
                <Divider />
                <NavigationListItem icon={<Devices />} title={Translation.SERVICES_CATALOG_TITLE} onClick={(r) => r.redirect(RoutePath.SERVICES_CATALOG)} />
                <Divider />
                <NavigationListItem
                    icon={<Dvr />}
                    title={Translation.APPLIANCES_TITLE}
                    subTitle={Translation.DEPRECATED}
                    onClick={(r) => r.redirect(RoutePath.APPLIANCES)}
                />
            </List>
            <Divider />
            <NavigationListItem icon={<PieChart />} title={Translation.METRICS_TITLE} onClick={(r) => r.redirect(RoutePath.METRICS)} />
            <Divider />
            {isSuperAdmin && (
                <>
                    <List>
                        <NavigationListItem
                            icon={<LowPriority />}
                            title={Translation.MANAGE_LICENSES_TITLE}
                            onClick={(r) => r.redirect(RoutePath.ADMIN_LICENSES)}
                        />
                        <NavigationListItem
                            icon={<Gavel />}
                            title={Translation.MANAGE_ROLES_TITLE}
                            subTitle={Translation.MANAGE_ROLES_SUBTITLE}
                            onClick={(r) => r.redirect(RoutePath.ADMIN_ROLES)}
                        />
                    </List>
                </>
            )}
            <List>
                <NavigationListItem
                    icon={<AccountBox />}
                    title={Translation.MANAGE_USER_GROUPS_TITLE}
                    subTitle={Translation.MANAGE_USER_GROUPS_SUBTITLE}
                    onClick={(r) => r.redirect(RoutePath.ADMIN_USER_GROUPS)}
                />
            </List>
            {isSuperAdmin && (
                <>
                    <List>
                        <NavigationListItem
                            icon={<Group />}
                            title={Translation.MANAGE_USERS_TITLE}
                            subTitle={Translation.MANAGE_USERS_SUBTITLE}
                            onClick={(r) => r.redirect(RoutePath.ADMIN_USERS)}
                        />
                    </List>
                </>
            )}
        </div>
    )
}
