import React, { FC, useEffect } from 'react'

import { TableProvider } from '../common/Table'

import { LicensesTable } from './Table'
import { CreationModal } from './Create'
import { EditModal } from './Edit'
import { DuplicateModal } from './Duplicate'
import { DeleteModal } from './Delete'
import { useUsecases } from '../../hooks'

export const Wrapper: FC = ({ children }) => {
    const { manageConnectwareLicensesUsecase } = useUsecases()
    useEffect(() => {
        // load licenses everytime the component mounts
        // this is not the best, but enables us to fetch the data without having to fully reload the page
        void manageConnectwareLicensesUsecase.loadLicenses()
    }, [])
    return (
        <TableProvider>
            <LicensesTable />
            <CreationModal />
            <EditModal />
            <DuplicateModal />
            <DeleteModal />
            {children}
        </TableProvider>
    )
}
