import { isPortalErrorOfTypes, PortalErrorType } from '../../../domain'

import { AuthenticationUsecase } from '../Authentication'
import { Usecase } from '../Usecase'

export class ConnectwareUsecase extends Usecase {
    /**
     * Service to make sure that calls are authorized before hand, and makes sure that authorization errors are handled
     */
    protected async withAuthentication<T>(callback: () => T | Promise<T>): Promise<T> {
        const authenticationUsecase = this.getUsecase(AuthenticationUsecase)

        try {
            /**
             * Call possibly async method
             */
            return await callback()
        } catch (e) {
            if (isPortalErrorOfTypes(e, PortalErrorType.MISSING_CREDENTIALS)) {
                /**
                 * Make sure user will be authenticated after this
                 */
                authenticationUsecase.logOut()
            }
            throw e
        }
    }
}
