import React, { createRef, FC, ReactNode, useEffect, useState } from 'react'

import { createMuiTheme, MuiThemeProvider, ThemeOptions } from '@material-ui/core/styles'
import MUIDataTable, { MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables'

import { cybusAmber, cybusBlue } from '../../../colors'

/**
 * @todo validate why some undocumented values are being sent
 */
const getStyles = (smallHeader: boolean, options: MUIDataTableOptions & { parentWidth: number; parentPadding: string | number }): ThemeOptions => {
    const styles: ThemeOptions = {
        overrides: {
            // TODO Fix how the width is caclulated (and if it is needed at all)
            MuiPaper: { root: { width: options.parentWidth - (((options as unknown) as Record<string, number>).padding || 0) * 2 || undefined } },
            MuiTableCell: { root: { cursor: options.onCellClick ? 'pointer' : 'inherit', fontSize: '0.8125rem', padding: '4px 24px 4px 24px' } },
            MuiTypography: { body2: { fontSize: '12px', color: 'rgba(0,0,0,0.54)' } },
            MuiTablePagination: { toolbar: { fontSize: '12px', color: 'rgba(0,0,0,0.54)' } }
        },
        palette: { primary: cybusBlue, secondary: cybusAmber }
    }

    // TODO Reconsider prettier/linter rules

    ;(styles.palette as Record<string, unknown>).accent = cybusAmber
    ;(styles.overrides as Record<string, unknown>).MUIInputBase = { root: { fontWeight: 400 } }
    ;(styles.overrides as Record<string, unknown>).MUIDataTableToolbarSelect = { root: { paddingRight: '16px' } }
    ;(styles.overrides as Record<string, unknown>).MUIDataTableHeadCell = {
        root: { padding: '4px 24px 4px 24px', height: '56px', fontSize: '12px', color: 'rgba(0,0,0,0.54)' },
        sortAction: { '& svg': { color: 'rgba(0,0,0,0.87) !important', fontSize: '1.5em' } },
        sortLabelRoot: { height: 'inherit' }
    }
    ;(styles.overrides as Record<string, unknown>).MUIDataTableBodyRow = { root: { height: '48px' } }
    ;(styles.overrides as Record<string, unknown>).MuiToolBar = { root: { fontSize: '12px' } }

    if (smallHeader) {
        // TODO prettier and eslint that force removal of semicolons seems to breaking this
        // eslint-disable-next-line no-extra-semi
        ;(styles.overrides as Record<string, unknown>).MUIDataTableToolbar = {
            root: { padding: '0px' },
            titleText: { padding: '0px 2px', fontSize: '14px' }
        }
    }

    return styles
}

type Props = {
    // TODO Properly type this out
    readonly data: MUIDataTableProps['data']
    readonly columns: MUIDataTableProps['columns']
    readonly options: MUIDataTableOptions
    readonly title?: ReactNode
    readonly smallHeader?: boolean
}

type State = { readonly width: number; readonly padding: number | string }

export const Table: FC<Props> = ({ title = '', smallHeader = false, columns, data, options }) => {
    const [{ width, padding }, setState] = useState<State>({ width: 0, padding: 0 })
    const tableTheme = createMuiTheme(getStyles(smallHeader, { ...options, parentWidth: width, parentPadding: padding }))

    const ref = createRef<HTMLDivElement>()

    /**
     * I am not sure why this is here, if the resizing functionality makes sense
     * It was here before I arrived, once tests are added, it makes sense to review them
     */
    useEffect(() => {
        const parentElement = ref.current?.parentElement
        if (!parentElement) {
            return
        }

        const updateState = () => setState({ width: parentElement.clientWidth, padding: window.getComputedStyle(parentElement).padding })

        window.addEventListener('resize', updateState)
        updateState()
        setTimeout(updateState, 10)

        return () => window.removeEventListener('resize', updateState)
    }, [ref, ref.current])

    return (
        <div>
            <MuiThemeProvider theme={tableTheme}>
                <MUIDataTable
                    title={title}
                    data={data}
                    columns={columns}
                    options={{
                        download: false,
                        filterType: 'checkbox',
                        fixedHeader: true,
                        print: false,
                        /**
                         * @todo validate why stacked is being set
                         */
                        responsive: 'standard',
                        rowsPerPageOptions: [10, 15, 30, 50, 100],
                        rowsPerPage: 100,
                        tableBodyMaxHeight: '80vh', // scrolling only inside table,
                        ...options
                    }}
                />
            </MuiThemeProvider>
        </div>
    )
}
