import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  formControl: {
    margin: `${theme.spacing(1)} 0px`,
    textAlign: 'left',
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
})

const operations = ['Pull', 'Push']

function Operation({ selected, handleSelect, classes }) {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor='applianceType'>Operation</InputLabel>
      <Select
        value={selected}
        onChange={handleSelect}
        inputProps={{
          name: 'operation',
          id: 'operation'
        }}
      >
        {operations.map((value) => (
          <MenuItem value={value} key={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default withStyles(styles)(Operation)
