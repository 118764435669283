import gql from 'graphql-tag'
import { PortalError, PortalErrorType, PortalUserSummary } from '../../../../domain'

import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

const PORTAL_SUMMARY = gql`
    query PortalSummary {
        portalSummary {
            numberOfAppliances
            numberOfLicenses
            numberOfServices
            userGroups {
                name
            }
            adminGroups {
                name
            }
        }
    }
`
interface PortalSummaryResponse extends TypedResponse<'portalSummary'>, PortalUserSummary {}

export default (): QueryHandler<void, { readonly portalSummary: PortalSummaryResponse }, PortalUserSummary> =>
    buildQueryHandler(
        {
            document: PORTAL_SUMMARY,
            mapper: ({ data }) => {
                if (!data.portalSummary) throw new PortalError(PortalErrorType.SERVER_ERROR, 'Unexpected fetching response', data.portalSummary)
                return {
                    numberOfAppliances: data.portalSummary.numberOfAppliances,
                    numberOfLicenses: data.portalSummary.numberOfLicenses,
                    numberOfServices: data.portalSummary.numberOfServices,
                    userGroups: data.portalSummary.userGroups,
                    adminGroups: data.portalSummary.adminGroups
                }
            }
        },
        void 0
    )
