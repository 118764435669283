import gql from 'graphql-tag'
import { UserGroupRolesAndUsers } from '../../../../domain'

import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

const USER_GROUPS_ROLES_AND_USERS = gql`
    query UserGroupsRolesAndUsers {
        listUserGroupsRolesAndUsers {
            name
            amountOfRoles
            amountOfUsers
        }
    }
`
interface UserGroupsRolesAndUsersResponse extends TypedResponse<'UserGroupsRolesAndUsers'> {
    readonly name: string
    readonly amountOfRoles: number
    readonly amountOfUsers: number
}

export default (): QueryHandler<void, { readonly listUserGroupsRolesAndUsers: UserGroupsRolesAndUsersResponse[] }, UserGroupRolesAndUsers[]> =>
    buildQueryHandler(
        {
            document: USER_GROUPS_ROLES_AND_USERS,
            mapper: ({ data }) => {
                return data.listUserGroupsRolesAndUsers.map(({ name, amountOfUsers, amountOfRoles }) => ({ name, amountOfUsers, amountOfRoles }))
            }
        },
        void 0
    )
