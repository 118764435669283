import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Toolbar } from './Toolbar'
import { FormattedTranslation, RoutePath, useIsAdmin, useRedirect } from '../../infrastructure/react/hooks'
import { Button } from '@material-ui/core'
import { Translation } from '../../domain'

const useStyles = makeStyles({
  header: { margin: '10px 0px', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' },
  actions: { display: 'flex' }
})

function Header({ connectwareLicense, isInUse }) {
  const classes = useStyles()
  const isAdmin = useIsAdmin()
  const r = useRedirect()
  return (
    <div className={classes.header}>
      <Typography variant='h5' component='h3'>
        {connectwareLicense.name}
      </Typography>
      <div className={classes.actions}>
        <Toolbar id={connectwareLicense.id} connectwareLicense={connectwareLicense} isInUse={isInUse} />
        {isAdmin && (
          <Button color='primary' variant='contained' onClick={() => r.redirect(RoutePath.LICENSE, connectwareLicense.id)}>
            <FormattedTranslation id={Translation.SHOW_LICENSE} />
          </Button>
        )}
      </div>
    </div>
  )
}

export default Header
