import gql from 'graphql-tag'

import { TypedResponse } from './Response'
import { buildQueryHandler, QueryHandler } from './Builders'

type ConnectwareLicenseInput = { readonly id: string }

const IS_CONNECTWARE_LICENSE_USED = gql`
    query IsConnectwareLicenseUsed($id: ID!) {
        isConnectwareLicenseUsed(id: $id) {
            isInUse
        }
    }
`

interface IsConnectwareLicenseUsedResponse extends TypedResponse<'isConnectwareLicenseUsedResponse'> {
    readonly isInUse: boolean
}

export default (id: string): QueryHandler<ConnectwareLicenseInput, { readonly isConnectwareLicenseUsed: IsConnectwareLicenseUsedResponse }, boolean> =>
    buildQueryHandler({ document: IS_CONNECTWARE_LICENSE_USED, mapper: ({ data }) => data.isConnectwareLicenseUsed.isInUse }, { id })
