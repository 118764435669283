import { PersistenceService } from '../../application/services'

export class WebStorage implements PersistenceService<string, string | null> {
    private storage: Storage

    constructor(storage: Storage) {
        this.storage = storage
    }

    find(id: string): string | null {
        return this.storage.getItem(id)
    }

    delete(id: string): void {
        return this.storage.removeItem(id)
    }

    insert(id: string, value: string): void {
        return this.storage.setItem(id, value)
    }
}
