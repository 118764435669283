import gql from 'graphql-tag'

import { ManageableConnectwareLicense } from '../../../../domain'

import { buildQueryHandler, QueryHandler } from './Builders'
import { TypedResponse } from './Response'

interface PermissionGroupResponse extends TypedResponse<'PermissionGroup'> {
    readonly id: string
    readonly name: string
}

interface ConnectwareLicenseResponse extends TypedResponse<'ConnectwareLicense'> {
    readonly id: string
    readonly associatedAccount: string
    readonly connectwareLicenseClass: string
    readonly name: string
    readonly description: string | null
    readonly licenseKey: string
    readonly expiration: string
    readonly capabilities: { readonly features: Record<string, boolean>; readonly protocols: string[]; readonly resources: Record<string, number> }
    readonly autoRenewal: boolean
    readonly archived: boolean
    readonly permissionGroups: PermissionGroupResponse[]
}

const ALL_LICENSES = gql`
    query ConnectwareLicenses {
        connectwareLicenses {
            id
            associatedAccount
            connectwareLicenseClass
            name
            description
            licenseKey
            expiration
            autoRenewal
            capabilities
            archived
            permissionGroups {
                id
                name
            }
        }
    }
`

export default (): QueryHandler<void, { readonly connectwareLicenses: ConnectwareLicenseResponse[] }, ManageableConnectwareLicense[]> =>
    buildQueryHandler(
        {
            document: ALL_LICENSES,
            mapper: ({ data: { connectwareLicenses } }) =>
                connectwareLicenses.map(
                    ({
                        id,
                        associatedAccount,
                        name,
                        description,
                        licenseKey,
                        autoRenewal,
                        archived,
                        connectwareLicenseClass,
                        expiration,
                        capabilities,
                        permissionGroups
                    }) => ({
                        id,
                        associatedAccount,
                        name,
                        description,
                        licenseKey,
                        autoRenewal,
                        archived,
                        licenseClass: connectwareLicenseClass,
                        roles: permissionGroups.map(({ name, id }) => ({ id, name })),
                        expiration: new Date(expiration),
                        capabilities
                    })
                )
        },
        void 0
    )
