import {
    makeStyles,
    Dialog,
    DialogTitle,
    Tooltip,
    IconButton,
    LinearProgress,
    DialogContent,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Link,
    List,
    ListItem,
    ListItemText,
    Divider,
    Button,
    Collapse,
    DialogActions,
    Paper
} from '@material-ui/core'
import { Edit, Delete, ExpandLess, ExpandMore } from '@material-ui/icons'
import moment from 'moment'
import React, { FC, useMemo, useState as useReactState } from 'react'
import { usePromise, isRejected, isPending, isResolved, WrappedPromise } from 'react-sync-promise'
import { ServiceContent, PortalError, Translation } from '../../../../../domain'
import { FormattedTranslation, useIsAdmin, useIsSuperAdmin, useState, useTranslator, useUsecases } from '../../../hooks'
import { ErrorMessage } from '../../common'

const useStyles = makeStyles((theme) => ({
    detailsHeader: { display: 'flex', justifyContent: 'space-between' },
    header: { display: 'flex', marginBottom: `${theme.spacing(2)}px` },
    icon: { maxWidth: '150px', margin: `${theme.spacing(3)}px`, objectFit: 'scale-down', objectPosition: 'top' },
    headerContent: { display: 'flex', flexDirection: 'column' },
    additionalInfo: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' },
    infoContainer: { display: 'flex', flexDirection: 'column' },
    appContentContainer: { padding: `${theme.spacing(2)}px` },
    appContent: { whiteSpace: 'pre-wrap' }
}))

const createDownloadHandler = (promise: WrappedPromise<ServiceContent | null, PortalError>) => {
    const app = useState((s) => s.manageServices.details)
    const { manageServicesCatalogUsecase } = useUsecases()
    if (!app || !isResolved(promise) || promise.value === null) return undefined
    const value = promise.value
    return () => {
        const blob = new Blob([value.data], { type: value.contentType })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        document.body.appendChild(link)
        link.download = value.filename
        link.href = url
        link.click()
        document.body.removeChild(link)
        manageServicesCatalogUsecase.toggleDetails(null)
    }
}

const useServiceContent = () => {
    const app = useState((s) => s.manageServices.details)
    const isAdmin = useIsAdmin() || false
    const isSuperAdmin = useIsSuperAdmin()
    const { servicesCatalogUsecase } = useUsecases()
    const promise = usePromise<ServiceContent | null, PortalError>(
        useMemo(
            () =>
                app && (isSuperAdmin || (isAdmin && app.availableInLicense))
                    ? servicesCatalogUsecase.fetchServiceContent({ directory: app.directory, filename: app.filename })
                    : null,
            [app]
        )
    )
    return promise
}

const Header: FC = () => {
    const app = useState((s) => s.manageServices.details)
    const { manageServicesCatalogUsecase } = useUsecases()
    const admin = useIsAdmin()
    const isSuperAdmin = useIsSuperAdmin()
    if (!admin || !app) return null
    return (
        <div>
            {(app.allowedToEdit || isSuperAdmin) && (
                <Tooltip title="Edit Service" onClick={() => manageServicesCatalogUsecase.toggleEdit(app)}>
                    <IconButton>
                        <Edit color="primary" />
                    </IconButton>
                </Tooltip>
            )}
            {(app.allowedToDelete || isSuperAdmin) && (
                <Tooltip title="Delete Service" onClick={() => manageServicesCatalogUsecase.toggleDeletion()}>
                    <IconButton>
                        <Delete color="error" />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    )
}

export const Details: FC = () => {
    const classes = useStyles()
    const app = useState((s) => s.manageServices.details)
    const isAdmin = useIsAdmin()
    const isSuperAdmin = useIsSuperAdmin()
    const translator = useTranslator()
    const { manageServicesCatalogUsecase } = useUsecases()
    const [expanded, setExpanded] = useReactState<boolean>(false)
    const promise = useServiceContent()

    const handleDownload = createDownloadHandler(promise)
    if (!app) return null

    const isDisabled = !isSuperAdmin && !app.availableInLicense
    return (
        <Dialog fullWidth disableEscapeKeyDown onClose={() => manageServicesCatalogUsecase.toggleDetails(null)} maxWidth="sm" open={Boolean(app)}>
            <div className={classes.detailsHeader}>
                <DialogTitle>{translator.formatTranslation(Translation.SERVICE_DETAILS_TITLE)}</DialogTitle>
                <Header />
            </div>
            <LinearProgress variant="determinate" value={0} />
            <DialogContent>
                {/* Header section with icon, title and provider */}
                <Card className={classes.header}>
                    <CardMedia
                        className={classes.icon}
                        component="img"
                        src={app.icon ? app.icon : 'https://www.cybus.io/wp-content/uploads/2017/10/blocks.svg'}
                        title={app.name}
                    />
                    <CardContent className={classes.headerContent}>
                        <Typography gutterBottom variant="h4">
                            {app.name}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {app.provider}
                        </Typography>
                    </CardContent>
                </Card>
                {/* Additional information about the app - description, homepage, version */}
                <List>
                    <Typography variant="h6" gutterBottom>
                        <FormattedTranslation id={Translation.SERVICE_DETAILS_DESCRIPTION} />
                    </Typography>
                    <ListItem>
                        <ListItemText secondary={app.description} />
                    </ListItem>
                    <Divider />
                    {isAdmin ? (
                        <Button
                            color="primary"
                            disabled={isDisabled}
                            onClick={() => setExpanded(!expanded)}
                            variant="contained"
                            endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                        >
                            {translator.formatTranslation(Translation.SERVICE_DETAILS_EXPANDED, { expanded })}
                        </Button>
                    ) : null}
                    <ListItem>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            {isPending(promise) ? (
                                <FormattedTranslation id={Translation.LOADING} />
                            ) : isRejected(promise) ? (
                                <ErrorMessage error={promise.value} />
                            ) : (
                                promise.value && (
                                    <Paper className={classes.appContentContainer} elevation={2}>
                                        <Typography paragraph className={classes.appContent}>
                                            {promise.value.data}
                                        </Typography>
                                    </Paper>
                                )
                            )}
                        </Collapse>
                    </ListItem>
                    <Typography gutterBottom variant="h6">
                        <FormattedTranslation id={Translation.SERVICE_DETAILS_ADDITIONAL_INFO_TITLE} />
                    </Typography>
                    <div className={classes.additionalInfo}>
                        <div className={classes.infoContainer}>
                            <ListItem>
                                <ListItemText
                                    primary={<FormattedTranslation id={Translation.SERVICE_DETAILS_ADDITIONAL_INFO_DIRECTORY} />}
                                    secondary={app.directory}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={<FormattedTranslation id={Translation.SERVICE_DETAILS_ADDITIONAL_INFO_VERSION} />}
                                    secondary={app.version}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={<FormattedTranslation id={Translation.SERVICE_DETAILS_ADDITIONAL_INFO_PROVIDER} />}
                                    secondary={app.provider}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={<FormattedTranslation id={Translation.SERVICE_DETAILS_ADDITIONAL_INFO_HOMEPAGE} />}
                                    secondary={
                                        <Link href={app.homepage} target="_blank">
                                            {app.homepage}
                                        </Link>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={<FormattedTranslation id={Translation.SERVICE_DETAILS_ADDITIONAL_INFO_PUBLICLY_OFFERED} />}
                                    secondary={app.publiclyOffered ? 'yes' : 'Only private'}
                                />
                            </ListItem>
                        </div>
                        <div className={classes.infoContainer}>
                            <ListItem>
                                <ListItemText
                                    primary={<FormattedTranslation id={Translation.SERVICE_DETAILS_ADDITIONAL_INFO_FILENAME} />}
                                    secondary={app.filename}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={<FormattedTranslation id={Translation.SERVICE_DETAILS_ADDITIONAL_INFO_UPDATED_AT} />}
                                    secondary={moment(app.updatedAt).format('DD.MM.YYYY')}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={<FormattedTranslation id={Translation.SERVICE_DETAILS_ADDITIONAL_INFO_RELEASED_AT} />}
                                    secondary={moment(app.createdAt).format('DD.MM.YYYY')}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={<FormattedTranslation id={Translation.SERVICE_DETAILS_ADDITIONAL_INFO_DOWNLOADS} />}
                                    secondary={app.downloads}
                                />
                            </ListItem>
                        </div>
                    </div>
                </List>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => manageServicesCatalogUsecase.toggleDetails(null)}>
                    <FormattedTranslation id={Translation.CLOSE_ACTION} />
                </Button>
                {isAdmin && (
                    <Button color="primary" variant="contained" onClick={handleDownload} disabled={isDisabled}>
                        <FormattedTranslation id={Translation.DOWNLOAD_ACTION} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
