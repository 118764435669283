import { ValueOf } from '.'

export const getEnumValue = <E extends Record<string, string | number>>(e: E, v: unknown): ValueOf<E> | null => {
    for (const k in e) {
        if (e[k] === v) {
            return v as ValueOf<E>
        }
    }

    return null
}

export const isEnumOf = <E extends Record<string, string | number>>(e: E, v: unknown): v is ValueOf<E> => Boolean(getEnumValue(e, v))
