import { createIntl as baseCreateIntl, IntlShape } from '@formatjs/intl'

import { PortalError, PortalErrorType, Translation } from '../../domain'

import { LoggerService, TranslationService } from '../../application/services'

export class FormatJSTranslationService implements TranslationService {
    private readonly intl: Pick<IntlShape<string>, 'formatMessage' | 'locale'>

    constructor(intl: FormatJSTranslationService['intl']) {
        this.intl = intl
    }

    translate(id: Translation, values?: Record<string, unknown>): string {
        return this.intl.formatMessage({ id }, values as Record<string, string>)
    }

    get locale(): string {
        return this.intl.locale
    }
}

export type IntlShapeArgs = {
    /**
     * The locale to be used with-in the application
     * Relevant with-in FormattedTranslation
     */
    readonly locale: string

    /**
     * Patterns should be as seen here `https://formatjs.io/docs/intl-messageformat`
     */
    readonly translations: Record<Translation, string>

    /**
     * Called in case of errors/issues translating
     */
    readonly logger: LoggerService
}

export const createIntlShape = ({ translations, locale, logger }: IntlShapeArgs): IntlShape<string> =>
    baseCreateIntl({
        messages: translations,
        locale,
        onError: (e) => logger.error(new PortalError(PortalErrorType.TRANSLATIONS, e.message, { ...e }))
    })
