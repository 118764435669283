import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'
import Modal from './Modal'

const styles = (theme) => ({
  container: {
    margin: '10px 0px 0px 15px'
  }
})

class Create extends Component {
  state = {
    open: false
  }

  toggleOpen = () => {
    this.setState(({ open }) => ({ open: !open }))
  }

  render() {
    const { classes, permissionGroupId } = this.props
    const { open } = this.state
    return (
      <span className={classes.container}>
        <Tooltip title={'Grant User Access to Permission Group'}>
          <IconButton color='primary' onClick={this.toggleOpen}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Modal open={open} toggleOpen={this.toggleOpen} permissionGroupId={permissionGroupId} />
      </span>
    )
  }
}

export default withStyles(styles)(Create)
