import { ServiceContentRequest, ServiceRequest, ServiceDeletionRequest, ServicesCatalogService, ParsedMetadataRequest } from '../../../application/services'
import { Category, Metadata, ServiceContent, ServicesByCategory } from '../../../domain'

import { ApolloConnectwareService } from './Connectware'

import buildServicesByCategories from './handlers/ServicesByCategories'
import buildServiceContent from './handlers/ServiceContent'
import buildCategories from './handlers/Categories'
import buildUpdateService from './handlers/UpdateService'
import buildCreateService from './handlers/CreateService'
import buildDeleteService from './handlers/DeleteService'
import buildAllowedDirectories from './handlers/AllowedDirectories'
import buildParseMetadata from './handlers/ParseMetadata'
export class ApolloServicesCatalogService extends ApolloConnectwareService implements ServicesCatalogService {
    async deleteService(request: ServiceDeletionRequest): Promise<void> {
        await this.mutate(buildDeleteService(request))
    }

    async createService(request: ServiceRequest): Promise<void> {
        await this.mutate(buildCreateService(request))
    }

    async updateService(request: ServiceRequest): Promise<void> {
        await this.mutate(buildUpdateService(request))
    }

    fetchCategories(): Promise<Category[]> {
        return this.query(buildCategories())
    }

    fetchServiceContent(args: ServiceContentRequest): Promise<ServiceContent> {
        return this.query(buildServiceContent(args))
    }

    fetchServicesByCategories(): Promise<ServicesByCategory[]> {
        return this.query(buildServicesByCategories())
    }
    fetchAllowedDirectories(): Promise<string[]> {
        return this.query(buildAllowedDirectories())
    }

    parseMetadata(request: ParsedMetadataRequest): Promise<Metadata> {
        return this.query(buildParseMetadata(request))
    }
}
