import {
    ApplianceConnectwareLicense,
    SimpleConnectwareLicense,
    ConnectwareLicenseExpirationConfiguration,
    LicenseClass,
    DetailedConnectwareLicense
} from '../../../../domain'

import { ConnectwareUsecase } from '../Base'

export class ConnectwareLicenseUsecase extends ConnectwareUsecase {
    /**
     * @description fetches if the given connectware license is in use or not
     */
    fetchUsage(id: string): Promise<boolean> {
        return this.withAuthentication(() => this.connectwareLicenseService.fetchUsage(id))
    }

    /**
     * @description fetches license classes
     */
    fetchLicenseClasses(): Promise<LicenseClass[]> {
        return this.withAuthentication(() => this.connectwareLicenseService.fetchLicenseClasses())
    }

    fetchExpirationConfiguration(): Promise<ConnectwareLicenseExpirationConfiguration> {
        return this.connectwareLicenseService.fetchExpirationConfiguration()
    }

    fetchAvailableLicenses(associatedAccount: string | null): Promise<ApplianceConnectwareLicense[]> {
        return this.withAuthentication(() => this.connectwareLicenseService.fetchAvailableLicenses(associatedAccount))
    }

    fetchManageableConnectwareLicense(id: string): Promise<DetailedConnectwareLicense> {
        return this.withAuthentication(() => this.connectwareLicenseService.fetchManageableConnectwareLicense(id))
    }

    findAllLicensesInAccount(): Promise<SimpleConnectwareLicense[]> {
        return this.withAuthentication(() => this.connectwareLicenseService.findAllLicensesInAccount())
    }
}
