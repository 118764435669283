import { Button, CircularProgress } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import { usePromise, isPending, isRejected } from 'react-sync-promise'
import { PortalError, Translation, User } from '../../../../domain'
import { useIsSuperAdmin, useTranslator, useUsecases } from '../../hooks'
import { ErrorMessage, Table } from '../common'
import { formatDate } from '../utils'

const ManageUsers: FC = () => {
    const translator = useTranslator()
    const { connectwareUsersUsecase } = useUsecases()
    const isSuperAdmin = useIsSuperAdmin()
    const promise = usePromise<User[], PortalError>(useMemo(() => connectwareUsersUsecase.listAllUsers(), []))

    if (isPending(promise)) return <CircularProgress />
    if (isRejected(promise)) {
        return <ErrorMessage error={promise.value} />
    }

    const customToolbar = () => {
        if (!isSuperAdmin) return null

        const url = connectwareUsersUsecase.buildExternalEditUserURL('USERS')

        // TODO Translate button
        return (
            <Button color="primary" variant="contained" href={url.toString()} target="_blank">
                Edit in AWS Cognito
            </Button>
        )
    }

    return (
        <Table
            title={translator.formatTranslation(Translation.MANAGE_USERS_TITLE)}
            data={promise.value}
            columns={[
                {
                    label: translator.formatTranslation(Translation.NAME),
                    name: 'userName'
                },
                {
                    label: translator.formatTranslation(Translation.EMAIL),
                    name: 'email'
                },
                {
                    label: translator.formatTranslation(Translation.ACTIVE),
                    name: 'enabled',
                    options: {
                        customBodyRender: (enabled: boolean) => translator.formatTranslation(Translation.YES_NO, { value: enabled })
                    }
                },
                {
                    label: translator.formatTranslation(Translation.STATUS),
                    name: 'status'
                },
                {
                    label: translator.formatTranslation(Translation.CREATED_AT),
                    name: 'createdAt',
                    options: {
                        customBodyRender: (createdAt: Date) => formatDate(createdAt)
                    }
                }
            ]}
            options={{
                filterType: 'dropdown',
                sortOrder: { name: 'userName', direction: 'asc' },
                // TODO: rows should be selectable to delete user from group
                selectableRows: 'none',
                customToolbar,
                textLabels: {
                    body: {
                        // TODO: replace with translation
                        noMatch: 'No users found'
                    }
                }
            }}
        />
    )
}

export default ManageUsers
