import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import { Mutation } from '@apollo/client/react/components'
import { LICENSE, UPDATE_CONNECTWARE_LICENSE } from '../../../../graphql'
import { ErrorMessage } from '../../../../infrastructure/react/components/common'
import Protocols from './Protocols'

const styles = (theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  dialogEntry: {
    marginTop: `${theme.spacing(3)}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    wordWrap: 'break-word'
  },
  dialogText: {
    width: '100%',
    textAlign: 'left'
  },
  errorText: {
    marginLeft: `${theme.spacing(3)}px`
  },
  icon: {
    marginRight: `${theme.spacing(2)}px`,
    color: theme.palette.accent[500]
  },
  form: {
    width: '100%',
    textAlign: 'center'
  },
  select: {
    textAlign: 'left',
    width: '100%'
  }
})

class Modal extends Component {
  state = {
    selectedProtocols: []
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleChangeInLicenses = (e) => {
    const { options } = e.target
    const selectedProtocols = []
    for (const option of options) {
      if (option.selected) {
        selectedProtocols.push(options.value)
      }
    }
    this.setState({
      [e.target.name]: selectedProtocols
    })
  }

  handleChangeInAccount = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      selectedProtocols: []
    })
  }

  handleClose = () => {
    const { toggleOpen } = this.props
    this.setState({ selectedProtocols: [] }, toggleOpen)
  }

  isDisabled = () => {
    const { selectedProtocols } = this.state
    return !Boolean(selectedProtocols.length)
  }

  render() {
    const { classes, connectwareLicenseId, capabilities, currentProtocols } = this.props

    const { selectedProtocols } = this.state

    return (
      <Mutation
        mutation={UPDATE_CONNECTWARE_LICENSE}
        variables={{
          id: connectwareLicenseId,
          capabilities: {
            ...capabilities,
            protocols: currentProtocols.concat(selectedProtocols)
          }
        }}
        refetchQueries={[{ query: LICENSE, variables: { id: connectwareLicenseId } }]}
      >
        {(updateConnectwareLicense, { loading, data = {}, error }) => {
          const { updateConnectwareLicense: { success, message } = {} } = data
          return (
            <Dialog fullWidth disableEscapeKeyDown open onClose={this.handleClose} maxWidth='xs'>
              <form
                className={classes.form}
                onSubmit={(e) => {
                  e.preventDefault()
                  updateConnectwareLicense()
                }}
              >
                <DialogTitle>Grant Connectware License Access to Protocols</DialogTitle>
                <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
                <DialogContent className={classes.dialogContent}>
                  <div className={classes.dialogEntry}>
                    <Protocols filter={(name) => !currentProtocols.includes(name)} selected={selectedProtocols} handleSelect={this.handleChange} />
                  </div>
                  <ErrorMessage error={error} />
                </DialogContent>
                <DialogActions>
                  <Button color='primary' onClick={this.handleClose}>
                    Cancel
                  </Button>
                  <Button color='primary' type='submit' disabled={this.isDisabled() || loading}>
                    Grant
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
          )
        }}
      </Mutation>
    )
  }
}

export default withStyles(styles)(Modal)
