import { ConnectwareAccount, ManageableConnectwareLicense, PortalError, ConnectwareAppliance, Metrics } from '.'
/**
 * @todo domain code should not read from application code, this entities need to be moved to the domain
 * For example `Appliances['toBeCreated']` only references domain code
 */
import { MetricsRequest, ServiceDeletionRequest, ServiceRequest } from '../application/services'

import { NullableValues } from '../utils'
import { isPortalError } from './PortalError'
import { Category, ServiceMetaData, ServicesByCategory } from './ServicesCatalog'

type LicenseToBeCreatedState = Pick<ManageableConnectwareLicense, 'associatedAccount' | 'licenseClass' | 'name' | 'expiration' | 'autoRenewal'>

type LicenseToBeDuplicatedState = Pick<
    ManageableConnectwareLicense,
    'associatedAccount' | 'licenseClass' | 'name' | 'expiration' | 'autoRenewal' | 'id' | 'roles'
> & { readonly duplicates: number }

type LicenseToBeUpdatedState = Pick<
    ManageableConnectwareLicense,
    'id' | 'associatedAccount' | 'licenseClass' | 'name' | 'description' | 'expiration' | 'archived'
>

/**
 * Used in the manageable licenses
 */
export interface ManageableConnectwareLicenses {
    /**
     * If the licenses are loaded, they will either an array or the portal error
     */
    readonly licenses: ManageableConnectwareLicense[] | PortalError | null

    /**
     * The license to be created/duplicated/edited/removed, before is submitted to the backend, is kept here
     */
    readonly toBeCreated: NullableValues<LicenseToBeCreatedState, 'expiration'> | null
    readonly toBeDuplicated: NullableValues<LicenseToBeDuplicatedState, 'expiration'> | null
    readonly toBeEdited: LicenseToBeUpdatedState | null
    readonly isBulkEditing: boolean
    readonly isBulkRemoving: boolean
    readonly downloadLicenseKey: boolean
    readonly downloadLicenseFile: boolean
    readonly editLicenseAsUser: boolean
    readonly editLicense: boolean
}

export interface Appliances {
    readonly appliances: ConnectwareAppliance[] | PortalError | null
    readonly toBeCreated: { name: ConnectwareAppliance['name']; license: ConnectwareAppliance['license']['id'] } | null
    readonly isDeleting: boolean
}

export interface ManageableServices {
    readonly services: ServicesByCategory[] | PortalError | null
    readonly categories: Category[] | PortalError | null
    readonly expanded: Record<string, boolean>
    readonly details: ServiceMetaData | null
    readonly toBeEdited: ServiceRequest | null
    readonly toBeCreated: ServiceRequest | null
    readonly toBeDeleted: ServiceDeletionRequest | null
    readonly allowedDirectories: string[] | PortalError | null
}

export interface ManageableMetrics {
    readonly metrics: Metrics[] | PortalError | null
    readonly isUploading: boolean
    readonly toBeUploaded: MetricsRequest | null
}

export interface AppState {
    /**
     * Information relevant to manage licenses tab
     */
    readonly manageLicenses: ManageableConnectwareLicenses

    /**
     * Information relevant to the appliances tabs
     */
    readonly appliances: Appliances

    /**
     * User authentication and information
     */
    readonly token: string | null
    readonly account: ConnectwareAccount | PortalError | null

    /**
     * Information relevant to the services catalog tab
     */
    readonly manageServices: ManageableServices

    readonly manageMetrics: ManageableMetrics
}

export const isAuthenticated = (auth: AppState['account']): auth is ConnectwareAccount => Boolean(auth && !isPortalError(auth))
