import React, { FC, useEffect, useState as useReactState } from 'react'

import {
    Dialog,
    DialogTitle,
    LinearProgress,
    DialogContent,
    Typography,
    FormControl,
    TextareaAutosize,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    DialogActions,
    Button,
    CircularProgress,
    TextField
} from '@material-ui/core'
import { CloudUpload } from '@material-ui/icons'

import { isPortalError, PortalError, Translation } from '../../../../../domain'

import { ServiceRequest } from '../../../../../application/services'

import { FormattedTranslation, useState, useTranslator, useUsecases } from '../../../hooks'
import { ErrorMessage, FileUploadField } from '../../common'

import { useStyles } from './Styles'
import { DirectoryField, DirectoryLabel } from './Directory'

type Props = {
    /**
     * Labels
     */
    /**
     * @deprecated
     * @todo translate and use Translation key
     */
    readonly title: string
    /**
     * @deprecated
     * @todo translate and use Translation key
     */
    readonly buttonText: string

    /**
     * Data
     */
    readonly data: ServiceRequest
    readonly isDirectoryValid?: boolean

    /**
     * Behaviour
     */
    readonly isDirectoryEditable?: boolean
    readonly disabled: boolean

    /**
     * Event handlers
     */
    readonly onChange: (
        /**
         * @todo think of an easier way to represent this
         */
        e: Partial<Pick<ServiceRequest, 'directory' | 'filename' | 'name' | 'description' | 'data' | 'isTemplate' | 'publiclyOffered' | 'category'>>
    ) => void
    readonly onClose: () => void
    readonly onSubmit: () => Promise<void>
    readonly edit?: boolean
}

export const Upload: FC<Props> = ({
    title,
    buttonText,
    data: { directory, filename, description, isTemplate, publiclyOffered, category, name, data },
    isDirectoryEditable = false,
    isDirectoryValid = true,
    disabled,
    onChange,
    onClose,
    onSubmit,
    edit
}) => {
    const classes = useStyles()
    const translator = useTranslator()
    const { manageServicesCatalogUsecase } = useUsecases()
    const categories = useState((s) => s.manageServices.categories)
    const defaultDirectories = useState((s) => s.manageServices.allowedDirectories)

    const [loading, setLoading] = useReactState(false)
    const [error, setError] = useReactState<PortalError | null>(null)

    useEffect(() => {
        void manageServicesCatalogUsecase.parseMetadata(data)
    }, [data])

    const handleSubmit = async (): Promise<void> => {
        setLoading(true)
        let error: PortalError | null = null
        try {
            await onSubmit()
        } catch (e) {
            error = e as PortalError
        } finally {
            setLoading(false)
            setError(error)
            if (!error) {
                onClose()
            }
        }
    }

    return (
        <Dialog maxWidth="sm" disableBackdropClick fullWidth disableEscapeKeyDown open>
            <form
                className={classes.uploadForm}
                onSubmit={(e) => {
                    e.preventDefault()
                    void handleSubmit()
                }}
            >
                <DialogTitle>{title}</DialogTitle>
                <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
                <DialogContent className={classes.uploadDialogContent}>
                    <div className={classes.dialogEntry}>
                        <Typography gutterBottom variant="subtitle1">
                            <FormattedTranslation id={Translation.UPLOAD_DIALOG_SELECT_FILE} />
                        </Typography>
                        <div className={classes.uploadDialogEntry}>
                            <CloudUpload className={classes.uploadIcon} />
                            <FileUploadField
                                id="file"
                                disableUnderline
                                onChange={(event) => {
                                    if (isPortalError(event)) {
                                        setError(event)
                                        return
                                    }
                                    setError(null)
                                    onChange({
                                        filename: !isDirectoryEditable ? filename : event.name,
                                        data: Buffer.from(event.result).toString('base64')
                                    })
                                }}
                            />
                        </div>
                    </div>
                    {(data || edit) && (
                        <>
                            <div className={classes.dialogEntry}>
                                <Typography gutterBottom variant="subtitle1">
                                    <FormattedTranslation id={Translation.UPLOAD_DIALOG_NAME} />
                                </Typography>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        name="name"
                                        id="name"
                                        InputProps={{ placeholder: '' }}
                                        value={name}
                                        onChange={({ target: { value: name } }) => onChange({ name })}
                                    />
                                </FormControl>
                            </div>

                            <div className={classes.dialogEntry}>
                                <Typography gutterBottom variant="subtitle1">
                                    <FormattedTranslation id={Translation.UPLOAD_DIALOG_DESCRIPTION} />
                                </Typography>
                                <FormControl className={classes.formControl}>
                                    <TextareaAutosize
                                        name="description"
                                        id="description"
                                        placeholder={translator.formatTranslation(Translation.UPLOAD_DIALOG_DESCRIPTION_PLACEHOLDER)}
                                        rowsMin={7}
                                        onChange={({ target: { value } }) => onChange({ description: value })}
                                        defaultValue={description}
                                    />
                                </FormControl>
                            </div>
                            <div className={classes.dialogEntry}>
                                <Typography gutterBottom variant="subtitle1">
                                    <FormattedTranslation id={Translation.UPLOAD_DIALOG_SELECT_CATEGORY} />
                                </Typography>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="category">{translator.formatTranslation(Translation.UPLOAD_DIALOG_SELECT_CATEGORY_LABEL)}</InputLabel>
                                    {isPortalError(categories) ? (
                                        <ErrorMessage error={categories} />
                                    ) : (
                                        <Select
                                            value={category}
                                            onChange={({ target: { value } }) => onChange({ category: value as string })}
                                            inputProps={{ name: 'category', id: 'category' }}
                                        >
                                            {categories
                                                ? categories.map(({ name }) => (
                                                      <MenuItem value={name} key={name}>
                                                          {name}
                                                      </MenuItem>
                                                  ))
                                                : null}
                                        </Select>
                                    )}
                                </FormControl>
                            </div>

                            {!isDirectoryEditable ? (
                                <DirectoryLabel directory={directory} />
                            ) : defaultDirectories === null ? (
                                <CircularProgress />
                            ) : isPortalError(defaultDirectories) ? (
                                <ErrorMessage error={defaultDirectories} />
                            ) : defaultDirectories.length === 0 ? (
                                <DirectoryLabel noEntries />
                            ) : (
                                <DirectoryField
                                    directory={directory}
                                    defaultDirectories={defaultDirectories}
                                    isValid={isDirectoryValid}
                                    onChange={(directory) => {
                                        onChange({ directory: directory })
                                    }}
                                    onReady={() => {
                                        onChange({ directory: defaultDirectories[0] })
                                    }}
                                />
                            )}

                            <div className={classes.dialogEntry}>
                                <Typography gutterBottom variant="subtitle1">
                                    <FormattedTranslation id={Translation.SERVICE_DETAILS_ADDITIONAL_INFO_OPTIONAL_TITLE} />
                                </Typography>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="publiclyOffered"
                                                color="primary"
                                                checked={publiclyOffered}
                                                onChange={() => onChange({ publiclyOffered: !publiclyOffered })}
                                                inputProps={{
                                                    'aria-label': translator.formatTranslation(
                                                        Translation.SERVICE_DETAILS_ADDITIONAL_INFO_PUBLICLY_OFFERED_ARIA_LABEL
                                                    )
                                                }}
                                            />
                                        }
                                        label={<FormattedTranslation id={Translation.SERVICE_DETAILS_ADDITIONAL_INFO_PUBLICLY_OFFERED} />}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="template"
                                                color="primary"
                                                checked={isTemplate}
                                                onChange={() => onChange({ isTemplate: !isTemplate })}
                                                inputProps={{
                                                    'aria-label': translator.formatTranslation(Translation.SERVICE_DETAILS_ADDITIONAL_INFO_TEMPLATE_ARIA_LABEL)
                                                }}
                                            />
                                        }
                                        label={<FormattedTranslation id={Translation.SERVICE_DETAILS_ADDITIONAL_INFO_TEMPLATE} />}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {error && <ErrorMessage error={error} />}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onClose}>
                        {translator.formatTranslation(Translation.CANCEL)}
                    </Button>
                    <Button color="primary" type="submit" disabled={disabled || loading}>
                        {buttonText}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
