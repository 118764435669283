import {
    Button,
    CircularProgress,
    colors,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    LinearProgress,
    makeStyles,
    MenuItem,
    Select
} from '@material-ui/core'
import React, { ChangeEvent, FC, useMemo, useState } from 'react'
import { isPending, isRejected, usePromise } from 'react-sync-promise'
import { PortalError, Translation } from '../../../../../../domain'
import { FormattedTranslation, useUsecases } from '../../../../hooks'
import { ErrorMessage } from '../../../common'
import { isAddModalOpen, useToggler } from '../../ManageRole.context'

const useStyles = makeStyles((theme) => ({
    root: { display: 'flex', flexWrap: 'wrap', width: '100%' },
    form: { width: '100%', textAlign: 'center' },
    formControl: { margin: `${theme.spacing(1)} 0px`, textAlign: 'left', width: '100%' },
    selectEmpty: { marginTop: theme.spacing(2) },
    selected: { fontWeight: 600 },
    recommended: { color: colors.blue[500] },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    dialogEntry: {
        marginTop: `${theme.spacing(3)}px`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        wordWrap: 'break-word'
    }
}))

interface Props {
    id: string
    currentUserGroups: string[]
    onUpdate: (userGroupNames: string[]) => void
}

const AddUserGroupDialog: FC<Props> = ({ id, currentUserGroups, onUpdate }) => {
    const [selected, setSelected] = useState<string[]>([])
    const classes = useStyles()
    const toggle = useToggler('addRoleModalOpen')
    const open = isAddModalOpen()
    const { connectwareUsersUsecase } = useUsecases()
    const promise = usePromise<string[], PortalError>(useMemo(() => connectwareUsersUsecase.fetchUserGroups(), []))

    if (isRejected(promise)) {
        return <ErrorMessage error={promise.value} />
    }

    const handleChange = (e: ChangeEvent<{ value: unknown }>): void => {
        setSelected(e.target.value as string[])
    }

    const updateUserGroups = async (): Promise<void> => {
        // TODO: maybe introduce own usecase for connectwareRoles
        const result = await connectwareUsersUsecase.updateRole(id, selected)
        onUpdate(result.userGroups)
        toggle()
    }

    return (
        <Dialog fullWidth disableEscapeKeyDown open={open} onClose={toggle} maxWidth={'xs'}>
            <DialogTitle>
                <FormattedTranslation id={Translation.ADD_USER_GROUP_TITLE} />
            </DialogTitle>
            <LinearProgress variant={'indeterminate'} />
            <form
                className={classes.form}
                onSubmit={(e) => {
                    e.preventDefault()
                    void updateUserGroups()
                }}
            >
                <DialogContent className={classes.dialogContent}>
                    {/* {promise.value} */}
                    <div className={classes.dialogEntry}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="userGroups">
                                <FormattedTranslation id={Translation.USER_GROUPS_FORM_LABEL} />
                            </InputLabel>
                            {isPending(promise) ? (
                                <CircularProgress />
                            ) : (
                                <Select multiple value={selected} onChange={handleChange} inputProps={{ name: 'userGroups' }}>
                                    {promise.value.map((userGroup) => {
                                        if (currentUserGroups.includes(userGroup)) return
                                        return (
                                            <MenuItem
                                                value={userGroup}
                                                key={userGroup}
                                                classes={selected.includes(userGroup) ? { root: classes.selected } : {}}
                                            >
                                                {userGroup}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            )}
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color={'primary'} onClick={toggle}>
                        <FormattedTranslation id={Translation.CANCEL} />
                    </Button>
                    <Button color={'primary'} type={'submit'}>
                        <FormattedTranslation id={Translation.GRANT} />
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddUserGroupDialog
