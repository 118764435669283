import React, { FC } from 'react'
import { makeStyles, Button, Typography, CircularProgress } from '@material-ui/core'

import { isPortalError, Translation } from '../../../../domain'

import { FormattedTranslation, RoutePath, useIsSuperAdmin, useRedirect } from '../../hooks'
import Toolbar from './Toolbar'
import { ErrorMessage } from '../common'
import { useLicense } from './hooks'

const useStyles = makeStyles(() => ({
    header: { margin: '10px 0px', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' },
    actions: { display: 'flex' }
}))

const Header: FC<{ id: string }> = ({ id }) => {
    const classes = useStyles()
    const isAdmin = useIsSuperAdmin()
    const r = useRedirect()
    const license = useLicense(id)

    if (!license) {
        return <CircularProgress />
    }

    if (isPortalError(license)) {
        return <ErrorMessage error={license} />
    }

    return (
        <div className={classes.header}>
            <Typography variant="h5" component="h3" data-testid="licenseName">
                {license.name}
            </Typography>
            <div className={classes.actions}>
                <Toolbar />
                {isAdmin && (
                    <Button color="primary" variant="contained" onClick={() => r.redirect(RoutePath.ADMIN_LICENSE, id)}>
                        <FormattedTranslation id={Translation.MANAGE_LICENSE_TITLE} />
                    </Button>
                )}
            </div>
        </div>
    )
}

export default Header
