import React, { FC } from 'react'
import { Tooltip, IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { Translation } from '../../../../../../../domain'
import { FormattedTranslation } from '../../../../../hooks'
import { useToggler } from '../../../ManageRole.context'

const DeleteUserGroup: FC = () => {
    const toggle = useToggler('deleteRoleModalOpen')

    const handleDelete = () => {
        toggle()
    }

    return (
        <Tooltip title={<FormattedTranslation id={Translation.DELETE_USER_GROUP_LABEL} />}>
            <IconButton color={'primary'} onClick={handleDelete}>
                <Delete />
            </IconButton>
        </Tooltip>
    )
}

export default DeleteUserGroup
