import moment from 'moment'

import { Translation } from '../../../domain'
import { isEnumOf } from '../../../utils'

import { useTranslator } from '.'

type CSVHookParameters<L> = {
    /**
     * Defaults to a comma
     */
    readonly separator?: string

    /**
     * The headers of the CV
     * Feel free to use translation keys
     */
    readonly headers: (string | Translation)[]

    /**
     * Function that outputs the csv content (line by line)
     */
    readonly lineGenerator: (line: L) => string[]
}

export type CSVHook<L> = {
    separator: string
    generateFileName: (fileName: string) => string
    generateContent: (data: L[]) => string
}

/**
 * Want to create an CSV in a react component? use this ;)
 */
export const useCSV = <L extends unknown>({ separator = ',', headers, lineGenerator }: CSVHookParameters<L>): CSVHook<L> => {
    const translator = useTranslator()

    return {
        separator,
        generateFileName: (fileName: string) => `${moment().format('YYYY-MM-DD-HH-mm-ss')}-${fileName}.csv`,
        generateContent: (data) => {
            const header = `"${headers
                .reduce<string[]>((headers, header) => [...headers, isEnumOf(Translation, header) ? translator.formatTranslation(header) : header], [])
                .join(`"${separator}"`)}"\r\n`

            const content = data.reduce((acc, line) => acc + `${lineGenerator(line).join(separator)}\r\n`, '').trim()

            // For MS Excel we give it a byte order mark (BOM)
            return '\uFEFF' + header + content
        }
    }
}
