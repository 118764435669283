import gql from 'graphql-tag'

import { PortalError, PortalErrorType, Roles } from '../../../../domain'

import { ConnectwareRoleAccessCreationRequest } from '../../../../application/services'

import { buildMutationHandler, MutationHandler } from './Builders'
import { TypedResponse } from './Response'

const GRANT_ACCESS_TO_ROLE = gql`
    mutation GrantAccessToRole($permissionGroupId: ID!, $users: [String], $userGroups: [String], $connectwareLicenseIds: [String]) {
        grantAccessToPermissionGroup(
            input: { permissionGroupId: $permissionGroupId, users: $users, userGroups: $userGroups, connectwareLicenseIds: $connectwareLicenseIds }
        ) {
            permissionGroup {
                userGroups
            }
            success
            message
        }
    }
`

interface CreationInput {
    readonly permissionGroupId: string
    readonly userGroups: string[]
    /**
     * These additional properties are needed in the future
     */
    // readonly users?: string[]
    // readonly connectwareLicenseIds?: string[]
}

interface PermissionGroup {
    userGroups: string[]
}

interface GrantAccessToRoleResponse extends TypedResponse<'GrantAccessToRoleResponse'> {
    readonly success: boolean
    readonly message: string
    readonly permissionGroup: PermissionGroup
}

export default ({
    id: permissionGroupId,
    userGroups
}: ConnectwareRoleAccessCreationRequest): MutationHandler<
    CreationInput,
    { readonly grantAccessToPermissionGroup: GrantAccessToRoleResponse },
    Pick<Roles, 'userGroups'>
> =>
    buildMutationHandler(
        {
            document: GRANT_ACCESS_TO_ROLE,
            mapper: ({ data }) => {
                if (!data?.grantAccessToPermissionGroup.success) {
                    throw new PortalError(PortalErrorType.SERVER_ERROR, data?.grantAccessToPermissionGroup.message)
                }
                return { userGroups: data.grantAccessToPermissionGroup.permissionGroup.userGroups }
            }
        },
        { permissionGroupId, userGroups }
    )
