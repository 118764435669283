import { isAuthenticated, isPortalError, isSuperAdmin, ManageableServices, PortalError, PortalErrorType, ServiceMetaData } from '../../../../domain'

import { initialState } from '../../../InitialState'
import { ServiceRequest } from '../../../services'

import { ConnectwareUsecase } from '../Base'

export class ManageServicesUsecase extends ConnectwareUsecase {
    // Utils

    private getManageServices(): ManageableServices {
        const { manageServices } = this.getState()
        return manageServices
    }

    private setManageableServices(newState: Partial<ManageableServices>): void {
        const manageServices = this.getManageServices()
        this.setState({ manageServices: { ...manageServices, ...newState } })
    }

    // Loading

    /**
     * Loads services to be managed
     */
    async loadData(): Promise<void> {
        try {
            await this.loadServices(true)
            await this.loadCategories()
            await this.loadAllowedDirectories()
        } catch (e) {
            this.logger.error(e as PortalError)
        }
    }

    private async loadServices(initial: boolean): Promise<void> {
        if (initial) this.setManageableServices(initialState.manageServices)

        try {
            const services = await this.withAuthentication(() => this.servicesCatalogService.fetchServicesByCategories())
            const expanded: Record<string, boolean> = {}
            services.forEach((x) => (expanded[x.name] = true))
            this.setManageableServices({ services, expanded })
        } catch (e) {
            this.setManageableServices({ services: e as PortalError })
        }
    }

    private async loadCategories(): Promise<void> {
        try {
            const categories = await this.withAuthentication(() => this.servicesCatalogService.fetchCategories())
            this.setManageableServices({ categories })
        } catch (e) {
            this.setManageableServices({ categories: e as PortalError })
        }
    }

    async loadAllowedDirectories(): Promise<void> {
        try {
            const allowedDirectories = await this.withAuthentication(() => this.servicesCatalogService.fetchAllowedDirectories())
            this.setManageableServices({ allowedDirectories })
        } catch (e) {
            this.setManageableServices({ allowedDirectories: e as PortalError })
        }
    }

    toggleExpanded(name: string): void {
        const expanded = this.getManageServices().expanded
        this.setManageableServices({ expanded: { ...expanded, [name]: !expanded[name] } })
    }

    toggleDetails(service: ServiceMetaData | null): void {
        const { details } = this.getManageServices()
        this.setManageableServices({ details: details ? null : service })
    }

    toggleCreate(): void {
        const manageServices = this.getManageServices()
        const toBeCreated = manageServices.toBeCreated
            ? null
            : {
                  category: '',
                  description: '',
                  name: '',
                  directory: '',
                  filename: '',
                  isTemplate: false,
                  publiclyOffered: false,
                  data: ''
              }
        this.setManageableServices({ toBeCreated })
    }

    updateServiceToBeCreated(service: Partial<ManageableServices['toBeCreated']>): boolean {
        const { toBeCreated } = this.getManageServices()
        if (!toBeCreated) throw new PortalError(PortalErrorType.STATE, "Service to be created can't be updated")
        this.setManageableServices({ toBeCreated: { ...toBeCreated, ...service } })
        return Boolean(this.createServiceRequest())
    }

    toggleEdit(service: ServiceMetaData | null): void {
        this.setManageableServices({
            toBeEdited: service
                ? {
                      category: service.category,
                      description: service.description,
                      name: service.name,
                      directory: service.directory,
                      filename: service.filename,
                      isTemplate: service.isTemplate,
                      publiclyOffered: service.publiclyOffered,
                      data: ''
                  }
                : null
        })
    }

    /**
     * Updates the service in the state that will be dispatched to the backend
     * @returns if the service can be updated
     */
    updateServiceToBeUpdated(service: Partial<ManageableServices['toBeEdited']>): boolean {
        const { toBeEdited } = this.getManageServices()
        if (!toBeEdited) throw new PortalError(PortalErrorType.STATE, "Service to be edited can't be updated")
        this.setManageableServices({ toBeEdited: { ...toBeEdited, ...service } })
        return Boolean(this.updateServiceRequest())
    }

    isDirectoryAllowed(): boolean {
        const { account } = this.getState()

        const { toBeCreated, allowedDirectories } = this.getManageServices()

        if (!toBeCreated || !allowedDirectories || isPortalError(allowedDirectories)) return false

        const { directory } = toBeCreated

        if (!directory || !directory.endsWith('/')) {
            /**
             * Does not have a valid format
             */
            return false
        }

        if (isAuthenticated(account) && isSuperAdmin(account)) {
            /**
             * Super admins can do whatever
             */
            return true
        }

        /**
         * Is with-in allowed directories
         */
        return allowedDirectories.some((dir) => directory.startsWith(dir))
    }

    async parseMetadata(data: string): Promise<void> {
        const { toBeCreated } = this.getManageServices()
        if (!toBeCreated) throw new PortalError(PortalErrorType.STATE, 'Metadata can not be parsed because Service to be created is not set!')
        try {
            const metadata = await this.withAuthentication(() => this.servicesCatalogService.parseMetadata({ data }))
            this.setManageableServices({ toBeCreated: { ...toBeCreated, name: metadata.name } })
        } catch (e) {
            this.logger.error(e)
            this.setManageableServices({ toBeCreated: { ...toBeCreated, name: '' } })
        }
    }

    private createServiceRequest(): ServiceRequest | null {
        const { account } = this.getState()
        const { toBeCreated, allowedDirectories } = this.getManageServices()

        if (!toBeCreated || !allowedDirectories || isPortalError(allowedDirectories) || isPortalError(account)) return null
        const { category, filename, name, directory, isTemplate, publiclyOffered, description, data } = toBeCreated

        if (!category || !filename || !name || !directory || !description || !data || !this.isDirectoryAllowed()) {
            return null
        }
        return { category, filename, name, directory, isTemplate, publiclyOffered, description, data }
    }

    private updateServiceRequest(): Partial<ServiceRequest> | null {
        const { toBeEdited } = this.getManageServices()
        if (!toBeEdited) return null
        const { category, filename, name, directory, isTemplate, publiclyOffered, description, data } = toBeEdited

        if (!category || !description) return null
        return { category, filename, name, directory, isTemplate, publiclyOffered, description, data }
    }

    async updateService(): Promise<void> {
        const request = this.updateServiceRequest()
        if (!request) throw new PortalError(PortalErrorType.STATE, "Service can't be updated")
        await this.withAuthentication(() => this.servicesCatalogService.updateService(request))
        await this.loadServices(false)
    }

    async createService(): Promise<void> {
        const request = this.createServiceRequest()
        if (!request) throw new PortalError(PortalErrorType.STATE, "Service can't be created")
        await this.withAuthentication(() => this.servicesCatalogService.createService(request))
        await this.loadServices(false)
    }

    toggleDeletion(): void {
        const manageServices = this.getManageServices()
        const app = manageServices.details
        const toBeDeleted = manageServices.toBeDeleted
            ? null
            : app && {
                  directory: app.directory,
                  filename: app.filename
              }
        this.setManageableServices({ toBeDeleted })
    }

    async deleteService(): Promise<void> {
        const { toBeDeleted } = this.getManageServices()
        const request = toBeDeleted && {
            filename: toBeDeleted.filename,
            directory: toBeDeleted.directory
        }
        if (!request) throw new PortalError(PortalErrorType.STATE, "Service can't be deleted")
        await this.withAuthentication(() => this.servicesCatalogService.deleteService(request))
        await this.loadServices(false)
    }
}
