import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from './Toolbar'

const styles = (theme) => ({
  header: {
    margin: '10px 0px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  }
})

function Header({ classes, permissionGroup }) {
  return (
    <div className={classes.header}>
      <Typography variant='h5' component='h3'>
        {permissionGroup.name}
      </Typography>
      <Toolbar id={permissionGroup.id} permissionGroup={permissionGroup} />
    </div>
  )
}

export default withStyles(styles)(Header)
