import React, { FC, useState as useReactState } from 'react'

import { useState, useUsecases } from '../../../hooks'

import { Upload } from './Upload'

export const Wrapper: FC = () => {
    const toBeEdited = useState((s) => s.manageServices.toBeEdited)
    const toBeCreated = useState((s) => s.manageServices.toBeCreated)
    const [disabled, setDisabled] = useReactState(true)

    const { manageServicesCatalogUsecase } = useUsecases()

    if (toBeEdited) {
        return (
            <Upload
                title="Update Service"
                buttonText="Update"
                disabled={disabled}
                data={toBeEdited}
                onChange={(e) => {
                    setDisabled(!manageServicesCatalogUsecase.updateServiceToBeUpdated(e))
                }}
                onClose={() => {
                    manageServicesCatalogUsecase.toggleEdit(null)
                    manageServicesCatalogUsecase.toggleDetails(null)
                }}
                onSubmit={() => manageServicesCatalogUsecase.updateService()}
                edit
            />
        )
    }

    if (toBeCreated) {
        return (
            <Upload
                title="Upload new Service"
                buttonText="Upload"
                isDirectoryEditable
                isDirectoryValid={manageServicesCatalogUsecase.isDirectoryAllowed()}
                disabled={disabled}
                data={toBeCreated}
                onChange={(e) => {
                    setDisabled(!manageServicesCatalogUsecase.updateServiceToBeCreated(e))
                }}
                onSubmit={() => manageServicesCatalogUsecase.createService()}
                onClose={() => {
                    manageServicesCatalogUsecase.toggleCreate()
                }}
            />
        )
    }

    return null
}
