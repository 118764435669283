import React, { createRef, FC, useEffect, useState } from 'react'
import { Paper, Theme, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
    root: { ...theme.mixins.gutters(), paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2), width: '100%' }
}))

export const Page: FC = ({ children }) => {
    const classes = useStyles()
    const page = createRef<HTMLDivElement>()
    const [[width, padding], setState] = useState<[number, number]>([0, 0])

    useEffect(() => {
        const parentElement = page.current?.parentElement
        if (!parentElement) {
            return
        }

        let unhooked = false
        const handleResize = () => {
            if (unhooked) return
            const width = parentElement.clientWidth
            const padding = window.getComputedStyle(parentElement).padding

            setState([width, padding ? parseInt(padding.replace('px', '')) : 0])
        }

        window.addEventListener('resize', handleResize)
        setTimeout(handleResize, 10)

        return () => {
            unhooked = true
            window.removeEventListener('resize', handleResize)
        }
    }, [page, page.current])

    return (
        <div ref={page} style={{ width: width - padding * 2 }}>
            <Paper className={classes.root} elevation={1}>
                {children}
            </Paper>
        </div>
    )
}
