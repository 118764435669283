import React, { FC } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, LinearProgress, Typography, makeStyles } from '@material-ui/core'

import { Translation } from '../../../../../domain'

import { FormattedTranslation, useServices } from '../../../hooks'
import { customStrongFormatter } from '../../utils'

const useStyles = makeStyles({
    dialogContent: { marginTop: '15px', width: '100%' },
    dialogText: { margin: '5px 0x', width: '100%', textAlign: 'left' },
    form: { width: '100%', textAlign: 'center' }
})

const Modal: FC<{ readonly open: boolean; readonly toggleOpen: () => void }> = ({ open, toggleOpen }) => {
    const classes = useStyles()

    const { configuration } = useServices()
    const contactInformation = configuration.getContactInformation()

    return (
        <Dialog fullWidth disableEscapeKeyDown open={open} onClose={toggleOpen} maxWidth="xs">
            <div className={classes.form}>
                <DialogTitle>
                    <FormattedTranslation id={Translation.REQUEST_LICENSE} />
                </DialogTitle>
                <LinearProgress variant="determinate" value={0} />
                <DialogContent className={classes.dialogContent}>
                    <Typography className={classes.dialogText} variant="subtitle1" gutterBottom>
                        <FormattedTranslation id={Translation.REQUEST_LICENSE_TIPS} />
                    </Typography>
                    <Typography className={classes.dialogText} variant="subtitle1" gutterBottom>
                        <FormattedTranslation
                            id={Translation.CONTACT_SUMMARY}
                            values={{ strong: customStrongFormatter(), br: <br />, ...contactInformation }}
                        />
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={toggleOpen}>
                        <FormattedTranslation id={Translation.CLOSE_ACTION} />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default Modal
