import { Button, CircularProgress } from '@material-ui/core'
import { MUIDataTableColumnDef } from 'mui-datatables'
import React, { FC, useMemo } from 'react'
import { isPending, isRejected, usePromise } from 'react-sync-promise'
import { PortalError, Translation, UserGroupRolesAndUsers } from '../../../../domain'
import { FormattedTranslation, RoutePath, useIsAdmin, useIsSuperAdmin, useRedirect, useTranslator, useUsecases } from '../../hooks'
import { ErrorMessage, Page, Table } from '../common'

const ManageUserGroups: FC = () => {
    const translator = useTranslator()
    const r = useRedirect()
    const isSuperAdmin = useIsSuperAdmin()
    const isAdmin = useIsAdmin()
    const { connectwareUsersUsecase } = useUsecases()
    const promise = usePromise<UserGroupRolesAndUsers[], PortalError>(useMemo(() => connectwareUsersUsecase.listUserGroupsRolesAndUsers(), []))

    if (isRejected(promise)) {
        return <ErrorMessage error={promise.value} />
    }

    if (isPending(promise)) return <CircularProgress />

    const userGroups = promise.value
    const customToolbar = () => {
        if (!isSuperAdmin) return null
        const link = connectwareUsersUsecase.buildExternalEditUserURL('GROUPS')
        return (
            <Button color="primary" variant="contained" href={link.toString()} target="_blank">
                <FormattedTranslation id={Translation.EDIT_AWS_ACTION} />
            </Button>
        )
    }

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'name',
            label: translator.formatTranslation(Translation.NAME),
            options: { filter: false, sort: true, sortDescFirst: false }
        },

        {
            name: 'amountOfRoles',
            label: translator.formatTranslation(Translation.NUMBER_OF_X_LABEL, {
                label: translator.formatTranslation(Translation.ROLES)
            })
        }
    ]
    if (isAdmin) {
        columns.push({
            name: 'amountOfUsers',
            label: translator.formatTranslation(Translation.NUMBER_OF_X_LABEL, {
                label: translator.formatTranslation(Translation.USERS)
            })
        })
    }

    return (
        <Page>
            <Table
                title={translator.formatTranslation(Translation.USER_GROUPS_TITLE)}
                data={userGroups}
                columns={columns}
                options={{
                    selectableRows: 'none',
                    filter: false,
                    viewColumns: false,
                    elevation: 0,
                    textLabels: { body: { noMatch: '' } },
                    sortOrder: { name: 'name', direction: 'asc' },
                    onCellClick: (_, { dataIndex }) => r.redirect(RoutePath.ADMIN_USER_GROUP, userGroups[dataIndex].name),
                    customToolbar
                }}
            />
        </Page>
    )
}

export default ManageUserGroups
