import React, { FC, useEffect, useState as useReactState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, makeStyles, LinearProgress } from '@material-ui/core'

import { ManageableConnectwareLicenses, PortalError, Translation } from '../../../../../domain'

import { FormattedTranslation, useState, useUsecases } from '../../../hooks'
import { DateField, ErrorMessage } from '../../common'

import { cybusAmber } from '../../../colors'
import { UserGroupsSelect } from '../../UserGroups'
import ConnectwareLicenseClass from '../../ConnectwareLicenseClass'

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    dialogEntry: {
        marginTop: `${theme.spacing(3)}px`,
        display: 'flex',
        // TODO Fix this
        flexDirection: ('left' as unknown) as undefined,
        alignItems: 'center',
        width: '100%',
        wordWrap: 'break-word'
    },
    dialogText: {
        width: '100%',
        textAlign: 'left'
    },
    errorText: {
        marginLeft: `${theme.spacing(3)}px`
    },
    icon: {
        marginRight: `${theme.spacing(2)}px`,
        // TODO Fix this
        color: ((theme.palette as unknown) as Partial<Record<string, typeof cybusAmber>>)?.accent?.[500]
    },
    form: {
        width: '100%',
        textAlign: 'center'
    },
    select: {
        textAlign: 'left',
        width: '100%'
    }
}))

export const CreationModal: FC = () => {
    const classes = useStyles()
    const { manageConnectwareLicensesUsecase } = useUsecases()

    const [disabled, setDisabled] = useReactState(true)
    const [loading, setLoading] = useReactState(false)
    const [creationError, setCreationErrror] = useReactState<PortalError | null>(null)

    const toBeCreated = useState((s) => s.manageLicenses?.toBeCreated || null)

    useEffect(() => {
        if (!toBeCreated) {
            setDisabled(true)
            setLoading(false)
            setCreationErrror(null)
        }
    }, [toBeCreated === null])

    if (toBeCreated === null) {
        return null
    }

    const updateLicenseToBeCreated = (license: Partial<ManageableConnectwareLicenses['toBeCreated']>): void =>
        setDisabled(!manageConnectwareLicensesUsecase.updateLicenseToBeCreated(license))

    const create = async (): Promise<void> => {
        try {
            setLoading(true)
            setCreationErrror(null)
            await manageConnectwareLicensesUsecase.createLicense()
            handleClose()
        } catch (e) {
            setCreationErrror(e)
            return
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        manageConnectwareLicensesUsecase.toggleCreation()
    }

    return (
        <Dialog fullWidth disableEscapeKeyDown open maxWidth="xs" onClose={handleClose}>
            <form
                className={classes.form}
                onSubmit={(e) => {
                    e.preventDefault()
                    void create()
                }}
            >
                <DialogTitle>Create License</DialogTitle>
                <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.dialogEntry}>
                        <TextField
                            name="name"
                            id="name"
                            label={<FormattedTranslation id={Translation.NAME} />}
                            className={classes.dialogText}
                            InputProps={{ placeholder: '' }}
                            value={toBeCreated.name}
                            autoComplete="off"
                            onChange={({ target: { value } }) => updateLicenseToBeCreated({ name: value })}
                        />
                    </div>
                    <div className={classes.dialogEntry}>
                        <UserGroupsSelect
                            account={toBeCreated.associatedAccount}
                            onChange={(associatedAccount) => updateLicenseToBeCreated({ associatedAccount })}
                        />
                    </div>
                    <div className={classes.dialogEntry}>
                        <DateField
                            name="expiration"
                            id="expiration"
                            label={<FormattedTranslation id={Translation.EXPIRATION} />}
                            className={classes.dialogText}
                            onChange={(expiration) => updateLicenseToBeCreated({ expiration })}
                        />
                    </div>
                    {/* <div className={classes.dialogEntry}>
                        <FormControlLabel
                            control={
                                <Switch
                                    name="autoRenewal"
                                    checked={toBeCreated.autoRenewal}
                                    onChange={() => updateLicenseToBeCreated({ autoRenewal: !toBeCreated.autoRenewal })}
                                    color="primary"
                                />
                            }
                            label={<FormattedTranslation id={Translation.AUTOMATIC_LICENSE_RENEWAL} />}
                        />
                    </div> */}
                    <div className={classes.dialogEntry}>
                        <ConnectwareLicenseClass
                            valueKey="name"
                            selected={toBeCreated.licenseClass}
                            onChange={(value) => updateLicenseToBeCreated({ licenseClass: value.name })}
                        />
                    </div>
                    {creationError && <ErrorMessage error={creationError} />}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        <FormattedTranslation id={Translation.CANCEL} />
                    </Button>
                    <Button color="primary" type="submit" disabled={disabled || loading}>
                        <FormattedTranslation id={Translation.CREATE_LICENSE} />
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
