import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import { LICENSE, BULK_REVOKE_ACCESS_TO_PERMISSION_GROUP } from '../../../graphql'
import { ErrorMessage } from '../../../infrastructure/react/components/common'
import Table from '../../common/Table'
import Create from './Create'
import { useUsecases } from '../../../infrastructure/react/hooks'

const Roles = ({ connectwareLicense }) => {
  const { manageConnectwareLicensesUsecase } = useUsecases()
  const getColumns = () => {
    return [
      {
        name: 'name',
        label: 'name',
        options: {
          filter: false,
          sort: true,
          sortDescFirst: false
        }
      }
    ]
  }

  const getOptions = ({ connectwareLicense, revokeAccessToPermissionGroup }) => {
    return {
      filter: false,
      viewColumns: false,
      elevation: 0,
      textLabels: {
        body: {
          noMatch: ''
        }
      },
      customToolbar: () => <Create connectwareLicenseId={connectwareLicense.id} currentPermissionGroupIds={connectwareLicense.roles.map(({ id }) => id)} />,
      onRowsDelete: async (options) => {
        revokeAccessToPermissionGroup({
          variables: {
            permissionGroupIds: options.data.map(({ dataIndex }) => connectwareLicense.roles[dataIndex].id),
            connectwareLicenseId: connectwareLicense.id
          }
        })
      }
    }
  }

  return (
    <Mutation
      mutation={BULK_REVOKE_ACCESS_TO_PERMISSION_GROUP}
      refetchQueries={[
        {
          query: LICENSE,
          variables: { id: connectwareLicense.id }
        }
      ]}
      onCompleted={async () => await manageConnectwareLicensesUsecase.loadLicenses()}
    >
      {(revokeAccessToPermissionGroup, { data = {}, error }) => {
        const columns = getColumns()
        const options = getOptions({
          connectwareLicense,
          revokeAccessToPermissionGroup
        })
        const { updatePermissionGroup: { success, message } = {} } = data
        return (
          <div>
            <Table title='Roles (formerly called "Permission Groups")' data={connectwareLicense.roles} columns={columns} options={options} smallHeader />
            <ErrorMessage error={error} />
          </div>
        )
      }}
    </Mutation>
  )
}

export default Roles
