import React, { FC, RefAttributes } from 'react'
import { Link as BaseLink, generatePath, useHistory, LinkProps } from 'react-router-dom'
import { History } from 'history'

import { resolveRoute, RoutePath, useServices } from '..'
import { LoggerService } from '../../../../application/services'
import { PortalError, PortalErrorType } from '../../../../domain'
type OnlyPath =
    | RoutePath.HOME
    | RoutePath.ADMIN_LICENSES
    | RoutePath.APPLIANCES
    | RoutePath.SERVICES_CATALOG
    | RoutePath.LICENSES
    | RoutePath.ADMIN_ROLES
    | RoutePath.ADMIN_USER_GROUPS
    | RoutePath.ADMIN_USERS
    | RoutePath.METRICS

type PathWithId = RoutePath.ADMIN_LICENSE | RoutePath.APPLIANCE | RoutePath.LICENSE | RoutePath.ADMIN_ROLE | RoutePath.ADMIN_USER_GROUP

// export type RedirectHook = { redirect: (...args: [OnlyPath] | [PathWithId, string]) => void }

const resolveRouteWithId = (path: RoutePath, id?: string): string => generatePath(resolveRoute(path), { id })

export class RedirectHook {
    private readonly history: History<unknown>
    private readonly logger: LoggerService

    constructor(history: History<unknown>, logger: LoggerService) {
        this.history = history
        this.logger = logger
    }

    redirect(...args: [OnlyPath] | [PathWithId, string]): void {
        let route: string | null = null

        switch (args.length) {
            case 2:
                route = resolveRouteWithId(...args)
                break
            case 1:
                route = resolveRouteWithId(...args)
                break
        }
        if (route) {
            this.history.push(route)
        } else {
            this.logger.error(new PortalError(PortalErrorType.UNEXPECTED, 'Route not possible', { args }))
        }

        this.history.push(route)
    }
}

export const useRedirect = (): RedirectHook => {
    const history = useHistory()
    const { logger } = useServices()
    return new RedirectHook(history, logger)
}

type Props = { readonly to: { readonly path: OnlyPath } | { readonly path: PathWithId; readonly id: string } }

export const Link: FC<Props & Omit<LinkProps<unknown> & RefAttributes<HTMLAnchorElement>, 'to'>> = ({ to, ...props }) => (
    <BaseLink {...props} to={'id' in to ? resolveRouteWithId(to.path, to.id) : resolveRouteWithId(to.path)} />
)
