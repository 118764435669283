import gql from 'graphql-tag'

import { buildQueryHandler, QueryHandler } from './Builders'

const ALLOWED_DIRECTORIES = gql`
    query AllowedDirectories {
        allowedDirectories
    }
`

export default (): QueryHandler<void, { readonly allowedDirectories: string[] }, string[]> =>
    buildQueryHandler({ document: ALLOWED_DIRECTORIES, mapper: ({ data }) => data.allowedDirectories }, void 0)
