import { Button, CircularProgress } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import { isPending, isRejected, usePromise } from 'react-sync-promise'
import { PortalError, Translation, User } from '../../../../domain'
import { useTranslator, useParameters, useUsecases, useIsSuperAdmin, FormattedTranslation } from '../../hooks'
import { ErrorMessage, Table } from '../common'
import { formatDate } from '../utils'

const ManageUserGroupTable: FC = () => {
    const translator = useTranslator()
    const { id: name } = useParameters('id')
    const { connectwareUsersUsecase } = useUsecases()
    const isSuperAdmin = useIsSuperAdmin()
    const promise = usePromise<User[], PortalError>(useMemo(() => connectwareUsersUsecase.listUsersInUserGroup(name), [name]))

    if (isPending(promise)) return <CircularProgress />
    if (isRejected(promise)) return <ErrorMessage error={promise.value} />

    const customToolbar = () => {
        if (!isSuperAdmin) return null

        const link = connectwareUsersUsecase.buildExternalEditUserURL({ group: name })

        // TODO Translate
        return (
            <Button color="primary" variant="contained" href={link.toString()} target="_blank">
                <FormattedTranslation id={Translation.EDIT_AWS_ACTION} />
            </Button>
        )
    }

    return (
        <Table
            title={translator.formatTranslation(Translation.MANAGE_USER_GROUP_TITLE, { name, type: translator.formatTranslation(Translation.USERS) })}
            data={promise.value}
            columns={[
                {
                    label: translator.formatTranslation(Translation.NAME),
                    name: 'userName'
                },
                {
                    label: translator.formatTranslation(Translation.EMAIL),
                    name: 'email'
                },
                {
                    label: translator.formatTranslation(Translation.ACTIVE),
                    name: 'enabled',
                    options: {
                        customBodyRender: (enabled: boolean) => translator.formatTranslation(Translation.YES_NO, { value: enabled })
                    }
                },
                {
                    label: translator.formatTranslation(Translation.STATUS),
                    name: 'status'
                },
                {
                    label: translator.formatTranslation(Translation.CREATED_AT),
                    name: 'createdAt',
                    options: {
                        customBodyRender: (createdAt: Date) => formatDate(createdAt)
                    }
                }
            ]}
            options={{
                filterType: 'dropdown',
                sortOrder: { name: 'userName', direction: 'asc' },
                selectableRows: 'none',
                customToolbar,
                textLabels: {
                    body: {
                        // TODO: replace with translation
                        noMatch: translator.formatTranslation(Translation.MANAGE_USER_GROUPS_NO_MATCH)
                    }
                }
            }}
        />
    )
}

export default ManageUserGroupTable
