import React, { Component } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { ALL_PERMISSION_GROUPS, REVOKE_ACCESS_TO_PERMISSION_GROUP } from '../../../graphql'
import { ErrorMessage } from '../../../infrastructure/react/components/common'
import Table from '../../common/Table'
import Create from './Create'

class Users extends Component {
  getColumns = () => {
    return [
      {
        name: 'username',
        label: 'Username',
        options: {
          filter: false,
          sort: false,
          sortDescFirst: false
        }
      }
    ]
  }

  getOptions = ({ permissionGroupId, users, revokeAccessToPermissionGroup }) => {
    return {
      filter: false,
      viewColumns: false,
      elevation: 0,
      textLabels: {
        body: {
          noMatch: ''
        }
      },
      customToolbar: () => <Create permissionGroupId={permissionGroupId} />,
      onRowsDelete: (options) => {
        revokeAccessToPermissionGroup({
          variables: {
            permissionGroupId,
            users: users.filter((value, index) => options.data.find(({ dataIndex }) => dataIndex === index))
          }
        })
      }
    }
  }

  render() {
    const { permissionGroupId, users } = this.props
    return (
      <Mutation mutation={REVOKE_ACCESS_TO_PERMISSION_GROUP} refetchQueries={[{ query: ALL_PERMISSION_GROUPS }]}>
        {(revokeAccessToPermissionGroup, { data = {}, error }) => {
          const columns = this.getColumns()
          const options = this.getOptions({
            permissionGroupId,
            users,
            revokeAccessToPermissionGroup
          })
          const { updatePermissionGroup: { success, message } = {} } = data
          return (
            <div>
              <Table title='Users' data={users.map((username) => ({ username }))} columns={columns} options={options} smallHeader />
              <ErrorMessage error={error} />
            </div>
          )
        }}
      </Mutation>
    )
  }
}

export default Users
