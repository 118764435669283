import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import { Mutation } from '@apollo/client/react/components'
import { ALL_PERMISSION_GROUPS, GRANT_ACCESS_TO_PERMISSION_GROUP } from '../../../../graphql'
import { ErrorMessage } from '../../../../infrastructure/react/components/common'

const getUsernameValidationError = (resource) => {
  const invalidTabOrSpace = resource.match(/[ \t]/g)
  if (invalidTabOrSpace) {
    return 'The username given contains an invalid space or tab. A username cannot contain a space or tab anywhere. Please amend your resource to fit this specification'
  }
  return null
}

const validateUsername = (resource) => {
  return !/[ \t]/.test(resource)
}

const styles = (theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  dialogEntry: {
    marginTop: `${theme.spacing(3)}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    wordWrap: 'break-word'
  },
  dialogText: {
    width: '100%',
    textAlign: 'left'
  },
  errorText: {
    marginLeft: `${theme.spacing(3)}px`
  },
  icon: {
    marginRight: `${theme.spacing(2)}px`,
    color: theme.palette.accent[500]
  },
  form: {
    width: '100%',
    textAlign: 'center'
  },
  select: {
    textAlign: 'left',
    width: '100%'
  }
})

class Modal extends Component {
  state = {
    username: '',
    errorMessage: ''
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value.trim()
      },
      () => this.handleValidateInput()
    )
  }

  handleValidateInput = () => {
    const { username } = this.state
    if (!validateUsername(username)) {
      const errorMessage = getUsernameValidationError(username)
      this.setState({ errorMessage })
    } else {
      this.setState({
        errorMessage: ''
      })
    }
  }

  handleClose = () => {
    const { toggleOpen } = this.props
    this.setState({ username: '' }, toggleOpen)
  }

  isDisabled = () => {
    const { username, errorMessage } = this.state
    return !username.length || Boolean(errorMessage.length)
  }

  render() {
    const { classes, open, permissionGroupId } = this.props
    const { username, errorMessage } = this.state
    return (
      <Mutation
        mutation={GRANT_ACCESS_TO_PERMISSION_GROUP}
        variables={{
          permissionGroupId,
          users: [username]
        }}
        refetchQueries={[{ query: ALL_PERMISSION_GROUPS }]}
      >
        {(grantAccessToPermissionGroup, { loading, data = {}, error }) => {
          const { grantAccessToPermissionGroup: { success, message } = {} } = data
          return (
            <Dialog fullWidth disableEscapeKeyDown open={open} onClose={this.handleClose} maxWidth='xs'>
              <form
                className={classes.form}
                onSubmit={(e) => {
                  e.preventDefault()
                  grantAccessToPermissionGroup()
                }}
              >
                <DialogTitle>Grant User Access to Role</DialogTitle>
                <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />
                <DialogContent className={classes.dialogContent}>
                  <div className={classes.dialogEntry}>
                    <TextField
                      name='username'
                      id='username'
                      label='Username'
                      className={classes.dialogText}
                      InputProps={{ placeholder: '' }}
                      value={username}
                      autoComplete='off'
                      error={Boolean(errorMessage.length)}
                      helperText={errorMessage}
                      onChange={this.handleChange}
                    />
                  </div>
                  <ErrorMessage error={error} />
                </DialogContent>
                <DialogActions>
                  <Button color='primary' onClick={this.handleClose}>
                    Cancel
                  </Button>
                  <Button color='primary' type='submit' disabled={this.isDisabled() || loading}>
                    Grant
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
          )
        }}
      </Mutation>
    )
  }
}

export default withStyles(styles)(Modal)
