export enum PortalErrorType {
    CONFIGURATION_ERROR = 'CONFIGURATION_ERROR',
    STATE = 'STATE',
    TRANSLATIONS = 'TRANSLATIONS',
    UNEXPECTED = 'UNEXPECTED',
    SERVER_ERROR = 'SERVER_ERROR',
    MISSING_CREDENTIALS = 'MISSING_CREDENTIALS',
    BAD_INPUT = 'BAD_INPUT'
}

export class PortalError extends Error {
    readonly type: PortalErrorType
    readonly extras: Record<string | number, unknown>

    constructor(type: PortalErrorType, message = 'No message', extras: Record<string | number, unknown> = {}) {
        super(message)

        this.type = type
        this.extras = extras

        /**
         * This sets the Error object as the base type of the Portal (see tests) #justJSThings
         */
        Object.setPrototypeOf(this, new.target.prototype)
    }
}

export const isPortalError = (e: unknown): e is PortalError => e instanceof PortalError
export const isPortalErrorOfTypes = (e: unknown, ...types: PortalErrorType[]): e is PortalError => isPortalError(e) && types.includes(e.type)
