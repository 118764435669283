import React, { FC, Fragment, PropsWithChildren, ReactElement, ReactNode } from 'react'
import { BrowserRouter, Route as BaseRoute, Switch as BaseSwitch, Redirect as BaseRedirect, useParams } from 'react-router-dom'

import { Unarray } from '../../../../utils'

import { PortalError, PortalErrorType } from '../../../../domain'

import { useIsAdmin, useIsSuperAdmin } from '..'
import { RoutePath } from './RoutePath'
import { RouteProvider } from './RoutePath.context'

type RouterProps = {
    readonly onAuthorizing?: ReactNode
    readonly onUnauthorized: RoutePath
    readonly children: ReactElement<RouteProps>[] | ReactElement<RouteProps>
}
type RouteProps = PropsWithChildren<{
    readonly exact?: boolean
    readonly superAdmin?: boolean
    readonly admin?: boolean
    readonly path: RoutePath
}>
type RedirectProps = PropsWithChildren<{ readonly to: RoutePath }>

const routes: Record<RoutePath, string> = {
    [RoutePath.HOME]: '/',
    [RoutePath.ADMIN_LICENSE]: '/admin-license/:id',
    [RoutePath.ADMIN_LICENSES]: '/admin-licenses',
    [RoutePath.APPLIANCES]: '/selfhosted-appliances',
    [RoutePath.APPLIANCE]: '/selfhosted-appliance/:id',
    [RoutePath.SERVICES_CATALOG]: '/services-catalog',
    [RoutePath.LICENSES]: '/licenses',
    [RoutePath.LICENSE]: '/license/:id',
    [RoutePath.ADMIN_ROLES]: '/admin-roles',
    [RoutePath.ADMIN_ROLE]: '/admin-role/:id',
    [RoutePath.ADMIN_USER_GROUPS]: '/admin-user-groups',
    [RoutePath.ADMIN_USER_GROUP]: '/admin-user-group/:id',
    [RoutePath.ADMIN_USERS]: '/admin-users',
    [RoutePath.METRICS]: '/metrics'
}

type PossibleParameters = { id?: string }

export const useParameters = <Keys extends (keyof PossibleParameters)[]>(...keys: Keys): Record<Unarray<Keys>, string> => {
    const params = useParams<PossibleParameters>()

    return keys.reduce<Record<Unarray<Keys>, string>>((r, k) => {
        const value = params[k]

        if (!value) {
            throw new PortalError(PortalErrorType.STATE, 'Parameter value not set', { name: k })
        }

        return { ...r, [k]: value }
    }, {} as Record<Unarray<Keys>, string>)
}

export const resolveRoute = (path: RoutePath): string => routes[path]

/**
 * To declared a route inside the Router
 */
export const Route: FC<RouteProps> = () => <Fragment />

/**
 * To perform a redirection inside the router
 */
export const Redirect: FC<RedirectProps> = ({ to }) => <BaseRedirect to={resolveRoute(to)} />

/**
 * Declare an application Router
 */
export const Router = (props: RouterProps): JSX.Element => {
    return (
        <BrowserRouter>
            <Switcher {...props} />
        </BrowserRouter>
    )
}
export const Switcher = ({ onUnauthorized, onAuthorizing, children }: RouterProps): JSX.Element => {
    const isAdmin = useIsAdmin()
    const isSuperAdmin = useIsSuperAdmin()
    const childArray = Array.isArray(children) ? children : [children]

    return (
        <BaseSwitch>
            {childArray.map((child, k) => {
                if (child.type !== Route) {
                    return <Fragment key={k}>{child}</Fragment>
                }

                const { exact, superAdmin, admin, path, children } = child.props
                const resolvedPath = resolveRoute(path)

                let rendered: ReactNode
                if (isSuperAdmin === null || isAdmin === null) {
                    rendered = onAuthorizing
                } else {
                    if ((superAdmin && !isSuperAdmin) || (admin && !isAdmin)) {
                        rendered = <Redirect key={k} to={onUnauthorized} />
                    } else {
                        rendered = <RouteProvider route={path}>{children}</RouteProvider>
                    }
                }

                return (
                    <BaseRoute key={k} path={resolvedPath} exact={exact}>
                        {rendered}
                    </BaseRoute>
                )
            })}
        </BaseSwitch>
    )
}
