import React, { Component } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { LICENSE, UPDATE_CONNECTWARE_LICENSE } from '../../../graphql'
import { ErrorMessage } from '../../../infrastructure/react/components/common'
import Table from '../../common/Table'
import Create from './Create'

class Protocols extends Component {
  getColumns = () => {
    return [
      {
        name: 'name',
        label: 'Name',
        options: {
          filter: false,
          sort: true,
          sortDescFirst: false
        }
      }
    ]
  }

  getOptions = ({ connectwareLicense, updateConnectwareLicense }) => {
    return {
      filter: false,
      viewColumns: false,
      elevation: 0,
      textLabels: {
        body: {
          noMatch: ''
        }
      },
      customToolbar: () => (
        <Create
          connectwareLicenseId={connectwareLicense.id}
          capabilities={connectwareLicense.capabilities}
          currentProtocols={connectwareLicense.capabilities.protocols}
        />
      ),
      onRowsDelete: (options) => {
        const toBeDeleted = options.data.map(({ dataIndex }) => connectwareLicense.capabilities.protocols[dataIndex])
        const current = connectwareLicense.capabilities.protocols
        const remaining = current.filter((val) => !toBeDeleted.includes(val))
        updateConnectwareLicense({
          variables: {
            id: connectwareLicense.id,
            capabilities: {
              ...connectwareLicense.capabilities,
              protocols: remaining
            }
          }
        })
      }
    }
  }

  render() {
    const { connectwareLicense } = this.props
    const { id, capabilities } = connectwareLicense
    const { protocols } = capabilities
    return (
      <Mutation
        mutation={UPDATE_CONNECTWARE_LICENSE}
        variables={{
          id,
          capabilities
        }}
        refetchQueries={[{ query: LICENSE, variables: { id } }]}
      >
        {(updateConnectwareLicense, { data = {}, error }) => {
          const columns = this.getColumns()
          const options = this.getOptions({ connectwareLicense, updateConnectwareLicense })
          const { updateConnectwareLicense: { success, message } = {} } = data
          return (
            <div>
              <Table
                title='Protocols'
                data={protocols.map((item) => {
                  return { name: item }
                })}
                columns={columns}
                options={options}
                smallHeader
              />
              <ErrorMessage error={error} />
            </div>
          )
        }}
      </Mutation>
    )
  }
}

export default Protocols
