import { CircularProgress } from '@material-ui/core'
import { MUIDataTableOptions } from 'mui-datatables'
import React, { FC, useMemo } from 'react'
import { isPending, isRejected, usePromise } from 'react-sync-promise'
import { PortalError, Translation, Role } from '../../../../domain'
import { useTranslator, useParameters, useUsecases, useRedirect, RoutePath, useIsSuperAdmin } from '../../hooks'
import { ErrorMessage, Table } from '../common'

const RolesTable: FC = () => {
    const translator = useTranslator()
    const r = useRedirect()
    const { id: name } = useParameters('id')
    const isSuperAdmin = useIsSuperAdmin()
    const { connectwareUsersUsecase } = useUsecases()
    const promise = usePromise<Role[], PortalError>(useMemo(() => connectwareUsersUsecase.fetchRolesByUserGroupName(name), [name]))

    if (isPending(promise)) return <CircularProgress />
    if (isRejected(promise)) return <ErrorMessage error={promise.value} />
    const roles = promise.value

    const options: Partial<MUIDataTableOptions> = {
        filterType: 'dropdown',
        sortOrder: { name: 'name', direction: 'asc' },
        selectableRows: 'none',
        textLabels: {
            body: {
                // TODO: replace with translation
                noMatch: translator.formatTranslation(Translation.ROLES_TABLE_NO_MATCH)
            }
        },
        rowsPerPage: 30
    }
    if (isSuperAdmin) {
        options.onCellClick = (_, { dataIndex }) => r.redirect(RoutePath.ADMIN_ROLE, roles[dataIndex].id)
    }

    return (
        <Table
            title={translator.formatTranslation(Translation.MANAGE_USER_GROUP_TITLE, { name, type: translator.formatTranslation(Translation.ROLES) })}
            data={roles}
            columns={[
                {
                    label: translator.formatTranslation(Translation.TABLE_LABEL_ID),
                    name: 'id',
                    options: {
                        display: 'excluded'
                    }
                },
                {
                    label: translator.formatTranslation(Translation.NAME),
                    name: 'name'
                }
            ]}
            options={options}
        />
    )
}

export default RolesTable
