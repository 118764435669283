import React, { FC, useEffect } from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { Launch } from '@material-ui/icons'
import { MUIDataTableMeta } from 'mui-datatables'

import { ValueOf } from '../../../../utils'

import { isPortalError, ManageableConnectwareLicense, Translation } from '../../../../domain'

import { customSearch, formatDate } from '../utils'
import { useTranslator, Link, RoutePath, useRedirect, useCSV, useWindowSize, useState, CSVHook } from '../../hooks'
import { ErrorMessage, ExpirationStatus, Table, useSelectedRows } from '../common'

import IsInUse from '../IsInUse'
import { CreateButton } from './Create'
import { Toolbar } from './Toolbar'

const useStyles = makeStyles({
    wrapper: { position: 'relative', height: '20px' },
    parentStyle: { position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, boxSizing: 'border-box', display: 'block', width: '100%' },
    cellStyle: { boxSizing: 'border-box', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }
})

const AssociatedAccountCell: FC<Pick<ManageableConnectwareLicense, 'associatedAccount'>> = ({ associatedAccount }) => {
    const classes = useStyles()

    return (
        <div className={classes.wrapper}>
            <div className={classes.parentStyle}>
                <div className={classes.cellStyle}>{associatedAccount}</div>
            </div>
        </div>
    )
}

const useLicensesCSV = (): CSVHook<ManageableConnectwareLicense> => {
    const translator = useTranslator()

    return useCSV<ManageableConnectwareLicense>({
        headers: [
            Translation.CUSTOMER,
            Translation.LICENSE_NAME,
            Translation.EXPIRATION_DATE,
            Translation.TYPE_OF_LICENSE,
            Translation.NUMBER_OF_PROTOCOLS,
            Translation.NUMBER_OF_SERVICES,
            Translation.NUMBER_OF_CONNECTIONS,
            Translation.NUMBER_OF_ENDPOINTS,
            Translation.NUMBER_OF_MAPPINGS,
            Translation.NUMBER_OF_USERS,
            Translation.NUMBER_OF_ROLES,
            Translation.LDAP
            // Translation.AUTO_RENEWAL
        ],
        lineGenerator: ({
            associatedAccount,
            name,
            expiration,
            licenseClass,
            // autoRenewal,
            capabilities: {
                protocols,
                resources: { services, connections, endpoints, mappings, users, roles },
                features: { ldap }
            }
        }) => [
            associatedAccount,
            name,
            formatDate(expiration),
            licenseClass,
            String(protocols.length),
            String(services),
            String(connections),
            String(endpoints),
            String(mappings),
            String(users),
            String(roles),
            translator.formatTranslation(Translation.BOOLEAN, { value: ldap })
            // translator.formatTranslation(Translation.BOOLEAN, { value: autoRenewal })
        ]
    })
}

export const LicensesTable: FC = () => {
    const licenses = useState((s) => s.manageLicenses?.licenses || null)
    const [, selectRows] = useSelectedRows<ManageableConnectwareLicense>()

    const { width } = useWindowSize()
    const r = useRedirect()
    const translator = useTranslator()

    const csv = useLicensesCSV()

    useEffect(() => {
        if (!licenses) {
            selectRows([])
        }
    }, [licenses])

    if (licenses === null) {
        return <CircularProgress />
    }

    if (isPortalError(licenses)) {
        return <ErrorMessage error={licenses} />
    }

    const customAssociatedAccountBodyRender = (val: string) => <AssociatedAccountCell associatedAccount={val} />
    const customInUseRenderer = (description: string | null) => <IsInUse description={description || false} />
    const customExpirationBodyRender = (expiration: Date, { rowIndex }: MUIDataTableMeta) => (
        <ExpirationStatus date={expiration} autoRenewal={licenses[rowIndex].autoRenewal} />
    )
    const customExternalLinkBodyRender = (_: unknown, { rowData }: MUIDataTableMeta) => {
        const license = licenses.find(({ name }) => name === rowData[0])
        return (
            <Link to={{ id: license?.id || 'error', path: RoutePath.ADMIN_LICENSE }} target="_blank" onClick={(e) => e.stopPropagation()}>
                <Launch fontSize="small" />
            </Link>
        )
    }

    const customToolbar = () => <CreateButton />
    const customToolbarSelect = () => <Toolbar />

    return (
        <Table
            data={licenses}
            columns={[
                { name: 'name', label: translator.formatTranslation(Translation.NAME), options: { filter: false, sort: true, sortDescFirst: false } },
                {
                    name: 'licenseKey',
                    label: 'Key',
                    options: {
                        display: 'excluded',
                        filter: false
                    }
                },
                {
                    name: 'associatedAccount',
                    label: translator.formatTranslation(Translation.ACCOUNT),
                    options: { filter: true, customBodyRender: customAssociatedAccountBodyRender }
                },
                { name: 'licenseClass', label: translator.formatTranslation(Translation.LICENSE_CLASS), options: { filter: true } },
                {
                    name: 'description',
                    label: translator.formatTranslation(Translation.DESCRIPTION),
                    options: { filter: false, customBodyRender: customInUseRenderer }
                },
                {
                    name: 'expiration',
                    label: translator.formatTranslation(Translation.EXPIRATION_DATE),
                    options: {
                        filter: true,
                        customBodyRender: customExpirationBodyRender,
                        filterOptions: {
                            /**
                             * the values only come in as strings :(
                             */
                            renderValue: (expiration: string) => formatDate(new Date(expiration))
                        }
                    }
                },
                // {
                //     name: 'autoRenewal',
                //     label: translator.formatTranslation(Translation.AUTOMATIC_LICENSE_RENEWAL),
                //     options: {
                //         filter: true,
                //         customBodyRender: (autoRenewal: boolean) => translator.formatTranslation(Translation.BOOLEAN, { value: autoRenewal })
                //     }
                // },
                {
                    name: 'archived',
                    label: translator.formatTranslation(Translation.ARCHIVED),
                    options: {
                        filter: true,
                        display: false,
                        customBodyRender: (archived: boolean) => translator.formatTranslation(Translation.BOOLEAN, { value: archived }),
                        customFilterListOptions: { render: (archived: string) => `${translator.formatTranslation(Translation.ARCHIVED)}: ${archived}` },
                        filterList: [translator.formatTranslation(Translation.BOOLEAN, { value: false })]
                    }
                },
                {
                    name: 'externalLink',
                    label: ' ',
                    options: { filter: false, sort: false, sortDescFirst: false, customBodyRender: customExternalLinkBodyRender }
                }
            ]}
            options={{
                responsive: width >= 600 ? 'vertical' : 'simple',
                sort: true,
                download: true,
                downloadOptions: {
                    filename: csv.generateFileName(translator.formatTranslation(Translation.LICENSES).toLowerCase()),
                    separator: csv.separator
                },
                onDownload: () => csv.generateContent(licenses),
                sortOrder: { name: 'name', direction: 'asc' },
                selectableRows: 'multiple',
                onCellClick: (_, { dataIndex }) => r.redirect(RoutePath.ADMIN_LICENSE, licenses[dataIndex].id),
                customToolbar,
                customToolbarSelect,
                onRowSelectionChange: (_, data: { index: number; dataIndex: number }[]): void => selectRows(data.map(({ dataIndex }) => licenses[dataIndex])),
                customSearch: (searchQuery, currentRow: ValueOf<ManageableConnectwareLicense>[]) => customSearch(searchQuery, currentRow),
                filterType: 'dropdown'
            }}
        />
    )
}
