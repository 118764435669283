import React, { createContext, FC, useContext } from 'react'

import { PortalError, PortalErrorType } from '../../../domain'

import { LoggerService, ConfigurationService } from '../../../application/services'

type Services = { readonly logger: LoggerService; readonly configuration: ConfigurationService }

const ServicesContext = createContext<Services | undefined>(undefined)

export const ServicesProvider: FC<Services> = ({ children, ...services }) => <ServicesContext.Provider value={services}>{children}</ServicesContext.Provider>

export const useServices = (): Services => {
    const context = useContext(ServicesContext)
    if (context === undefined) {
        throw new PortalError(PortalErrorType.STATE, 'useServices must be used with-in a ServicesProvider')
    }
    return context
}
